import React, { useState } from "react";
import { unit1, unit2 } from "./helpers";

const Units = () => {
  const [active, setActive] = useState(0);

  let units = [unit1, unit2];

  return (
    <>
      <div className="my-5">
        <div className="flex items-center  w-full">
          <div className="flex w-4/5  border-b">
            {["Unit 1", "Unit 2"].map((item, index) => (
              <div
              key={index}
                onClick={() => setActive(index)}
                className={`${
                  active === index
                    ? "bg-ThemeBlue text-white "
                    : "text-lightGrayText "
                } font-AuthorSemiBold w-full cursor-pointer h-11 flex items-center justify-center transitions-all opacity-100 duration-500`}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="lg:w-[350px] mb-5">
        <>
          {React.Children.toArray(
            units[active].map(({ icon, title },index) => (
              <div key={index} className="flex items-center gap-4">
                <img src={icon} alt="icon" />
                <p className="text-lightBlue lg:w-5/12 text-base md:text-lg font-AuthorThin">
                  {title}
                </p>
              </div>
            ))
          )}
        </>
      </div>
    </>
  );
};

export default Units;
