import React, { Component } from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

class Investor extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<div className='Investor'>
				<Header />
				<section className="container relative max-w-5xl  h-full m-auto bg-center bg-cover header">
					<h1 className='py-16 text-3xl coming-soon'>Page Coming Soon</h1>
				</section>
				<Footer />
			</div>
		);
	}
}

export default Investor;
