import React, { Component } from "react";
import "../Layout/layout.scss";
import { Switch, Route } from "react-router-dom";

//Components
import "react-toastify/dist/ReactToastify.css";

//Pages
import Home from "../Pages/Home/Home";

import Sustainability from "../Pages/Sustainability/Sustainability";
import ProductView from "../Pages/Product/ProductView";

import AboutUs from "../Pages/AboutUs/AboutUs";

import Facilities from "../Pages/Facilities/Facilities";

import NotFound from "../Pages/NotFound";
import Login from "../Pages/Login";
import Investor from "../Pages/Investor";

//Admin
import Dashboard from "../AdminLayout/Pages/Dashboard";
import Gallery from "../AdminLayout/Pages/Gallery";
import ContactList from "../AdminLayout/Pages/contact-submission/contact-list";
import ContactEdit from "../AdminLayout/Pages/contact-submission/contact-edit";
import ApplicantsList from "../AdminLayout/Pages/Applicants/Applicants-list";
import UserList from "../AdminLayout/Pages/Users/user-list";
import UserEdit from "../AdminLayout/Pages/Users/user-edit";
import AddressList from "../AdminLayout/Pages/AddressInfo/address-list";
import AddressEdit from "../AdminLayout/Pages/AddressInfo/address-edit";

import JobList from "../AdminLayout/Pages/JobList/job-list";
import JobEdit from "../AdminLayout/Pages/JobList/job-edit";
import JobAdminView from "../AdminLayout/Pages/JobList/job-view";
import JobAdminViewDetails from "../AdminLayout/Pages/JobList/job-view-details";

import ProductList from "../AdminLayout/Pages/ProductList/product-list";
import ProductEdit from "../AdminLayout/Pages/ProductList/product-edit";
import ProductAdminView from "../AdminLayout/Pages/ProductList/product-view";

import GuardedRoute from "../config/GuardedRoute";
import Footer from "../components/Footer/Footer";

import IntermediateProducts from "../Pages/Product/IntermediateProducts";
import APIProducts from "../Pages/Product/APIProducts";
import Header from "../components/Header/Header";
import Contact from "../Pages/ContactUs/Contact";
import Career from "../Pages/Careers/Career";
import JobView from "../Pages/Careers/JobView";
import BoardMembers from "../Pages/AboutUs/BoardMembers";
import SustainabilityAdmin from "../AdminLayout/Pages/Sustainability/SustainabilityAdmin";
import SustainabilityForm from "../AdminLayout/Pages/Sustainability/Form";
import SustainabilityViewForm from "../AdminLayout/Pages/Sustainability/View";
import FutureTargets from "../Pages/AboutUs/FutureTargets";
import Achivements from "../Pages/AboutUs/Achivements";
import SustainabilityListing from "../Pages/Sustainability/Listing";



class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAutheticated: false,
    };
  }

  render() {
    return (
      <div className="overflow-hidden">
        <Header />
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/login" component={Login}></Route>
          <Route exact path="/about-us" component={AboutUs}></Route>
          <Route
            exact
            path="/about-us/facilities"
            component={Facilities}
          ></Route>
          <Route
            exact
            path="/about-us/board-members"
            component={BoardMembers}
          ></Route>
          <Route
            exact
            path="/about-us/future-targets"
            component={FutureTargets}
          ></Route>
           <Route
            exact
            path="/about-us/achievements"
            component={Achivements}
          ></Route>
          <Route exact path="/careers" component={Career}></Route>
          <Route
            exact
            path="/products/active-pharmaceutical-ingredients"
            component={APIProducts}
          ></Route>
          <Route
            exact
            path="/products/intermediates"
            component={IntermediateProducts}
          ></Route>
          <Route
            exact
            path="/sustainability"
            component={Sustainability}
          ></Route>
          <Route
            exact
            path="/sustainability/listing"
            component={SustainabilityListing}
          ></Route>
          <Route exact path="/investors" component={Investor}></Route>
          <Route
            exact
            path="/products/view/:id"
            component={ProductView}
          ></Route>
          <Route exact path="/contact-us" component={Contact}></Route>
          <Route
            exact
            path="/products/intermediates"
            component={IntermediateProducts}
          ></Route>
          <Route exact path="/careers/job/:id" component={JobView}></Route>
          <GuardedRoute
            exact
            path="/admin/job-list"
            component={JobList}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/new-job"
            component={JobEdit}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/job-list/:id/edit"
            component={JobEdit}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/job-list/:id"
            component={JobAdminView}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/job-list/:id/details/:id"
            component={JobAdminViewDetails}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/product-list"
            component={ProductList}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/new-product"
            component={ProductEdit}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/product-list/:id/edit"
            component={ProductEdit}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/product-list/:id"
            component={ProductAdminView}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/address-list"
            component={AddressList}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/new-address"
            component={AddressEdit}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/address-list/:id"
            component={AddressEdit}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/user-list"
            component={UserList}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/new-user"
            component={UserEdit}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/user-list/:id"
            component={UserEdit}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/applicants-list"
            component={ApplicantsList}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/applicants-list/:id"
            component={JobAdminViewDetails}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/contact-list"
            component={ContactList}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/contact-list/:id"
            component={ContactEdit}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/dashboard"
            component={Dashboard}
            auth={this.state.isAutheticated}
          ></GuardedRoute>

          <GuardedRoute
            exact
            path="/admin/sustainability/"
            component={SustainabilityAdmin}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/new-sustainability"
            component={SustainabilityForm}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          <GuardedRoute
            exact
            path="/admin/update-sustainability/:id"
            component={SustainabilityForm}
            auth={this.state.isAutheticated}
          ></GuardedRoute>

          <GuardedRoute
            exact
            path="/admin/view-sustainability/:id"
            component={SustainabilityViewForm}
            auth={this.state.isAutheticated}
          ></GuardedRoute>
          {/* <GuardedRoute exact path='/gallery-info' component={Gallery} auth={this.state.isAutheticated} ></GuardedRoute> */}
          <Route component={NotFound}></Route>
        </Switch>
        <Footer />
      </div>
    );
  }
}
export default Layout;
