import React, { useEffect, useState } from "react";
import gsap from "gsap/dist/gsap";
import { useLocation } from "react-router";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { mobileList } from "./helpers";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, styleprops }) => ({
  boxShadow: "none",
  "& .MuiAccordionSummary-root": {
    minHeight: 24,
    backgroundColor: styleprops.expanded ? "#1B3F67" : "#1B3F67",
    borderRadius: styleprops.expanded ? "0px" : "0px",
    color: "#FFFFFF",

    "&.Mui-expanded": {
      marginBottom: 0,
      outline: "none",
      color: "#FFFFFF",
    },
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#FFFFFF" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#1B3F67",
  minHeight: 64,
  padding: 0,
  border: "none",
  "& .MuiAccordionSummary-expandIconWrapper": {
    marginRight: theme.spacing(4),
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: theme.spacing(2),
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(270deg)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "-1px",
  backgroundColor: "#1B3F67",
}));

const SmallScreenHeader = () => {
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = React.useState(-1);

  let { pathname } = useLocation();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleModal = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    setExpanded(-1);
  };

  useEffect(() => {
    if (open) {
      gsap.to(".header_container", {
        height: "auto",
        duration: 1,
        ease: "power2.out",
      });
    } else {
      gsap.to(".header_container", {
        height: 0,
        ease: "power2.out",
        duration: 1,
      });
    }
  }, [open]);
  return (
    <div>
      <div className="fixed font-AuthorSemiBold z-50 top-10 w-full px-3 md:px-8 ">
        <div className="flex justify-between  ">
          <div
            className={`flex h-20  bg-ThemeBlue cursor-pointer  w-full items-center justify-between px-3 md:px-8 rounded-t-xl ${
              !open ? "transition-all duration-1000 rounded-b-xl" : ""
            }`}
          >
            <div>
              <Link to="/">
                {" "}
                <img
                  src="/home/logoMobile.svg"
                  alt="logo"
                  className="w-full"
                  onClick={handleClose}
                />
              </Link>
            </div>

            <div
              className="lg:hidden w-8 md:w-14 h-[60%] flex items-center"
              onClick={handleModal}
            >
              <div className="cursor-pointer w-full h-6 flex flex-col justify-between">
                <div
                  className={`w-1/2 h-1 bg-white  rounded-full ${
                    open ? "rotateIcon" : "reverseIcon"
                  }`}
                ></div>
                <div className="w-full h-1 bg-white rounded-full"></div>
                <div
                  className={`w-1/2 h-1 bg-white  rounded-full ml-auto ${
                    open ? "rotateIcon1" : "reverseIcon"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`  header_container  h-0 -mt-4 rounded-b-xl  bg-ThemeBlue overflow-hidden`}
        >
          <div className="text-white py-6">
            <div className="flex flex-col px-1 h-8 text-white">
              <Link to="/">
                {" "}
                <p
                  onClick={handleClose}
                  className={`${
                    (pathname.includes("/")
                      ? pathname.split("/")[1]
                      : pathname) === ""
                      ? "text-green"
                      : " text-white"
                  } text-lg font-AuthorThin font-medium pl-4`}
                >
                  HOME
                </p>
              </Link>
            </div>
            {mobileList?.map((item, parentIndex) => (
              <div key={parentIndex} className="-mt-1">
                <Accordion
                  sx={{
                    "&:before": {
                      backgroundColor: "transparent !important",
                    },
                  }}
                  key={parentIndex}
                  styleprops={{ expanded: expanded === parentIndex }}
                  expanded={expanded === parentIndex}
                  onChange={handleChange(parentIndex)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>
                      <Link
                        onClick={handleClose}
                        to={item?.url}
                        className={`${
                          (pathname.includes("/")
                            ? pathname.split("/")[1]
                            : pathname) === item?.slug
                            ? "text-green"
                            : "text-white"
                        } text-lg font-AuthorThin font-medium`}
                      >
                        {item?.name}
                      </Link>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex flex-col max-h-52 overflow-y-scroll">
                      {item?.subList?.map((subList, index) => (
                        <div key={index}>
                          {parentIndex === 2 ? (
                            <a
                              href={subList?.url}
                              target="_blank"
                              className="text-white text-lg font-AuthorThin font-medium py-1"
                            >
                              {subList?.name}
                            </a>
                          ) : (
                            <Link
                              key={index}
                              to={`${subList?.subLink}`}
                              className=" text-lg font-AuthorThin font-medium  text-white"
                            >
                              <p
                                onClick={handleClose}
                                className={`hover:text-green font-AuthorThin ${
                                  (pathname.includes("/") && pathname) ===
                                  subList?.subLink
                                    ? "text-green"
                                    : "text-white"
                                }`}
                              >
                                {subList?.name}
                              </p>
                            </Link>
                          )}
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}

            <div className="flex flex-col px-1">
              <Link to="/sustainability">
                {" "}
                <p
                  onClick={handleClose}
                  className={`${
                    (pathname.includes("/")
                      ? pathname.split("/")[1]
                      : pathname) === "sustainability"
                      ? "text-green"
                      : " text-white"
                  } text-lg font-AuthorThin font-medium pl-4 pb-4`}
                >
                  SUSTAINABILITY & ESG
                </p>
              </Link>
            </div>

            <div className="flex flex-col px-1">
              <Link to="/contact-us">
                {" "}
                <p
                  onClick={handleClose}
                  className={`${
                    (pathname.includes("/")
                      ? pathname.split("/")[1]
                      : pathname) === "contact-us"
                      ? "text-green"
                      : " text-white"
                  } text-lg font-AuthorThin font-medium pl-4 pb-4`}
                >
                  CONTACT
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallScreenHeader;
