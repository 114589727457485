import React from "react";

import { useState } from "react";
import { TabOne, TabThree, TabTwo } from "./ProductSections";
import HowWeAreRaising from "./HowWeAreRaising";
import { useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import api from "../../api";
import Loader from "../../components/Loader";
import EnquiryCommon from "./EnquiryCommon";
import updateMeta from "../../components/SeoMeta";
import { productList } from "./productJson";

const ProductView = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [productData, setProductData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [productId, setProductId] = useState();
  const [altTag, setAltTag] = useState("active pharmaceutical ingredients");
  let { id } = useParams();

  const location = useLocation();
  const link = location.state?.link;

  const getProductDetails = () => {
    setIsLoading(true);
    api
      .get(`/product/${id}`)
      .then((res) => {
        setIsLoading(false);

        setProductData(res?.data?.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getProductDetails();
  }, [id]);

  const handleEnquiryForm = (id) => {
    setProductId(id);
  };

  useEffect(() => {
    let links = location.pathname.split("/");
    let product = productList.find((item) => item?.url === links[3]);
    if (product) {
      setAltTag(product.imageAlt);
      document.title = product?.title || productData?.drug_name;
      updateMeta("description", `${product?.description}`);
      updateMeta("keyword", `${product?.keywords}`);
    }
  }, []);

  return (
    <div>
      <div className="lg:mt-40 mt-36 px-4 md:px-16 lg:px-24">
        {isLoading ? (
          <div className="mt-10">
            <Loader />
          </div>
        ) : (
          <div>
            <div className="font font-AuthorThin text-lightBlue">
              <p>
                <Link to="/">Home</Link> &gt; <Link to={link}>Products</Link>{" "}
                &gt;{" "}
                <span className="font-AuthorSemiBold">
                  {productData?.drug_name || "--"}{" "}
                </span>
              </p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-7 gap-4 lg:gap-12 py-6 ">
              <div className="border  border-lightBlue lg:col-span-3 w-full ">
                <img
                  src={`${
                    process.env.REACT_APP_BASE_URL ||
                    "https://api-virupaksha.scube.me"
                  }${productData?.image_url}`}
                  alt={altTag}
                  className="w-full h-auto"
                />
              </div>

              <div className="lg:col-span-4">
                <p className="text-2xl lg:text-4xl font-AuthorBold text-lightBlue">
                  {productData?.drug_name || "--"}
                </p>
                <p className="text-lg lg:text-xl text-lightBlue font-normal font-AuthorThin py-2">
                  Cas No. {productData?.cas_no || "--"}
                </p>

                <div className=" grid grid-cols-2 pr-2 lg:grid-cols-3 my-2">
                  <div className="lg:col-span-1">
                    <p className="text-lightBlue font-AuthorSemiBold font-medium text-base lg:text-xl">
                      Chemical Name
                    </p>
                  </div>
                  <div className="lg:col-span-2">
                    <p className="text-lightBlue font-AuthorThin font-normal text-base lg:text-xl break-all">
                      {productData?.name || "--"}
                    </p>
                  </div>
                </div>

                <div className="grid grid-cols-2 pr-2 lg:grid-cols-3 my-2">
                  <div className="lg:col-span-1">
                    <p className="text-lightBlue font-AuthorSemiBold font-medium text-base lg:text-xl">
                      Molecular Weight
                    </p>
                  </div>
                  <div className="lg:col-span-2">
                    <p className="text-lightBlue font-AuthorThin font-normal text-base lg:text-xl">
                      {productData?.molecular_weight || "--"}
                    </p>
                  </div>
                </div>

                <div
                  className="w-56 m-auto lg:m-0  pt-12"
                  onClick={() => {
                    handleEnquiryForm(productData?.id);
                  }}
                >
                  <EnquiryCommon product_id={productId} />
                </div>
              </div>
            </div>

            {/***Tabs */}

            <div className="grid lg:grid-cols-12 gap-5 mt-10 ">
              <div className="lg:col-span-2 flex flex-row  justify-between lg:flex-col lg:gap-3    lg:py-6  lg:border-b-0 lg:border-r-2  border-border">
                {["Produtcs details", "Uses", "Status"].map((item, index) => (
                  <p
                    onClick={() => {
                      setActiveTab(index);
                    }}
                    key={index}
                    className={`${
                      activeTab === index
                        ? "text-green border-b-4 lg:border-b-0 border-green"
                        : "text-lightBlue"
                    }  font-AuthorSemiBold py-2  text-xl cursor-pointer`}
                  >
                    {item}
                  </p>
                ))}
              </div>

              <hr className="lg:hidden block -mt-6 h-3px text-border bg-border" />
              <div className="lg:col-span-8 lg:pl-4 lg:py-6 ">
                {
                  [
                    <TabOne data={productData} />,
                    <TabTwo data={productData} />,
                    <TabThree data={productData} />,
                  ][activeTab]
                }
              </div>
            </div>
          </div>
        )}
      </div>
      {/* raising */}
      <div className="pt-6 pb-10">
        <HowWeAreRaising />
      </div>
    </div>
  );
};

export default ProductView;
