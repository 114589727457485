import React, { useEffect } from "react";
import ContactForm from "../../components/ContactForm";
import ClickableMap from "./Maps";
import updateMeta from "../../components/SeoMeta";

const Contact = () => {
  const handleMapClick = () => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=Virupaksha Organics Limited, Asbestos Colony, Kukatpally, Hyderabad, Telangana`,
      "_blank"
    );
  };
  
  useEffect(() => {
    document.title = 'Contact Us | Virupaksha Organics Ltd';
    updateMeta("description", "Get in touch with Virupaksha Organics Ltd for any inquiries about our pharmaceutical and fine chemical solutions. We're here to help.");
    updateMeta("keywords", "Virupaksha Organics Ltd, contact us, customer support, inquiries, pharmaceutical solutions, chemical solutions, company contact");
  }, []);

  return (
    <div className="">
      <div
        className={`relative h-70vh lg:h-60vh md:h-50vh bg-contact_us  bg-cover`}
      >
        <div className="h-30vh md:h-25vh lg:h-30vh"></div>
        <div className="absolute  w-full">
          <div className="w-full h-full flex flex-col justify-center items-center px-3">
            <h1 className="text-green text-center font-AuthorBold text-2xl md:text-4xl">
              Contact Us
            </h1>

            <p className="w-full mt-2 md:w-11/12 text-center   lg:px-0 lg:w-6/12 text-white text-lg md:text-xl font-AuthorThin">
              Need assistance or have a specific request? We're here to help –
              reach out to us!
            </p>
          </div>
        </div>

        <div className="absolute w-full flex items-center justify-center top-50vh md:top-40vh lg:top-50vh">
          <div className="grid grid-cols-1 shadow-2xl lg:grid-cols-5 border border-border rounded-lg w-11/12 md:w-10/12 lg:w-8/12 ">
            <div className="bg-white rounded-t-lg lg:rounded-t-none lg:rounded-l-lg col-span-3 px-3 py-6 md:px-8 lg:px-12">
              <h2 className="text-lightBlue title1">Reach out for enquiries</h2>
              <div className="w-full mt-2 pb-2 ">
                <ContactForm />
              </div>
            </div>
            <div className="col-span-2 rounded-b-lg rounded-tl-none lg:rounded-l-none rounded-l-lg  rounded-r-none lg:rounded-r-lg product_bg lg:pr-3 py-6">
              <div className="flex flex-col  lg:flex-row gap-2">
                <div className="flex-none mx-auto lg:mx-0">
                  {" "}
                  <img
                    src="/home/contact/location.svg"
                    alt=""
                    className="w-16"
                  />
                </div>
                <div className="w-full flex flex-col justify-center items-center lg:justify-start lg:items-start  ">
                  {" "}
                  <p className="text-green mt-1 font-AuthorSemiBold text-xl lg:text-2xl">
                    Corporate Headquarters
                  </p>
                  <p className="text-base mt-2 md:px-4 lg:px-0 text-center  lg:text-left  lg:text-lg  font-AuthorLight text-white">
                    Plot No. B1 and B2, IDA, Gandhinagar, Kukatpally,
                    Medchal-Malkajgiri District, Hyderabad-500037, Telangana,
                    India.
                  </p>
                  <div className="relative  w-11/12 mt-3 h-48 rounded-lg border border-border">
                    <div className="absolute left-1 md:left-6 top-3">
                      <p
                        onClick={() => {
                          handleMapClick();
                        }}
                        className="bg-white p-2 shadow-xl font-AuthorThin font-medium text-skyBlue hover:underline cursor-pointer"
                      >
                        View Larger map
                      </p>
                    </div>
                    <img
                      src="/home/contact/head1.png"
                      alt="headQuaters"
                      className="h-full w-full object-cover rounded-lg"
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col lg:flex-row gap-2 mt-3">
                <div className="flex-none mx-auto lg:mx-0">
                  {" "}
                  <img src="/home/contact/phone.svg" alt="" className="w-16" />
                </div>
                <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start">
                  {" "}
                  <p className="text-green mt-1 font-AuthorSemiBold text-xl lg:text-2xl">
                    Phone
                  </p>
                  <a
                    href="tel:+914023075816"
                    className=" text-base lg:text-lg font-AuthorLight text-white"
                  >
                    +91 40 23075816
                  </a>
                  <p className="text-white text-base font-AuthorLight lg:text-lg opacity-70">
                    Monday – Saturday, 9:00 am – 6:30 pm
                  </p>
                </div>
              </div>

              <div className="flex flex-col lg:flex-row gap-2 mt-3">
                <div className="flex-none mx-auto lg:mx-0">
                  {" "}
                  <img src="/home/contact/email.svg" alt="" className="w-16" />
                </div>
                <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start">
                  {" "}
                  <p className="text-green mt-1 font-AuthorSemiBold text-xl lg:text-2xl">
                    Email Id
                  </p>
                  <a
                    href="mailto:info@virupaksha.com"
                    className="w-3/5  text-center text-base lg:text-lg font-AuthorLight text-white"
                  >
                    info@virupaksha.com
                  </a>
                  <p className="text-white text-base font-AuthorLight lg:text-lg opacity-70">
                    Monday – Saturday, 9:00 am – 6:30 pm
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="h-1300px  md:pb-10  lg:h-650px"></div>

      <div className="pb-10 md:pt-20 lg:pt-0 px-0 md:px-6 lg:px-0">
        <h1 className="text-lightBlue text-center font-AuthorBold text-2xl md:text-3xl pb-10">
          Our Unit Locations
        </h1>
        <ClickableMap />
      </div>
    </div>
  );
};

export default Contact;
