export const fivePData = [
    {
      name: "People",
      content: "Together to create ultimate value creation for the society.",
      image: "/images/sustainability/people.png",
    },
    {
      name: "Planet",
      content: "Working with a climate sensitive approach.",
      image: "/images/sustainability/planet.png",
    },
    {
      name: "Prosperity",
      content:
        "We are committed for the prosperity of our stakeholders be it Economic, Social and Techonological.",
      image: "/images/sustainability/prosperity.png",
    },
    {
      name: "Peace",
      content:
        "We are committed for working in harmony with all our stakeholders.",
      image: "/images/sustainability/peace.png",
    },
    {
      name: "Partnership",
      content:
        "We believe “Together we can grow”, and our policies are focused to engage in a lasting partnership with our stakeholders.",
      image: "/images/sustainability/partnership.png",
    },
  ];
