import React from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { useState } from "react";
import moment from "moment/moment";

import HowWeAreRaising from "../Product/HowWeAreRaising";
import { useEffect } from "react";
import api from "../../api";
import JobForm from "./JobForm";

const JobView = () => {
  let link = "/careers";
  const [job, setJob] = useState();
  const [isLoading, setIsLoading] = useState(false);
  let { id } = useParams();

  const fetchJob = () => {
    setIsLoading(true);
    api
      .get(`/jobs-home/${id}`)
      .then((res) => {
        setJob(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchJob();
  }, []);

  return (
    <div>
      <div className="lg:mt-40 mt-36 px-4 md:px-16 lg:px-16">
        <div className="font font-AuthorThin text-lightBlue">
          <p>
            <Link to="/">Home</Link> &gt; <Link to={link}>Careers</Link> &gt;
            <span className="font-AuthorSemiBold">{job?.name || ''}</span>
          </p>
        </div>

        <p className="text-2xl lg:text-3xl font-AuthorBold text-lightBlue">
          Current openings
        </p>

        {isLoading ? (
          <div className="mt-10">
            <Loader />
          </div>
        ) : (
          <div className="py-6">
            <div className="w-full product_bg flex justify-between md:justify-start md:gap-6 py-6 px-4 md:px-4 rounded-lg">
              <div className="flex flex-col ">
                <p className=" font-AuthorBold text-white text-xl md:text-2xl">
                  {job?.name || "--"}
                </p>
                <p className="text-white font-AuthorThin text-base ">
                  {moment(job?.created_at).format("ll")}{" "}
                  <span>.({"Full Time" || "--"})</span>
                </p>
              </div>
              <div>
                <p className="font font-AuthorSemiBold text-customGreen text-lg md:text-xl mt-0 md:mt-1">
                  {job?.work_location || "--"}
                </p>
              </div>
            </div>

            <div className="py-5 px-3 md:px-4">
              <p className="font-AuthorSemiBold text-lightBlue text-2xl">
                About us
              </p>

              <p className="text-justify  font-AuthorThin text-lightBlue text-lg">
              At Virupaksha, we're dedicated to providing top-tier products to our customers. Our vision extends beyond just quality products; we aim to enhance the quality of life for our employees. We uphold stringent quality standards across all our facilities, with an FDA-certified plant.
              </p>
            </div>

            <div className="py-4 px-3 md:px-4">
              <p className=" font-AuthorSemiBold text-lightBlue text-2xl">
                Roles & Responsibilities :
              </p>

              <ul className="jobList">
                {job?.roles_responsibilities?.split("\n").map((item, index) => (
                  <li
                    key={index}
                    className="font-AuthorThin leading-tight text-lightBlue text-lg"
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>

            <div className="py-4 px-3 md:px-4">
              <p className=" font-AuthorSemiBold text-lightBlue text-2xl">
                Expectations :
              </p>

              <ul className="jobList">
                {job?.expectations?.split("\n").map((item, index) => (
                  <li
                    key={index}
                    className="font-AuthorThin leading-tight text-lightBlue text-lg"
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>

            <JobForm job={job} />

            <HowWeAreRaising />
          </div>
        )}
      </div>

      <div className="px-20"></div>
    </div>
  );
};

export default JobView;
