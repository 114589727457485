import React, { useEffect } from "react";
import HowWeAreRaising from "../Product/HowWeAreRaising";
import updateMeta from "../../components/SeoMeta";

const data = [
  {
    title: "1.Quality",
    description:
      "We are committed to delivering high-quality products that meet or exceed our customers’ expectations. We will continue to invest in our quality management systems to ensure that our products meet the highest standards of quality.",
  },
  {
    title: "2.Innovation",
    description:
      "We will continue to invest in research and development to develop new products and technologies that meet the evolving needs of our customers.",
  },
  {
    title: "3.Sustainability",
    description:
      "We are committed to minimizing our environmental impact by reducing our carbon footprint, conserving natural resources, and promoting sustainable practices throughout our operations.",
  },
  {
    title: "4.Customer Satisfaction",
    description:
      "We are committed to providing our customers with exceptional service and support. We will continue to invest in our customer service capabilities to ensure that our customers receive the highest level of support.",
  },
  {
    title: "5.Employee Development",
    description:
      "We are committed to providing our employees with a safe and supportive work environment that fosters personal and professional growth. We will continue to invest in our employees’ development to ensure that they have the skills and knowledge they need to succeed.",
  },
];

const FutureTargets = () => {

  useEffect(() => {
    document.title = 'Future Targets & Goals | Virupaksha Organics Ltd';
    updateMeta("description", "Learn about the future targets and goals of Virupaksha Organics Ltd as we aim to lead the pharmaceutical and fine chemical industry with innovation and excellence.");
    updateMeta("keywords", "Virupaksha Organics Ltd, future goals, company targets, strategic vision, pharmaceutical innovation, chemical industry future");
  }, []);

  return (
    <div>
      <div
        className={`relative h-70vh lg:h-60vh md:h-50vh bg-achivements  bg-cover`}
      >
        <div className="h-30vh md:h-25vh lg:h-30vh"></div>
        <div className="absolute  w-full">
          <div className="w-full h-full px-3">
            <h1 className="text-green text-center font-AuthorBold text-2xl md:text-4xl">
              Future Target & Goals
            </h1>

            <p className="mx-auto w-full px-2 mt-2 md:w-11/12 text-center   lg:px-0 lg:w-6/12 text-white text-base md:text-xl font-AuthorThin">
              Dedicated to advancing healthcare through innovation, research,
              and a steadfast commitment to improving the quality of life for
              people around the world.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-lightWhite px-4 py-10 md:py-16 lg:px-24">
        <p className="text-green text-xl   text-center  font-AuthorSemiBold uppercase">
          Future Target
        </p>
        <p className="text-2xl md:text-2xl lg:text-3xl font-AuthorBold text-center w-11/12 md:w-6/12 lg:w-4/12 mx-auto leading-tight text-lightBlue">
          Delivering <span className="text-green">Excellence</span> in
          Pharmaceutical Manufacturing
        </p>

        <p className="text-center   w-11/12 md:w-12/12 lg:w-7/12 mx-auto font-AuthorThin text-base md:text-xl mt-3 text-lightBlue">
          Our goal is to become a leading API manufacturer in the industry by
          providing high-quality products and services to our customers. We aim
          to achieve this by expanding our product portfolio, increasing our
          production capacity, and investing in research and development.
        </p>
      </div>

      <section className="bg-shadeGreen py-6 lg:py-10  px-4 md:px-8 lg:px-24">
        <p className="text-green text-xl  text-left   font-AuthorSemiBold uppercase">
          GOALS
        </p>
        <p className="text-2xl md:text-2xl lg:text-3xl font-AuthorBold text-left w-full md:w-6/12 lg:w-4/12  leading-tight text-lightBlue">
          Delivering <span className="text-green">Excellence</span> in
          Pharmaceutical Manufacturing
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 mt-8 lg:mt-12">
          {data?.map((item, index) => (
            <div key={index} className="w-full  cardStyle py-6 lg:py-10">
              <p className="text-blueNavy text-xl md:text-xl lg:text-2xl font-AuthorBold text-center  mx-auto leading-tight">
                {item?.title}
              </p>
              <p className="text-center mt-2 w-11/12 md:w-11/12 mx-auto font-AuthorThin text-base md:text-xl  text-lightBlue">
                {item?.description}
              </p>
            </div>
          ))}
        </div>
      </section>

      <div className="pb-10">
        <HowWeAreRaising />
      </div>
    </div>
  );
};

export default FutureTargets;
