import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const footerLinks = [
  {
    name: "Quick Links",
    subItems: [
      {
        name: "About us",
        link: "/about-us",
      },
      {
        name: "Facilities",
        link: "/about-us/facilities",
      },
      {
        name: "Contact Us",
        link: "/contact-us",
      },
      {
        name: "Careers",
        link: "/careers",
      },
    ],
  },
  {
    name: "Products",
    subItems: [
      {
        name: "API",
        link: "/products/active-pharmaceutical-ingredients",
      },
      {
        name: "Intermediates",
        link: "/products/intermediates",
      },
    ],
  },
];

const locationInfo = [
  {
    icon: "/home/footer/pin.svg",
    text: "Registered office-Plot No. B1 and B2, IDA, Gandhi Nagar, Kukatpally, Medchal-Malkajgiri District, Hyderabad-500037, Telangana, India.",
  },
  {
    icon: "/home/footer/call.svg",
    text: "+91 40 23075816",
    href: "tel:+914023075816",
  },
  {
    icon: "/home/footer/mail.svg",
    text: "info@virupaksha.com",
    href: "mailto:info@virupaksha.com",
  },
];

const socials = [
  {
    icon: "/home/footer/x.svg",
    link: "https://twitter.com/i/flow/login?redirect_after_login=%2FVirupakshaO",
  },
  {
    icon: "/home/footer/facebook.svg",
    link: "https://www.facebook.com/people/Virupaksha-Organics/pfbid02haUgyM1RBhtj87S6tJYCAunfszrwD63Vrbvdtbjbj9YoAwPckKaJTjoUjEBjFaM5l/",
  },
  {
    icon: "/home/footer/instagram.svg",
    link: "https://www.instagram.com/virupakshaorganics/",
  },
  {
    icon: "/home/footer/linkedin.svg",
    link: "https://www.linkedin.com/company/virupaksha-organics-limited/",
  },
  {
    icon: "/home/footer/youtube.svg",
    link: "https://www.youtube.com/channel/UCJC8cgBXIDFtn-4mcoJYkMA",
  },
];

const Footer = () => {
  let { pathname } = useLocation();
  return (
    <>
      {pathname.includes("admin") || pathname.includes("login") ? null : (
        <>
          <div className="bg-ThemeBlue text-white lg:p-10 lg:px-20 p-5">
            <div className="grid md:grid-cols-3 lg:grid-cols-3 lg:gap-0 gap-7 lg:items-start">
              {/* Logo & Information */}
              <div className="">
                <img
                  src="/home/logo.svg"
                  alt="brand logo"
                  className="cursor-pointer"
                />
                <p className="text-white font-AuthorThin w-full lg:w-10/12 text-base lg:text-xl  mt-4 opacity-70">
                  Having the highest quality is the best marketing strategy that
                  can be ever followed.
                </p>
              </div>

              {/* Quick Links & Products */}
              <div className="grid grid-cols-2">
                {React.Children.toArray(
                  footerLinks.map((item) => (
                    <div className="flex flex-col gap-4">
                      <h1 className="font-AuthorSemiBold text-xl lg:text-2xl">
                        {item.name}
                      </h1>
                      {React.Children.toArray(
                        item.subItems.map((x) => (
                          <div className="w-max">
                            <Link to={x?.link}>
                              {" "}
                              <p className=" w-max font-AuthorThin text-base lg:text-xl  text-white hover:underline cursor-pointer opacity-70">
                                {x.name}
                              </p>
                            </Link>
                          </div>
                        ))
                      )}
                    </div>
                  ))
                )}
              </div>

              {/* Get in Touch */}
              <div className="flex flex-col gap-4">
                <h1 className="font-AuthorSemiBold text-xl lg:text-2xl">
                  Get in Touch
                </h1>
                {React.Children.toArray(
                  locationInfo.map(({ icon, text, href }, index) => (
                    <div className="flex gap-2">
                      <img
                        src={icon}
                        alt={icon}
                        className={`${
                          index === 0 ? "-mt-10 md:-mt-24 lg:-mt-12" : ""
                        }`}
                      />
                      {index === 0 ? (
                        <p className="text-base lg:text-xl font-AuthorThin text-white opacity-70">
                          {text}
                        </p>
                      ) : (
                        <a href={`${href}`} className="opacity-70">
                          {text}
                        </a>
                      )}
                    </div>
                  ))
                )}

                <div className="flex items-center gap-4">
                  {React.Children.toArray(
                    socials.map(({ icon, link }) => (
                      <div>
                        <a href={link} target="_blank">
                          <img
                            src={icon}
                            alt={icon}
                            className="cursor-pointer"
                          />
                        </a>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>

         

          {/* Copyright */}
          <div className="bg-darkBlue p-5 w-full lg:px-20 px-5 flex lg:flex-row flex-col lg:justify-between lg:gap-0 gap-2 items-start lg:items-center">
            <p className="text-white  font-AuthorThin">
              Copyright &copy; 2024 Virupaksha. All Rights Reserved
            </p>
            <span className="text-white  font-AuthorThin flex  items-end gap-2">
              <p className="lg:text-sm text-xs">Made with ♥️ from</p>
              <img
                src="/images/scubetm.svg"
                alt="scube"
                className="relative -top-px  w-16 md:w-20"
              />
            </span>
          </div>
        </>
      )}
    </>
  );
};

export default Footer;
