import React, { useEffect, useMemo, useState } from "react";

import api from "../../api/index";
import Table from "./Table";
import Banner from "./Banner";
import HowWeAreRaising from "./HowWeAreRaising";
import updateMeta from "../../components/SeoMeta";

const APIProducts = () => {
  const [productData, setProductData] = useState([]);
  const [filterdData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [keyPressed, setKeyPressed] = useState(false);

  const header = [
    {
      name: "S.no",
      slug: "sno",
    },
    {
      name: "Product Name",
      slug: "drug_name",
    },
    {
      name: "Status",
      slug: "product_status_short",
    },
    {
      name: "CAS No.",
      slug: "cas_no",
    },
  ];

  const fetchData = () => {
    setIsLoading(true);
    api
      .get(`/products`, {
        params: {
          category_slug: "active-pharmaceutical-ingredients-apis",
        },
      })
      .then((res) => {
        let response = res?.data?.data;
        let newData = response.map((item, index) => ({
          ...item,
          sno: index + 1,
        }));
        setProductData(newData);
        setFilteredData(newData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    setKeyPressed(true);
    setSearchKey(e.target.value);
  };

  const handleFilter = () => {
    const data = productData.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchKey.toLowerCase())
      );
    });
    setFilteredData(data);
  };

  useMemo(() => {
    if (keyPressed) {
      handleFilter();
    }
  }, [searchKey]);

  useEffect(() => {
    document.title = 'Active Pharmaceutical Ingredients | Virupaksha Organics Ltd';
    updateMeta("description", " Explore our range of high-quality Active Pharmaceutical Ingredients (APIs) at Virupaksha Organics Ltd, designed to meet the needs of the global pharmaceutical industry.");
    updateMeta("keywords", "Virupaksha Organics Ltd, active pharmaceutical ingredients, APIs, pharmaceutical ingredients, high-quality APIs, pharmaceutical industry");
    fetchData();
  }, []);
  return (
    <div className="">
      <Banner
        title="Active Pharmaceutical Ingredients (APIs)"
        handleChange={handleChange}
        searchKey={searchKey}
        styles="bg-api bg-cover"
        searchBox={true}
        content="*None of the below products will be supplied to the countries in
        which these could conflict with the existing patents. The final
        responsibility lies exclusively with the buyer."
      />

      <div className="h-5vh md:h-10vh"></div>

      <Table header={header} isLoading={isLoading} filterdData={filterdData} />

      <HowWeAreRaising />

      <div className="h-10vh"></div>
    </div>
  );
};

export default APIProducts;
