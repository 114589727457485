import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = ({ handleCaptcha }) => {
  const ref = React.useRef(null);
  const APP_SITE_KEY = "6Ld4lVMpAAAAAGVTXE4If0VBNypOds-0ooLIbjsv";
  const onRecaptchaChange = (value) => {
    if (value) {
      return handleCaptcha(true);
    } else if (value === null) {
      return handleCaptcha(false);
    }
  };
  return (
    <div>
      <ReCAPTCHA
        style={{ display: "" }}
        theme="light"
        ref={ref}
        sitekey={APP_SITE_KEY}
        onChange={onRecaptchaChange}
      />
    </div>
  );
};

export default Captcha;
