import React, { useEffect } from "react";
import updateMeta from "../../components/SeoMeta";

let independent_directors = [
  {
    name: "Janardhana Reddy Yeddula",
    role: "Independent Director",
    content:
      "Mr. Janardana Reddy is a graduate in commerce and Fellow Member of Institute of Cost and Management Accountants of India (FCMA).He is a founding partner of DV Associates, Cost and Management accountants. He has more than 30 years of experience in Finance & Accounts domain of various Pharma, Fertilizers, Cement, Agro Chemicals and energy conductors companies.",
  },
  {
    name: "Shruti Gupta",
    role: "Independent Director",
    content:
      "Ms. Shruti Gupta, is a Company Secretary by profession with more than 10 Years of post-qualification Experience.",
  },
];

let board_directors = [
  {
    name: "Chandra Mouliswar Reddy Gangavaram",
    image: "",
    role: "Managing Director",
    content:
      "Mr. Chandra Mouliswar Reddy Gangavaram is the founder and Managing Director of Virupaksha. He has a Master’s degree in Organic Chemistry and has more than 35 years of experience in the pharmaceutical industry. He had a vision of providing quality products to the customers, while also caring for the welfare of his employees and the society. Under his leadership and guidance,Virupaksha has grown to become a reputed name in the API (Active Pharmaceutical Ingredients) market, both within India and abroad.",
  },
  {
    name: "Balasubba Reddy Mamilla",
    image: "",
    role: "Executive Director",
    content:
      "Mr. Balasubba Reddy Mamilla is the co-founder and CFO of Virupaksha. He holds a BTech degree and has more than 30 Years of administration and technical expertise. He has always been driven by his entrepreneurial spirit and his passion for innovation. He played a key role in developing the business plan for Virupaksha to tap into the global markets of US, Europe, Japan and others. He also spearheaded the growth of Virupaksha within India and across the globe.",
  },
  {
    name: "Vedavathi Gangavaram",
    image: "",
    role: "Director",
    content:
      "Ms. Vedavathi Gangavaram is a Non- Executive Director of Virupaksha. She is a philanthropist and associated with various Social Welfare Organizations.",
  },
  {
    name: "Chandrasekhar Reddy Gangavaram",
    image: "",
    role: "Director",
    content:
      "Mr. Chandrasekhar Reddy Gangavaram has a Master’s degree in Organic Chemistry and has more than 30 years of technical knowledge and managerial experience in Pharmaceutical Industry. He is instrumental in streamlining the operations at Virupaksha.",
  },
];

let board_directorsOne = [
  {
    name: "Mr. Sriram Reddy",
    image: "",
    role: "Director",
  },
  {
    name: "Mr. Ramchandra Reddy",
    image: "",
    role: "Director",
  },
  {
    name: "Mr. Sandeep Reddy",
    image: "",
    role: "Director",
  },
  {
    name: "Mr. Suraj Reddy",
    image: "",
    role: "Director",
  },
  {
    name: "Mr. Nagarjuna Reddy",
    image: "",
    role: "Director",
  },
];


const BoardMembers = () => {
  useEffect(() => {
    document.title = 'Board Members | Virupaksha Organics Ltd';
    updateMeta("description", "Meet the board members of Virupaksha Organics Ltd, who bring a wealth of experience and leadership to drive our company forward in the pharmaceutical and fine chemical industry");
    updateMeta("keywords", " Virupaksha Organics Ltd, board members, leadership team, company directors, pharmaceutical leadership, chemical industry leaders");
  }, []);
  return (
    <div>
      <div
        className={`relative h-70vh lg:h-60vh md:h-50vh bg-board_members  bg-cover`}
      >
        <div className="h-30vh md:h-25vh lg:h-30vh"></div>
        <div className="absolute  w-full">
          <div className="w-full h-full px-3">
            <h1 className="text-green text-center font-AuthorBold text-2xl md:text-4xl">
              Leadership
            </h1>

            <p className="mx-auto w-full mt-2 md:w-11/12 text-center   lg:px-0 lg:w-6/12 text-white text-lg md:text-xl font-AuthorThin">
              Our commitment to strict compliance with regulatory agencies is
              non-negotiable, and we must ensure our operations adhere to the
              highest ethical standards.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-lightWhite py-4 md:py-6 lg:py-10 px-4 md:px-10 lg:px-24">
        <p className="text-green text-xl  font-AuthorSemiBold uppercase">
          Our Leaders
        </p>
        <p className="text-2xl md:text-2xl lg:text-3xl font-AuthorBold leading-tight text-lightBlue">
          Our Board of Directors Driving Innovation and Compliance
        </p>

        <p className="text-justify font-AuthorThin text-base md:text-xl mt-3 text-lightBlue">
          These leaders play a critical role in shaping our company, Their
          collective vision and leadership are essential in guiding us in
          pharmaceutical sector as it continues to develop life-saving
          medications and meet the healthcare needs of individuals and societies
          worldwide.
        </p>

        <div className="w-full pt-6 md:pt-10">
          <p className="pb-2 lg:pb-6 text-left text-2xl md:text-2xl lg:text-3xl font-AuthorBold leading-tight text-lightBlue">
            Board of Directors
          </p>

          <div className="w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10">
              {board_directors.map((item, index) => (
                <div
                  key={index}
                  className="border bg-shadeGray border-border rounded-lg"
                >
                  <div className="product_bg p-4 rounded-lg">
                    <p className="text-lg md:text-xl lg:text-2xl font-AuthorBold leading-tight text-white">
                      {item?.name}
                    </p>
                    <p className="text-base md:text-lg lg:text-lg   font-AuthorThin leading-tight text-white ">
                      {item?.role}
                    </p>
                  </div>
                  <div className="p-4  w-full  rounded-lg text-lightBlue font-AuthorThin text-base md:text-lg">
                    {item?.content}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="w-full pt-6 md:pt-10">
          <p className="text-left  pb-2 lg:pb-6 text-xl md:text-xl lg:text-xl font-AuthorBold leading-tight text-lightBlue">
            Independent Directors
          </p>

          <div className="w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10">
              {independent_directors.map((item, index) => (
                <div
                  key={index}
                  className="border bg-shadeGray border-border rounded-lg"
                >
                  <div className="product_bg p-4 rounded-lg">
                    <p className="text-xl md:text-xl lg:text-2xl font-AuthorBold leading-tight text-white">
                      {item?.name}
                    </p>
                    <p className="text-base md:text-lg lg:text-lg   font-AuthorThin leading-tight text-white ">
                      {item?.role}
                    </p>
                  </div>
                  <div className="p-4 rounded-lg  text-lightBlue font-AuthorThin text-base md:text-lg">
                    {item?.content}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="w-full pt-6 md:pt-10">
          <p className="pb-2 lg:pb-6 text-2xl text-left md:text-2xl lg:text-3xl font-AuthorBold leading-tight text-lightBlue">
            Board of Management Directors
          </p>

          <div className="w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10">
              {board_directorsOne.map((item, index) => (
                <div key={index} className="border border-border rounded-lg">
                  <div className="product_bg p-4 rounded-lg">
                    <p className="text-xl md:text-xl lg:text-2xl font-AuthorBold leading-tight text-white">
                      {item?.name}
                    </p>
                    <p className="text-base md:text-lg lg:text-lg   font-AuthorThin leading-tight text-white  font-medium">
                      {item?.role}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardMembers;
