const updateMeta = (name, content) => {
  let meta = document.querySelector(`meta[name=${name}]`);
  if (!meta) {
    meta = document.createElement("meta");
    meta.name = name;
    document.head.appendChild(meta);
  }
  meta.content = content;
};
export default updateMeta;
