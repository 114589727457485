import React, { useEffect } from "react";
import Banner from "../Product/Banner";
import { cards, strength } from "./helpers";
import HowWeAreRaising from "../Product/HowWeAreRaising";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import updateMeta from "../../components/SeoMeta";


let slidesData = [
  {
    image: "/images/aboutus/slider/img1.webp",
  },
  {
    image: "/images/aboutus/slider/img2.webp",
  },
  {
    image: "/images/aboutus/slider/img3.webp",
  },
  {
    image: "/images/aboutus/slider/img4.webp",
  },
  {
    image: "/images/aboutus/slider/img5.webp",
  },
  {
    image: "/images/aboutus/slider/img6.webp",
  },
];

const AboutUs = () => {
  useEffect(() => {
    document.title = 'About Us | Virupaksha Organics Ltd';
    updateMeta("description", "Discover the story and mission of Virupaksha Organics Ltd, a global leader in the pharmaceutical and fine chemical industry. Learn about our commitment to quality, innovation, and excellence");
    updateMeta("keywords", "Virupaksha Organics Ltd, about Virupaksha, pharmaceutical industry, fine chemicals, company mission, company values, chemical innovation");
  }, []);
  return (
    <div>
      <div>
        <Banner
          title="About us"
          styles="bg-about_us bg-cover"
          searchBox={false}
          content="Dedicated to advancing healthcare through innovation, research, and a steadfast commitment to improving the quality of life for people around the world."
        />
      </div>

      <section className="bg-lightWhite  p-4 md:p-10 lg:p-24">
        <p className="text-green text-xl  font-AuthorSemiBold uppercase">
          Our Story
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="w-11/12">
            <p className="text-2xl lg:text-3xl font-AuthorBold leading-tight text-lightBlue">
              Delivering <span className="text-green">Excellence</span> in
              Pharmaceutical Manufacturing
            </p>

            <p className="text-justify  font-AuthorSemiBold text-xl mt-3 text-lightBlue">
              “Inspired by innovation, research, and passion, while embracing
              sustainability, driven by the aim of holistic stakeholder
              enhancement and lasting environmental impact.”
            </p>

            <p className="text-justify font-AuthorThin text-lg lg:text-xl mt-3 text-lightBlue">
              Virupaksha was established in the year 2003, and is a leading
              manufacturer of active pharmaceutical ingredients (APIs). Our
              journey started with a vision of Mr. Chandra Mouliswar Reddy
              Gangavaram, our founder and Managing Director, for providing
              quality APIs to cater to the diverse needs of pharmaceutical
              companies across the globe, while also being ethical and
              responsible towards our stakeholders. Virupaksha Organics Limited
              has a team of skilled and experienced professionals who ensure the
              highest standards of quality, safety, and efficacy in our
              products. Virupaksha Organics Limited have state-of-the-art
              manufacturing facilities in Telangana (India) at Kazipally &
              Pashamylaram. Both our production facilities are audited by FDA
              and are certified with ISO 9001, ISO 14001, ISO 45001, ISO 50001,
              ISO 27001, GMP standards. "We are committed to innovation,
              excellence, and customer satisfaction, and we strive to be the
              partner of choice for the pharmaceutical industry"
            </p>
          </div>
          <div className="hidden relative w-full lg:flex justify-end">
            <div className="w-full h-100 ">
              <img
                src="/home/about/aboutTwo.webp"
                alt=""
                className="w-full h-full  -mt-4 rounded-lg shadow-2xl"
              />
            </div>
            <div className="absolute w-7/12 -left-6 -bottom-4 ">
              <img
                src="/home/about/aboutOne.webp"
                alt=""
                className="w-full h-full object-cover rounded-lg shadow-2xl "
              />
            </div>
          </div>
          <div className="grid mt-4 grid-cols-1 w-full lg:hidden">
            <div className="w-full">
              <img
                src="/home/about/aboutTwo.webp"
                alt=""
                className="w-full h-full rounded-lg shadow-2xl"
              />
            </div>
            <div className="mt-6 mb-3">
              <img
                src="/home/about/aboutOne.webp"
                alt=""
                className="w-full h-full object-cover rounded-lg shadow-2xl "
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-shadeGreen py-16">
        <p className="text-green font-AuthorSemiBold text-lg lg:text-xl text-center">
          OUR EXPERTISE
        </p>
        <p className="text-lightBlue font-AuthorSemiBold text-2xl lg:text-3xl mt-2 text-center">
          Unleashing The Power of Expertise
        </p>

        <div className="flex flex-wrap px-4 md:px-10 lg:px-20 flex-1 gap-4 lg:gap-10 mt-12">
          {React.Children.toArray(
            cards.map((item, index) => (
              <div
                key={index}
                className=" cardStyle  rounded-lg px-4 py-16 flex flex-grow  w-36 items-center gap-4 flex-col justify-center"
              >
                <img src={item.icon} alt={item.icon} />
                <p className="text-center font-AuthorSemiBold text-lightBlue text-lg lg:text-xl font-medium">
                  {[1, 2, 3].includes(index) ? (
                    <>
                      {item?.title?.split(" ")[0].replace("-", " ")}
                      <br />
                      {item?.title?.split(" ")[1]}
                      <br />
                      {item?.title?.split(" ")[2]}{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      {item?.title?.split(" ")[0]} <br />
                      {item?.title?.split(" ")[1]} <br />
                    </>
                  )}
                </p>
              </div>
            ))
          )}
        </div>
      </section>

      <section className="bg-lightWhite py-10 lg:py-16">
        <p className="text-green font-AuthorSemiBold text-xl text-center">
          OUR GALLERY
        </p>
        <p className="w-11/12 lg:w-6/12 mx-auto text-lightBlue font-AuthorSemiBold text-2xl lg:text-3xl mt-2 text-center">
          These photos illustrate the precision and care that goes into every
          stage of the production process
        </p>

        <div className="mt-10 px-4 md:px-10 lg:px-20 grid grid-cols-1 lg:grid-cols-3 gap-2  rounded-lg ">
          <div className="shadow-2xl grid grid-cols-1 gap-2  rounded-lg  ">
            <div className="rounded-lg shadow-2xl">
              <img
                src="/images/aboutus/profile1.webp"
                alt="profile1"
                className="w-full h-full object-cover rounded-lg "
              />
            </div>
            <div className="shadow-2xl grid grid-cols-1 lg:grid-cols-2 gap-2 ">
              <div className="shadow-2xl">
                <img
                  src="/images/aboutus/profile2.webp"
                  alt="profile2"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
              <div className="shadow-2xl rounded-lg">
                <img
                  src="/images/aboutus/profile3.webp"
                  alt="profile3"
                  className="w-full h-full  rounded-lg object-cover"
                />
              </div>
            </div>
          </div>
          <div className="shadow-2xl">
            <img
              src="/images/aboutus/profile4.webp"
              alt="profile4"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
          <div className="gap-2 grid grid-cols-1 shadow-2xl  rounded-lg ">
            <div className="shadow-2xl">
              <img
                src="/images/aboutus/profile5.webp"
                alt="profile5"
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
            <div className="shadow-2xl">
              <img
                src="/images/aboutus/profile6.webp"
                alt="profile6"
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="px-4 md:px-10 lg:px-20 py-10">
        <div className="product_bg rounded-lg py-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4 lg:px-10">
          <div className="px-6 py-10 flex flex-col flex-grow">
            <p className="text-center lg:text-left text-green font-AuthorSemiBold text-lg lg:text-xl">
              OUR STRENGTH
            </p>
            <div className="text-center lg:text-left mt-2 leading-tight text-xl lg:text-2xl font-AuthorBold text-white">
              Empowering Lives Through Quality and Sustainable Operations
            </div>
          </div>

          {React.Children.toArray(
            strength.map((item, index) => (
              <div
                key={index}
                className="marquee_card  rounded-lg px-6 py-8 w-full flex flex-col lg:justify-start justify-center lg:items-start items-center gap-3"
              >
                <img src={item?.icon} alt="" className="w-16 lg:w-20" />
                <p className="text-white text-center lg:text-left font-AuthorBold text-xl lg:text-2xl">
                  {item?.title}
                </p>
                <p className="text-white text-center lg:text-left  font-AuthorThin text-lg lg:text-xl ">
                  {item?.content}
                </p>
              </div>
            ))
          )}
        </div>
      </section>

      <section className="bg-shadeGreen py-16">
        <p className="text-green font-AuthorSemiBold text-lg lg:text-xl text-center  uppercase">
          OUR pharma units
        </p>
        <p className="w-11/12 lg:w-6/12 text-lightBlue font-AuthorSemiBold text-2xl lg:text-3xl mt-2 text-center mx-auto">
          These photos illustrate the precision and care that goes into every
          stage of the production process
        </p>

        <div className="w-full px-4 md:px-10 lg:px-32 mt-10">
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlaySpeed={3000}
            autoPlay={true}
            centerMode={false}
            className=""
            containerClass="container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            partialVisible={true}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 2,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {slidesData?.map((item, index) => (
              <div key={index} className="h-50vh p-2 cursor-pointe rounded-lg">
                <img
                  src={item?.image}
                  alt=""
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            ))}
          </Carousel>
        </div>
      </section>

      <div className="pb-10">
        <HowWeAreRaising />
      </div>
    </div>
  );
};

export default AboutUs;
