import React, { Component } from 'react';
import $ from 'jquery';
import { authenticationService } from '../config/auth.service';
import  browserHistory from '../config/browserHistory'
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from 'react-toastify';
class Login extends Component {
	constructor(props) {
		super(props);
	
        if (authenticationService.currentUserValue) { 
			browserHistory.push("/admin/dashboard");
        }
        
		this.state = {
			isLoading: false, 
            message:'',
			email:'',
			password:'',
			formErrors: {email: '',password:''},
			emailValid: false,
			formValid: false
		}
	}

	
	handleSubmit=(event)=>{
		event.preventDefault();
		if(this.state.formValid){
		let data = {
			email: this.state.email,
			password: this.state.password,
		}
		this.setState({isLoading:true});
		var self = this;
        authenticationService.login(data.email,data.password)
			.then(res => {
				
				if(res.status === 200){
					browserHistory.push("/admin/dashboard");
				}else{
					self.setState({
						message:res.data.message,
						isLoading:false
					})
					toast.error(res.data.message,{className: 'error-info'})
				}
				
			
			})
		}else{
			toast.error('Please fill the required details',{className: 'error-info'})
			if(this.state.email === ''){
				this.handleChange({data:'email'})
			}
			if(this.state.password === ''){
			this.handleChange({data:'password'})
			}
			this.scrollToFirstInvalidControl();
			return false;
		}
		
	}
	
	scrollToFirstInvalidControl() {
		const firstInvalidControl = document.querySelector(
		  ".has-error"
		);
	 
		if (firstInvalidControl) {
		  $(window).scrollTop($("input").offset().top - 120);
		  firstInvalidControl.focus();
		}
	  }

	handleChange = (e) => {
		this.setState({message:''})
		let nameKey;
		let valueData;
		if(e['data']){
		  nameKey = e.data;
		  valueData = '';
		}
		if(e.type){
		  nameKey = e.target.name;
		  valueData =  e.target.value;
		}
		const name = nameKey;
		const value = valueData;
		this.setState({[name]: value},
			() => { this.validateField(name, value) });;
	  }

	validateField(fieldName, value) {
		let fieldValidationErrors = this.state.formErrors;
		let emailValid = this.state.emailValid;
		let passwordValid = this.state.passwordValid;
	
		switch(fieldName) {
		  case 'email':
			emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
			fieldValidationErrors.email = emailValid ? '' : ' is invalid';
			break;  
		  case 'password':
			passwordValid = (value.length >= 8);
			fieldValidationErrors.password =passwordValid ? '': ' is invalid';
			break;
		  default:
			break;
		}

		this.setState({formErrors: fieldValidationErrors,
			emailValid: emailValid,
			passwordValid: passwordValid,
		}, this.validateForm);
	}	

	validateForm() {
		this.setState({formValid: this.state.emailValid && this.state.passwordValid});
	}

	errorClass(error) {
		return(error.length === 0 ? '' : 'has-error');
	}
	  
	render() {
		return (
            <div className="my-12 text-center">
				<Helmet>
					<title>Login</title>
					<meta name="description" content= 'Login'/>
					<meta name="keywords" content= 'Login' />
				</Helmet>
				<ToastContainer />
				<form ref="form" className="mt-1" onSubmit={this.handleSubmit} noValidate>
						
           <div className="inline-block w-11/12 m-auto overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl md:w-5/12">
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                  <div className="mt-3">
                    <div className="mt-2">
					<h2 className="text-3xl text-center text-ThemeBlue font-WavehausBold">Login Form</h2>
                    <p className="text-sm text-center text-blue">
	  
					Enter the valid details
                    </p>
				
						<div>
                            <div  className={`form-group ${this.errorClass(this.state.formErrors.email)}`}>
								<input type="email"  value={this.state.email} required id="email" onChange={this.handleChange} noValidate name="email" className="form-group__input" autoComplete="off" placeholder=" "/>
								<label htmlFor="email" className="form-group__label">Email</label>
							</div>

							<div  className={`form-group ${this.errorClass(this.state.formErrors.password)}`}>
								<input type="password"  value={this.state.password} required id="password" onChange={this.handleChange} noValidate name="password" className="form-group__input" autoComplete="off" placeholder=" "/>
								<label htmlFor="password" className="form-group__label">Password</label>
							</div>
							</div>
					
				    </div>
                  </div>
                  <p className="px-3 text-red">{this.state.message}</p>
              </div>
           
              <div className="px-4 pb-6 text-center bg-gray-50">
				  {
					!this.state.isLoading ? 	 <button type="submit" onClick={this.handleSubmit} className="px-8 py-3 text-sm text-white transition-all duration-150 ease-linear shadow outline-none rounded-3xl font-WavehausBold bg-green get-started focus:outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-lg">Login</button>: <button className="px-8 py-3 text-sm text-white transition-all duration-150 ease-linear shadow outline-none rounded-3xl font-WavehausBold bg-green get-started focus:outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-lg">Loading...</button>
				  }
			  </div>
			
            </div>
			</form>
            </div>
        );
	}
}

export default Login;
