import React from "react";
import NoDataFound from "../../components/NoDataFound";
import { Link } from "react-router-dom";
import EnquiryCommon from "./EnquiryCommon";
import { useState } from "react";
import Button from "../../components/Button";

const Table = ({ header, isLoading, filterdData }) => {
  const [productId, setProductId] = useState();
  const handleEnquiryForm = (id) => {
    setProductId(id);
  };

  return (
    <div className="">
      {isLoading ? (
        <div className="h-20vh flex items-center justify-center">
          Loading...
        </div>
      ) : (
        <>
          {filterdData && filterdData.length > 0 ? (
            <>
              {/* Table Large and Medium Screens */}

              <div className="hidden  md:block w-full bg-white px-8 lg:px-12">
                <div className="flex w-full product_bg rounded-xl py-6">
                  {header.map((item, index) => (
                    <div
                      key={index}
                      className={`flex-none text-white font-AuthorThin font-600 text-xl pl-10 
              ${
                index === 0
                  ? "w-1/12"
                  : index === 1
                  ? "w-3/12"
                  : index === 2
                  ? "w-3/12"
                  : "w-5/12"
              }`}
                    >
                      <div className="md:hidden lg:block">{item?.name}</div>
                      <div className="md:block lg:hidden">
                        {index === 3 ? null : <> {item?.name}</>}
                      </div>
                    </div>
                  ))}
                </div>

                {/* data */}

                {filterdData?.map((item, outerIndex) => (
                  <div
                    key={outerIndex}
                    className="flex justify-center items-center border-b border-tableBorder w-full px-2 py-6"
                  >
                    {header?.map((innerItem, index) => (
                      <div
                        key={index}
                        className={`flex-none   font-AuthorThin font-semibold  text-lg pl-10 
                    ${
                      index === 0
                        ? "w-1/12"
                        : index === 1
                        ? "w-3/12"
                        : index === 2
                        ? "w-3/12"
                        : "w-5/12"
                    }`}
                      >
                        <>
                          {index === 3 ? (
                            <div className="flex  -mt-2">
                              <div className="w-full">
                                <p className="text-lightBlue text-sm lg:text-lg font-semibold mt-2 hidden lg:block">
                                  {item[innerItem?.slug]}
                                </p>
                              </div>

                              <div className="w-full flex gap-4 justify-end">
                                <div className="w-28 lg:w-40">
                                  {" "}
                                  <Link
                                    to={{
                                      pathname: `/products/view/${item?.slug}`,
                                      state: {
                                        link: "/products/active-pharmaceutical-ingredients",
                                      },
                                    }}
                                  >
                                    <Button variant="outlined" className="">
                                      <p className="text-xs md:text-sm font-AuthorThin">
                                        View
                                      </p>
                                    </Button>
                                  </Link>
                                </div>

                                <div
                                  className="w-28 lg:w-40"
                                  onClick={() => {
                                    handleEnquiryForm(item?.id);
                                  }}
                                >
                                  <EnquiryCommon product_id={productId} />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <p className="text-lightBlue">
                              {item[innerItem?.slug] || "--"}
                            </p>
                          )}
                        </>
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              {/* Table For Mobile */}

              <div className="block px-4 md:hidden w-full mt-10 ">
                <div className="h-16 flex justify-center items-center bg-tempBlue rounded-xl">
                  <p className="text-white font-AuthorSemiBold text-xl">
                    Product Name
                  </p>
                </div>

                {/*Data */}

                {filterdData?.map((item, index) => (
                  <div
                    key={index}
                    className="w-full py-6 flex items-center  justify-between border-b border-border "
                  >
                    <div>
                      {" "}
                      <p className="text-lightBlue text-sm lg:text-lg font-AuthorThin font-semibold">
                        {item?.drug_name}
                      </p>
                    </div>

                    <div className="flex gap-4 justify-center items-center">
                      <Link
                        to={{
                          pathname: `/products/view/${item?.slug}`,
                          state: {
                            link: "/products/active-pharmaceutical-ingredients",
                          },
                        }}
                      >
                        <p className="underline text-sm text-lightBlue  font-AuthorThin">
                          View
                        </p>
                      </Link>
                      <div
                        className="w-28"
                        onClick={() => {
                          handleEnquiryForm(item?.id);
                        }}
                      >
                        <EnquiryCommon product_id={productId} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="mt-6">
              <NoDataFound />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Table;
