import React from 'react';

const LandscapeFallbackMessage = () => {
  return (
    <div className="h-screen flex justify-center items-center">
      <p>Please rotate your device back to portrait mode for the best experience.</p>
    </div>
  );
};

export default LandscapeFallbackMessage;
