export const ChartDataStatus = {
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error',
    NO_DATA: 'no_data',
  };
  
  export const ChartColors = {
    contact: '#4EE287',
    job: '#2B66A8',
    notValid: '#eceff2',
  };
  
  export const ChartLegendNames = {
    contact: 'Contact form',
    job: 'Job Applicants',
    notValid: 'Not Valid',
  };
  