import React from "react";
import SideMenu from "../../components/SideMenu";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import { Link } from "react-router-dom";
import { useState } from "react";
import api from "../../../api";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import browserHistory from "../../../config/browserHistory";

const SustainabilityForm = () => {
  const [params, setParams] = useState({
    name: "",
    file: "",
    image_url: "",
    base64String: "",
  });
  const [loading, setLoading] = useState(false);

  let { id } = useParams();

  const handleChange = (e) => {
    setParams({ ...params, name: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let postData = {
      name: params?.name,
      base64String: params?.base64String
        ? `data:@file/pdf;base64,${params?.base64String?.split(",")[1]}`
        : "",
    };

    if (params?.name === "") {
      return toast.error("Please Enter Policy Name", {
        className: "error-info",
      });
    }
    if (params?.base64String === "") {
      return toast.error("Please Select PDF", {
        className: "error-info",
      });
    }
    setLoading(true);
    if (id) {
      api
        .put(`/admin/sustainability-reports/${id}`, postData)
        .then((res) => {
          setLoading(false);
          browserHistory.push("/admin/sustainability/");
          toast.success(res.data.message, { className: "success-info" });
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      api
        .post(`/admin/sustainability-reports`, postData)
        .then((res) => {
          setLoading(false);
          browserHistory.push("/admin/sustainability/");
          toast.success(res.data.message, { className: "success-info" });
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type === "application/pdf") {
      if (file.size > 5 * 1024 * 1024) {
        return toast.error("File Size maximum 5 MB,", {
          className: "error-info",
        });
      }
      const reader = new FileReader();

      reader.onload = (e) => {
        setParams({
          ...params,
          base64String: e.target.result,
          image_url: e.target.result,
        });
      };

      reader.readAsDataURL(file);
    } else {
      toast.error("Invalid File ", { className: "error-info" });
    }
  };

  const fetchData = () => {
    api
      .get(`/admin/sustainability-reports/${id}`)
      .then((res) => {
        setParams({
          name: res.data.data.name,
          base64String: res.data.data.base64String,
          image_url: res.data.data.image_url,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return (
    <div>
      <div className="flex bg-gray-100 admin bg-adminbg">
        <ToastContainer />
        <SideMenu></SideMenu>
        <div className="flex flex-col w-full h-screen overflow-y-hidden">
          <AdminHeader></AdminHeader>
          <div className="flex flex-col w-full overflow-x-hidden border-t border-ThemeLightBlue">
            <main className="flex-grow w-full p-6 ">
              <div>
                <div className="flex items-center breadcrumbs">
                  <Link
                    to="/admin/sustainability"
                    className="text-base text-blue"
                  >
                    Sustainability{" "}
                  </Link>{" "}
                  <svg
                    className="mx-3"
                    width="8"
                    height="12"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 18.25L10.75 10.125L2 2"
                      stroke="#5F7995"
                      strokeWidth="1.875"
                      strokeLinecap="square"
                    />
                  </svg>
                  <span className="text-base text-ThemeBlue font-WavehausBold">
                    {id ? "Edit" : "Add"} report/policy
                  </span>
                </div>

                <hr className="mt-2 border-borderColor" />

                <div className="w-full pr-0 my-6 lg:pr-2">
                  <form onSubmit={handleSubmit}>
                    <div className="px-6 bg-white border rounded-lg shadow-xl py-7 border-borderBlue">
                      <h4 className="pb-2 text-base border-b border-gray text-ThemeBlue font-WavehausBold">
                        Basic Info
                      </h4>
                      <div>
                        <div
                          className={`form-group w-1/2 inline-block my-1 mr-2`}
                        >
                          <input
                            type="text"
                            id="name"
                            value={params?.name}
                            onChange={handleChange}
                            noValidate
                            name="name"
                            className="form-group__input"
                            autoComplete="off"
                            placeholder=" "
                          />
                          <label htmlFor="name" className="form-group__label">
                            Enter report/policy name
                          </label>
                        </div>

                        <div className="inline-block w-1/4 my-1 mr-2 form-group py-6">
                          <div className="pt-16">
                            {params?.image_url ? (
                              <img
                                src="/pdfIcon.svg"
                                className="inline-block object-contain h-28 pb-10"
                              />
                            ) : null}
                          </div>

                          <label
                            className={` form-group__file border border-ThemeLightBlue  bg-ThemeLightBlue`}
                          >
                            {params?.image_url == "" ? (
                              <>
                                <p>Upload Document Here</p>
                                <svg
                                  className="inline-block ml-4"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clipPath="url(#clip0_1067_1292)">
                                    <path
                                      d="M7.99744 15.9964C6.51063 15.9964 5.02336 16.0073 3.53745 15.9932C2.18716 15.9805 1.15317 15.387 0.459596 14.2242C0.159931 13.718 0.00268188 13.1403 0.00449483 12.5521C-0.00278679 11.5303 -5.61854e-05 10.5086 0.00449483 9.48689C0.00449483 9.12644 0.216117 8.84245 0.530592 8.75415C0.836875 8.66814 1.17547 8.77873 1.33066 9.05407C1.40904 9.19692 1.45138 9.35674 1.45399 9.51965C1.46492 10.4959 1.459 11.4721 1.45991 12.4483C1.45991 13.6589 2.34326 14.5396 3.55702 14.5418C6.52034 14.5418 9.4832 14.5418 12.4456 14.5418C13.6589 14.5418 14.5391 13.6607 14.5391 12.4483C14.5391 11.4548 14.5391 10.4617 14.5391 9.46823C14.5358 9.31371 14.5822 9.16221 14.6713 9.03593C14.7604 8.90964 14.8876 8.8152 15.0342 8.76644C15.3237 8.6695 15.6509 8.75324 15.8252 9.00538C15.9112 9.12962 15.9849 9.29392 15.9858 9.44092C15.9927 10.5701 16.0204 11.7015 15.9645 12.8283C15.8875 14.3648 14.6342 15.6997 13.1178 15.9381C12.8769 15.9759 12.6335 15.9954 12.3896 15.9964C10.9265 15.9991 9.46196 15.9964 7.99744 15.9964Z"
                                      fill="#7D94B1"
                                    />
                                    <path
                                      d="M7.27513 2.49057C7.19276 2.56885 7.14725 2.61072 7.10356 2.65441C6.13996 3.61743 5.17666 4.58106 4.21366 5.5453C4.0116 5.74782 3.78268 5.85341 3.49278 5.78969C3.37147 5.76392 3.25883 5.70738 3.16567 5.6255C3.07252 5.54362 3.00199 5.43916 2.96085 5.32216C2.91972 5.20515 2.90937 5.07954 2.93079 4.95738C2.95222 4.83522 3.0047 4.72062 3.08319 4.6246C3.1202 4.58151 3.15939 4.54035 3.20061 4.50127C4.60293 3.09982 6.00449 1.69867 7.40529 0.297828C7.79971 -0.096604 8.19413 -0.0979693 8.58855 0.293732C9.99846 1.70277 11.4081 3.11165 12.8174 4.52038C13.0258 4.72837 13.1273 4.96685 13.0517 5.25949C13.0225 5.3816 12.9617 5.4939 12.8755 5.58519C12.7893 5.67648 12.6806 5.74357 12.5604 5.77977C12.4401 5.81597 12.3125 5.82 12.1902 5.79148C12.0679 5.76296 11.9553 5.70287 11.8635 5.61721C11.6705 5.44153 11.4916 5.25311 11.3069 5.06606C10.4998 4.2593 9.69354 3.45177 8.88801 2.64349C8.84568 2.60116 8.82794 2.53472 8.79699 2.47919L8.72099 2.53335V2.7295C8.72099 5.67045 8.72099 8.6114 8.72099 11.5524C8.72099 11.9701 8.53166 12.2396 8.17486 12.3342C8.0731 12.3609 7.96672 12.365 7.86322 12.346C7.75973 12.3271 7.66166 12.2857 7.57593 12.2247C7.4902 12.1637 7.41891 12.0847 7.36709 11.9931C7.31527 11.9015 7.28419 11.7997 7.27604 11.6948C7.27058 11.6156 7.27194 11.536 7.27194 11.4568V2.49057H7.27513Z"
                                      fill="#7D94B1"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1067_1292">
                                      <rect
                                        width="16"
                                        height="16"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </>
                            ) : (
                              <div className="underline cursor-pointer">
                                Change pdf
                              </div>
                            )}
                            <input
                              type="file"
                              id="image_url"
                              accept=".pdf"
                              className="hidden"
                              noValidate
                              name="image_url"
                              onChange={onFileChange}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="w-full mt-4 text-right">
                      {!loading ? (
                        <button className="px-6 py-3 mb-1 mr-1 text-sm text-white transition-all duration-150 ease-linear rounded-full shadow outline-none cursor-pointer font-WavehausSemiBold bg-green get-started focus:outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-lg">
                          <span>Submit Detail</span>
                        </button>
                      ) : (
                        <button className="px-8 py-3 text-sm text-white transition-all duration-150 ease-linear shadow outline-none rounded-3xl font-WavehausBold bg-green get-started focus:outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-lg">
                          Loading...
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SustainabilityForm;
