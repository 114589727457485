import React from "react";
import api from "../../api";
import TextInput from "../../components/Inputs/TextInput";
import FileUploader from "../../components/Inputs/FileUploader";
import { SelectInput } from "../../components/Inputs/SelectInput";
import TextArea from "../../components/Inputs/TextArea";
import RadioButton from "../../components/Inputs/Radio";

import { regularExpression } from "../Home/helpers";
import { useState } from "react";
import Validator from "validatorjs";
import {  useHistory } from "react-router-dom";
import SuccessModal from "./SuccessModal";
import Button from "../../components/Button";
import { ToastContainer, toast } from "react-toastify";

let jobFields = {
  isJobRole: "",
  fileName: "",
  name: "",
  email: "",
  phone: "",
  resume_url: "",
  join_in_days_type: "",
  job_source_type: "",
  job_role: "default",
  other_info: "",
  is_relocate: "No",
  job_id: "",
};

const JobForm = ({ job, open, handleClose }) => {
  

  const [params, setParams] = useState(jobFields);

  const [errors, setErrors] = useState(jobFields);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  
  const [successModal, setSuccessModal] = useState(false);
  const history = useHistory();

  const handleChange = (e) => {
    setErrors(jobFields);
    setIsSubmitted(false);
    let { value, name } = e.target;

    if (!regularExpression(name, value)) {
      return false;
    }

    setParams({ ...params, [name]: value });
  };





  const onFileChange = (event) => {
    setErrors(jobFields);
    const file = event.target.files[0];

    if (file && file.type === "application/pdf") {
      if (file.size > 5 * 1024 * 1024) {
        return toast.error("File Size maximum 5 MB,", {
          className: "error-info",
        });
      }
      const reader = new FileReader();

      reader.onload = (e) => {
        setParams({
          ...params,
          resume_url: e.target.result,
        });
      };

      reader.readAsDataURL(file);
    } else {
      toast.error("Invalid File ", { className: "error-info" });
    }
  };

  const handleRemoveFile = () => {
    setParams({
      ...params,
      resume_url: "",
      fileName: "",
    });
  };

  const validate = (parameters, rule) => {
    const validator = new Validator(parameters, rule, {
      "min.phone": "Phone number should be 10 digits",
    });

    if (validator.fails()) {
      const fieldErrors = {};
      for (const key in validator.errors.errors) {
        fieldErrors[key] = validator.errors.errors[key][0];
      }

      setErrors(fieldErrors);

      return false;
    }
    setErrors({});
    return true;
  };

  const handleForm = () => {
    let rules = {
      name: "required|min:3|max:35",
      phone: "required|min:10|max:13",
      email: "required|email",
      resume_url: "required",
      job_source_type: "required",
      join_in_days_type: "required",
    };

    let response = validate(params, rules);

    if (!response) {
      return;
    }

    setIsLoading(true);

    let postData = {
      ...params,
      is_relocate: params?.is_relocate === "Yes" ? "1" : "0",
      job_id: job ? job?.id : "",
    };

    api
      .post(`/jobs-home/apply`, postData)
      .then((res) => {
        console.log(res);
        if(res.data.status){
          setSuccessModal(true);
          setIsLoading(false);
          setIsSubmitted(true);
          setParams(jobFields);
          toast.success(res.data.message);
        } else {
          setIsLoading(false);
          toast.error(res.data.message, {
            className: "error-info",
          });
        }
      
      })
      .catch((err) => {
        console.log(err,'fffff');
        setIsLoading(false);
      });
  };

  const handleSuccessModal = () => {
    setSuccessModal(false);
    if (open) {
      handleClose();
    }
    history.push("/careers");
  };

  return (
    <div>
        <ToastContainer />
      <div className="w-full border border-border rounded-lg pb-6">
        {open ? null : (
          <div className="w-full product_bg p-4 rounded-t-lg">
            <p className=" font-AuthorSemiBold text-white text-2xl">
              Submit your application
            </p>
            <p className=" font-AuthorThin text-white text-xl">
              We’re committed to our employees health and happiness
            </p>
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-5 py-6 px-2 md:px-4">
          <div className="lg:col-span-2 font-AuthorBold text-lightBlue text-2xl">
            Personal Information
          </div>
          <div className="lg:col-span-3 flex flex-col gap-3 mt-2 lg:mt-0">
            <TextInput
              label="Your Name*"
              value={params?.name}
              handleChange={handleChange}
              name="name"
              error={errors?.name}
              helperText={errors?.name}
            />
            <TextInput
              label="Contact Number*"
              value={params?.phone}
              handleChange={handleChange}
              name="phone"
              error={errors?.phone}
              helperText={errors?.phone}
            />
            <TextInput
              label="Email Id*"
              value={params?.email}
              handleChange={handleChange}
              name="email"
              error={errors?.email}
              helperText={errors?.email}
            />
          </div>
        </div>
        <div className="px-4">
          <hr className="text-border h-2px bg-border" />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-5 py-6 px-2 md:px-4">
          <div className="lg:col-span-2 font-AuthorBold text-lightBlue text-2xl">
            Upload CV / Resume
          </div>
          <div className="lg:col-span-3 flex flex-col gap-3 mt-2 lg:mt-0">
            <FileUploader
              onFileUpload={onFileChange}
              error={errors?.resume_url}
              handleRemoveFile={handleRemoveFile}
            />
          </div>
        </div>
        <div className="px-4">
          <hr className="text-border h-2px bg-border" />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-5 py-6 px-2 md:px-4">
          <div className="lg:col-span-2 font-AuthorBold text-lightBlue text-2xl">
            Other Questions
          </div>
          <div className="lg:col-span-3 flex flex-col gap-3 mt-2 lg:mt-0">
            <SelectInput
              label="How did you hear about Virupaksha"
              name="job_source_type"
              handleChange={handleChange}
              options={[
                { name: "Google", value: "google" },
                { name: "Facebook", value: "facebook" },
                { name: "Linkedin", value: "linkedin" },
                { name: "Friends", value: "friends" },
                { name: "Others", value: "others" },
              ]}
              error={errors?.job_source_type}
              helperText={errors?.job_source_type}
            />

            <SelectInput
              label="If hired how soon can you join"
              handleChange={handleChange}
              name="join_in_days_type"
              options={[
                { name: "Immediate Joining", value: "0" },
                { name: "15 Days", value: "1" },
                { name: "1 Month", value: "2" },
                { name: "2 Month", value: "3" },
                { name: "3 Month", value: "4" },
              ]}
              error={errors?.join_in_days_type}
              helperText={errors?.join_in_days_type}
            />

            <TextArea
              label="Do you like to provide us more information"
              name="other_info"
              error={errors?.other_info}
              helperText={errors?.other_info}
              value={params?.other_info}
              handleChange={handleChange}
            />

            <div>
              <p className="text-grayCyan font-AuthorThin text-lg">
                Willing to relocate to {job?.work_location || "Hyderbad"}?
              </p>
              <RadioButton
                name="is_relocate"
                handleChange={handleChange}
                value={params?.is_relocate}
                error={errors?.is_relocate}
                helperText={errors?.helperText}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center lg:justify-end px-4">
          <div className="w-44 lg:w-40 justify-self-end">
            <Button variant="contained" onClick={handleForm} loading={isLoading}>
              {isLoading ? "Processing..." : "Submit Application"}
            </Button>
          </div>
        </div>
      </div>

      <SuccessModal
        open={successModal}
        handleSuccessClose={handleSuccessModal}
      />
    </div>
  );
};

export default JobForm;
