import React, { useRef, useState } from "react";

const FileUploader = ({ onFileUpload, error = false, handleRemoveFile,success }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [invalidFile, setInvalidFile] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedTypes = [
        "application/pdf"
      ];

      if (!allowedTypes.includes(file.type)) {
        setInvalidFile(true);
        return;
      }
    }

    setSelectedFile(file);
    onFileUpload(e);
    setInvalidFile(false);
  };

  const handleRemove = (e) => {
    setSelectedFile(null);
    handleRemoveFile();
  };

  return (
    <div>
      {selectedFile === null ? (
        <div className="flex items-center">
          <div
            className={`border-2 border-dashed ${
              error ? "border-red" : "border-border"
            } p-4 rounded-md flex-grow`}
          >
            <input
              type="file"
              accept=".pdf,.docx,.doc"
              onChange={handleFileChange}
              className="hidden"
              id="file-input"
              ref={fileInputRef}
            />
            <label htmlFor="file-input" className="cursor-pointer">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2 md:gap-6">
                  <img
                    src="/home/contact/fileUploader.svg"
                    alt="File Icon"
                    className="w-8 h-8"
                  />

                  <p className=" font-AuthorThin text-xs md:text-base text-secondaryColor">
                    File Format: PDF (max 5MB)
                  </p>
                </div>

                <div>
                  <div>
                    <span className=" bg-lightBlue text-white font-AuthorThin p-3 rounded-lg">
                      Choose a file
                    </span>
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div>
      ) : (
        <div className="flex items-center">
          <div
            className={`border-2 border-dashed ${
              error ? "border-red" : "border-border"
            } p-4 rounded-md flex-grow`}
          >
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2 md:gap-6">
                <img
                  src="/home/contact/fileUploader.svg"
                  alt="File Icon"
                  className="w-8 h-8"
                />

                <p className="hidden lg:block font-AuthorThin text-xs md:text-base text-secondaryColor">
                  File Format: PDF (max 5MB)
                </p>
              </div>

              <div>
                <div className="flex items-center">
                  <span className="block lg:hidden font-bold flex-grow">
                    {selectedFile.name.length > 10
                      ? selectedFile.name.slice(0, 16) + "..."
                      : selectedFile.name}
                  </span>
                  <span className="hidden lg:block font-bold flex-grow">
                    {selectedFile.name.length > 40
                      ? selectedFile.name.slice(0, 40) + "..."
                      : selectedFile.name}
                  </span>
                  <button
                    onClick={handleRemove}
                    className="bg-blue-500 text-red py-2 px-4 rounded-md ml-2"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {error ? (
        <p className="text-red text-xs mt-1 pl-2">File required*</p>
      ) : null}

      {invalidFile ? (
        <p className="text-red text-xs mt-1 pl-2">
          Invalid file type. Please select a PDF
        </p>
      ) : null}
    </div>
  );
};
 
export default FileUploader;
