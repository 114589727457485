import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import JobForm from "./JobForm";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialog-paper": {
    minWidth: "1000px",
    maxWidth: "1000px",
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(0),
  },
  [theme.breakpoints.down("md")]: {
    "& .MuiDialog-paper": {
      minWidth: "600px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paper": {
      minWidth: "360px",
    },
  },
}));

export default function JobModal({ open, handleClose, product_id }) {
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, py: 0, px: 0 }}
          id="customized-dialog-title"
        ></DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <div className="">
            <div className="sticky flex justify-between p-4 top-0 h-24 z-99999 product_bg w-full">
              <div>
                <p className=" font-AuthorSemiBold text-white text-xl lg:text-2xl">
                  Submit your application
                </p>
                <p className=" font-AuthorThin text-white text-base lg:text-xl">
                  We’re committed to our employees health and happiness
                </p>
              </div>
              <p onClick={handleClose}>
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cursor-pointer"
                >
                  <path
                    d="M16.4522 10.2812L10.291 16.4424"
                    stroke="#F7F7F7"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.4534 16.4488L10.2871 10.2812"
                    stroke="#F7F7F7"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle
                    cx="13.5"
                    cy="13.5"
                    r="12.625"
                    stroke="#F7F7F7"
                    stroke-width="1.75"
                  />
                </svg>
              </p>
            </div>

            <JobForm open={open} handleClose={handleClose} />
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
