import { fivePData } from "./helpers";
import Banner from "../Product/Banner";
import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import updateMeta from "../../components/SeoMeta";
import HowWeAreRaising from "../Product/HowWeAreRaising";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Sustainability = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleSlides = (nextSlide, _ref2) => {
    {
      setActiveSlide((prev) => {
        return prev === 4 ? 0 : prev + 1;
      });
    }
  };
  useEffect(() => {
    document.title = 'Sustainability & ESG | Virupaksha Organics Ltd';
    updateMeta("description", "Learn about Virupaksha Organics Ltd's commitment to sustainability and ESG practices, ensuring a responsible and ethical approach to our pharmaceutical and chemical production");
    updateMeta("keywords", " Virupaksha Organics Ltd, sustainability, ESG practices, environmental responsibility, ethical production, pharmaceutical sustainability, chemical industry ESG");
  }, []);

  return (
    <div>
      <Banner
        title="Sustainability"
        styles="bg-sustainability_webp bg-cover"
        searchBox={false}
        content="As a responsible corporate citizen, we at Virupaksha organics Ltd. conduct our business with complete respect for the environment and community. "
      />

      <div className=" w-full grid grid-cols-1 lg:grid-cols-2 md:gap-10 py-10 px-4 lg:px-16 md:px-10">
        <div className="flex justify-center items-center px-3">
          <img src="/home/Layer_1.png" alt="" />
        </div>

        <div className=" flex flex-col items-center justify-center">
          <div className="mt-8 md:mt-0">
            {" "}
            <p className="text-xl  md:text-4xl font-AuthorBold leading-8 md:leading-10 w-full lg:w-10/12 text-ThemeBlue ">
              We at Virupaksha Organics Ltd. are comitted to focusing on what
              matters the most :
            </p>
          </div>
          <div className="mt-6 text-justify  font-AuthorThin text-lightBlue text-base md:text-xl">
            <p className="w-full lg:w-11/12">
              We are committed for creating a better environment where the
              safety, prosperity and well-being of our stakeholders take centre
              stage in our operations. We achieve this by adopting
              industry-leading best practices in sourcing, research and
              development, production, marketing and supply chain management.
            </p>
          </div>
        </div>
      </div>

      <div className="px-4 lg:px-24 py-6 ">
        <div className=" product_bg w-full rounded-xl py-4 md:py-10">
          <div className="py-10">
            <p className="text-white font-AuthorSemiBold text-center text-2xl md:text-4xl w-11/12  lg:w-7/12 m-auto">
              The 2030 Agenda is based on five dimensions, also known as the 5Ps
            </p>
          </div>

          <div className="w-full px-0 md:px-20 lg:px-32">
            <Carousel
              additionalTransfrom={0}
              arrows={false}
              autoPlay
              autoPlaySpeed={2000}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover={true}
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              renderDotsOutside={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable={false}
              beforeChange={handleSlides}
            >
              {fivePData?.map((item, index) => (
                <div
                  key={index}
                  className="py-6 cursor-pointer h-48 lg:h-52 mx-5 px-3 lg:px-4 md:px-3 marquee_card rounded-lg"
                >
                  <img src={item?.image} alt="" className="w-12" />

                  <p className="py-2 text-white text-xl lg:text-3xl font-AuthorSemiBold">
                    {item?.name}
                  </p>

                  <p className="text-white font-AuthorThin text-base lg:text-xl">
                    {item?.content}
                  </p>
                </div>
              ))}
            </Carousel>
          </div>

          <div className="w-full  flex justify-center items-center gap-3 mt-6">
            {Array.from({ length: 5 }).map((item, index) => (
              <div
                key={index}
                className={`${
                  index === activeSlide ? "bg-green" : "bg-lightGray"
                } w-3 h-3 lg:w-8 lg:h-1 rounded-full transition-all duration-1000 `}
              ></div>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-8 px-4 lg:px-24">
        <Link
          to="/sustainability/listing"
          className="underline text-blueNavy text-xl md:text-2xl lg:text-3xl font-AuthorLight"
        >
          Reports and Policies &gt;&gt;
        </Link>
      </div>

      <div className="pb-10">
        <HowWeAreRaising />
      </div>
    </div>
  );
};

export default Sustainability;
