export const cards = [
  {
    title: "Regulatory Approvals",
    icon: "/home/about/approvals.svg",
  },
  {
    title: "Strong-And Transparent Leadership",
    icon: "/home/about/leadership.svg",
  },
  {
    title: "Responsible Manufacturing Operations",
    icon: "/home/about/operations.svg",
  },
  {
    title: "Integrated Manufacturing Facilities",
    icon: "/home/about/facilities.svg",
  },
];

export const strength = [
  {
    title: "Strong and Transparent Leadership",
    icon: "/home/about/strength1.svg",
    content:
      "We follow lean management to have continuous supply with always maintaining a safety stock.",
  },
  {
    title: "Responsible Manufacturing Operations",
    icon: "/home/about/strength2.svg",
    content:
      "We are strong at manufacturing starting materials of the key starting materials",
  },
  {
    title: "Sustainable Procurement Process",
    icon: "/home/about/strength3.svg",
    content:
      "Our team is highly experienced in handling almost all the hazardous reactions",
  },
  {
    title: "Corporate Social Responsibility",
    icon: "/home/about/strength4.svg",
    content:
      "We manufacture from the SMs giving us the highest advantage of not being dependent.",
  },
  {
    title: "Social Equality",
    icon: "/home/about/strength5.svg",
    content:
      "Utmost care and in-process checks are kept to maintain the highest quality.",
  },
];
