import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const RadioButton = ({ handleChange, value, error, helperText, name }) => {
  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="choose-option"
        name={name}
        value={value}
        onChange={handleChange}
      >
        <div className="flex">
          <FormControlLabel
            sx={{ color: "#1C3F67", fontFamily: "Author thin" }}
            value="Yes"
            control={<Radio />}
            label="Yes"
          />
          <FormControlLabel
            sx={{ color: "#1C3F67", fontFamily: "Author thin" }}
            value="No"
            control={<Radio />}
            label="No"
          />
        </div>
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButton;
