import React, {useEffect} from 'react'

import Marquee from 'react-fast-marquee'
import { certInfo } from './helpers'
import ContactForm from '../../components/ContactForm'
import WhatWeDo from './WhatWeDo'
import WhyToChooseUs from './WhyToChooseUs'
import Units from './Units'
import WhoAreWe from './WhoAreWe'
import updateMeta from "../../components/SeoMeta";

const Home = () => {
  useEffect(() => {
    document.title = 'Virupaksha Organics Ltd | Leading Pharmaceutical and Fine Chemical Solutions';
    updateMeta("description", "Welcome to Virupaksha Organics Ltd, a global leader in pharmaceuticals and fine chemicals. Explore our high-quality APIs, intermediates, and specialty chemicals, and learn more about our commitment to innovation and excellence");
    updateMeta("keywords", "Virupaksha Organics Ltd, pharmaceuticals, fine chemicals, active pharmaceutical ingredients, APIs, chemical solutions, specialty chemicals, pharmaceutical innovation");
  }, []);
  return (
    <div className="w-full ">
      <div className="relative h-88vh lg:h-screen md:h-60vh  bg-banner1 bg-center bg-cover">
        <div className="h-30vh md:h-20vh lg:h-30vh 3xl:h-40vh"></div>

        <div className="w-full   lg:w-full px-4 md:px-8 lg:px-12">
          <div className="title1   md:w-11/12 w-full lg:w-8/12 ">
            <p className="leading-tight text-lightBlue pb-4">
              {' '}
              We, at <span className="text-green">Virupaksha</span> strive
              constantly to supply the highest quality products to our customers
              with sustainable best practices in all our operations.
            </p>
          </div>
        </div>
        <WhatWeDo />
      </div>

      {/* Who we are */}
      <WhoAreWe />

      <div className="lg:p-20">
        <WhyToChooseUs />
      </div>

      <div className="flex mt-4 flex-col  lg:grid  lg:grid-cols-2">
        {/* Tabs & Info */}
        <div className="lg:w-[55%] w-full px-4 md:px-8 py-4 lg:p-20  bg-greenishBlue h-full">
          <p className=" uppercase sub-heading2 text-green">Unit details</p>
          <p className="title1 text-lightBlue">Hubs of Quality Production</p>

          <Units />

          <div>
            <p className="title1 text-lightBlue">
              Certification & Accreditations
            </p>

            <div className="my-5">
              <Marquee>
                {React.Children.toArray(
                  certInfo.map((item) => (
                    <div className="mx-5">
                      <img
                        src={item.icon}
                        alt={item.icon}
                        style={{
                          width: item.name === 'sustain' ? '85px' : 'auto',
                          height: item.name === 'sustain' ? '85px' : 'auto',
                        }}
                      />
                    </div>
                  )),
                )}
              </Marquee>
            </div>
          </div>
        </div>

        {/* Contact Form */}
        <div className="lg:w-[45%] w-full px-4 md:px-8 py-4  lg:p-20  bg-lightWhite h-full flex flex-col items-center justify-center">
          <div className="flex flex-col items-center justify-center gap-2 lg:w-[417px] w-full">
            <h2 className="text-lightBlue font-AuthorSemiBold text-2xl lg:text-4xl">
              For Enquiry - Please Contact Us
            </h2>
            <p className=" font-AuthorThin text-base text-lightBlue">
              Get in touch by writing to us
            </p>

            <div className="w-full mt-2">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
