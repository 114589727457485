
import React, { Component } from 'react';
import api from '../../api/index'
import AdminHeader from '../components/AdminHeader/AdminHeader'
import SideMenu from '../components/SideMenu'

class Gallery extends Component {
	currentUser={};

	state={
		isImageAction:false,
		pageNo:'',
		total:'0',
		totalPage:'0',
		gallerys:[]
	}

	componentDidMount() {
		this.getGallerys(1);
	}

	getGallerys=(page_no)=>{
		api.get(`/admin/gallery`, {
			params: {
				page_no: page_no,
				size:10
			}}).then(res=>{
			this.setState({
				gallerys:res.data.data.data,
				total:res.data.data.pagination.total,
				totalPage:res.data.data.pagination.lastPage,
				pageNo: page_no
			})
			console.log(this.state)
		}).catch(err => {
			console.log(err)
		})
	}

	deleteImg=(id)=>{
		api.delete(`/admin/gallery/` + id).then(res=>{
			this.setState({
				gallerys: this.state.gallerys.filter(item => item.id != id),
				total:this.state.total - 1
			})
		}).catch(err => {
			console.log(err)
		})
	}

	pagination=()=>{
		var page = []
		for (let i = 1; i <= Math.ceil(this.state.total/10); i++) {
			page.push(<li className="paginate_button page-item">
				{this.state.pageNo==i?
					<li className="paginate_button page-item active">
						<button aria-controls="dataTable" className="page-link "onClick={() =>this.getGallerys(i)} >{i}</button> </li>:
				<button aria-controls="dataTable" className="page-link" onClick={() =>this.getGallerys(i)}>{i}</button>
				}
		</li>)
		}
		return page;
	}


	
	onFileChange = (event) => {
		console.log(event)
		if (event.target.files && event.target.files[0]) {
			if (
			  event.target.files[0].type === "image/jpeg" ||
			  event.target.files[0].type === "image/png" ||
			  event.target.files[0].type === "image/jpg"
			) {
			  var reader = new FileReader();
			  reader.onload = (e) => {
				let data = {
					image:e.target.result,
					file_name:event.target.files[0].name,
					file_size:(event.target.files[0].size).toString(),
				  };
				  this.setState({isImageAction:true});
				  var self = this;
				  api.post("/admin/gallery", data)
					  .then((res) => {
						self.setState({isImageAction:false});
						this.getGallerys(1);
					  })
					  .catch((err) => {
						self.setState({isImageAction:false});
					});
					  
			  };
	  
			  reader.readAsDataURL(event.target.files[0]);
			} else {
			  alert('invalid image')
		}
	}

		
	  };
	
	render() {
		return (
			<div className="flex bg-gray-100 admin bg-adminbg">
			<SideMenu></SideMenu>
			<div className="flex flex-col w-full h-screen overflow-y-hidden">
			<AdminHeader></AdminHeader>
			<div className="flex flex-col w-full overflow-x-hidden border-t border-ThemeLightBlue">
            <main className="flex-grow w-full p-6 ">
				<div>

				<h2 className="text-xl text-ThemeBlue font-WavehausBold">Manage gallery ({this.state.total})</h2>	
					<hr className="mt-2 border-borderColor"/>
						<div className="w-full pr-0 my-6 lg:pr-2">
							<div className="px-6 bg-white border rounded-lg shadow-xl py-7 border-borderBlue">
							<div className="py-4 text-center border-2 border-dashed cursor-pointer border-ThemeLightBlue rounded-xl">
							{!this.state.isImageAction ? <label>
							<svg className="m-auto" width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="52.0303" height="52.0303" rx="7.5" fill="#4EE287" stroke="#54C780"/>
<path d="M23 21.5L23.5303 20.9697C23.2374 20.6768 22.7626 20.6768 22.4697 20.9697L23 21.5ZM18.5 17.75H36.5V16.25H18.5V17.75ZM37.25 18.5V36.5H38.75V18.5H37.25ZM36.5 37.25H18.5V38.75H36.5V37.25ZM17.75 36.5V18.5H16.25V36.5H17.75ZM18.5 37.25C18.0858 37.25 17.75 36.9142 17.75 36.5H16.25C16.25 37.7426 17.2574 38.75 18.5 38.75V37.25ZM37.25 36.5C37.25 36.9142 36.9142 37.25 36.5 37.25V38.75C37.7426 38.75 38.75 37.7426 38.75 36.5H37.25ZM36.5 17.75C36.9142 17.75 37.25 18.0858 37.25 18.5H38.75C38.75 17.2574 37.7426 16.25 36.5 16.25V17.75ZM18.5 16.25C17.2574 16.25 16.25 17.2574 16.25 18.5H17.75C17.75 18.0858 18.0858 17.75 18.5 17.75V16.25ZM17 32.75H38V31.25H17V32.75ZM17.5303 28.0303L23.5303 22.0303L22.4697 20.9697L16.4697 26.9697L17.5303 28.0303ZM22.4697 22.0303L32.9697 32.5303L34.0303 31.4697L23.5303 20.9697L22.4697 22.0303ZM32 23.75C31.5858 23.75 31.25 23.4142 31.25 23H29.75C29.75 24.2426 30.7574 25.25 32 25.25V23.75ZM32.75 23C32.75 23.4142 32.4142 23.75 32 23.75V25.25C33.2426 25.25 34.25 24.2426 34.25 23H32.75ZM32 22.25C32.4142 22.25 32.75 22.5858 32.75 23H34.25C34.25 21.7574 33.2426 20.75 32 20.75V22.25ZM32 20.75C30.7574 20.75 29.75 21.7574 29.75 23H31.25C31.25 22.5858 31.5858 22.25 32 22.25V20.75Z" fill="white"/>
</svg>

								<p className="text-xl text-ThemeBlue font-WavehausBold">Drop your image here, or <strong className="text-green">browse</strong></p>
								<p className="text-sm text-blue">Supports .JPG, PNG and JPEG2000</p>
							
								
                            <input type="file" accept="image/jpg, image/jpeg, image/png" className="hidden" required  onChange={this.onFileChange}/>
                          </label> : <label className="flex items-center justify-center h-24">Uploading...</label>}
							
							

							</div>

							<ul className="my-8">
							{this.state.gallerys.map((item,index) => {
											return(<li className="relative inline-block m-1 align-top border w-60 h-72 hove-me rounded-xl border-blue" key={item.id}>
									<img className="object-cover w-full h-60 rounded-t-xl" src={item.url} alt=""/>
									<div className="flex justify-between p-3">
										<p className="w-32 truncate text-ThemeBlue font-WavehausSemiBold">{item.file_name}</p>
										<p  className="text-ThemeBlue">{(item.file_size/1024).toFixed(2)} MB</p>
									</div>
									<div className="absolute hidden img-visi top-3 right-3" onClick={() =>this.deleteImg(item.id)}>
									<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="#FF5555" stroke="#E13B3B"/>
<path d="M12.5 10.5V9C12.5 8.44772 12.9477 8 13.5 8H17.5C18.0523 8 18.5 8.44772 18.5 9V10.5M8 11H9.5M9.5 11H21.5M9.5 11V21C9.5 21.5523 9.94772 22 10.5 22H20.5C21.0523 22 21.5 21.5523 21.5 21V11M21.5 11H23M15.5 14.5V19.5M12.5 16.5V19.5M18.5 16.5V19.5" stroke="white"/>
</svg>

									</div>
								</li>
							)
						})}	
							</ul>
							{ this.state.gallerys.length == 0 ? <div className="mt-4 text-center">No Data Found</div> : ''}
							</div>
							</div>
							<ul className="flex items-center justify-end pagination">
								<span className="mr-2 -mt-1 text-blue font-WavehausSemiBold">{this.state.pageNo} of {this.state.totalPage} </span>
							{/* {this.pagination()}  */}
									{this.state.pageNo <= 1 ?
										<li className="mx-2 paginate_button page-item previous disabled" id="dataTable_previous">
											<button className="page-link"><svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="18" cy="18" r="17.5" stroke="#cfd5dc"/>
<path d="M15.6717 22.6613L16.1667 23.1563L17.1566 22.1663L16.6616 21.6714L15.6717 22.6613ZM11.5 17.4997L11.005 17.0047L10.5101 17.4997L11.005 17.9946L11.5 17.4997ZM16.6616 13.328L17.1566 12.833L16.1667 11.8431L15.6717 12.338L16.6616 13.328ZM16.6616 21.6714L11.995 17.0047L11.005 17.9946L15.6717 22.6613L16.6616 21.6714ZM11.995 17.9946L16.6616 13.328L15.6717 12.338L11.005 17.0047L11.995 17.9946ZM11.5 18.1997H26.6667V16.7997H11.5V18.1997Z" fill="#1B3F67"/>
</svg>
</button>
										</li>:
										<li className="mx-2 paginate_button page-item previous" id="dataTable_previous">
										
											<button className="page-link" onClick={() =>this.getGallerys(this.state.pageNo-1)} ><svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="18" cy="18" r="17.5" stroke="#4EE287"/>
<path d="M15.6717 22.6613L16.1667 23.1563L17.1566 22.1663L16.6616 21.6714L15.6717 22.6613ZM11.5 17.4997L11.005 17.0047L10.5101 17.4997L11.005 17.9946L11.5 17.4997ZM16.6616 13.328L17.1566 12.833L16.1667 11.8431L15.6717 12.338L16.6616 13.328ZM16.6616 21.6714L11.995 17.0047L11.005 17.9946L15.6717 22.6613L16.6616 21.6714ZM11.995 17.9946L16.6616 13.328L15.6717 12.338L11.005 17.0047L11.995 17.9946ZM11.5 18.1997H26.6667V16.7997H11.5V18.1997Z" fill="#1B3F67"/>
</svg>
</button>
										</li>
									}
								
									{this.state.pageNo >= this.state.totalPage ?
										<li className="mx-2 paginate_button page-item next disabled" id="dataTable_next">
											<button className="page-link" ><svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="18" cy="18" r="17.5" stroke="#cfd5dc"/>
<path d="M20.9963 12.3377L20.5013 11.8427L19.5114 12.8327L20.0063 13.3277L20.9963 12.3377ZM25.168 17.4993L25.6629 17.9943L26.1579 17.4993L25.6629 17.0044L25.168 17.4993ZM20.0063 21.671L19.5114 22.166L20.5013 23.156L20.9963 22.661L20.0063 21.671ZM20.0063 13.3277L24.673 17.9943L25.6629 17.0044L20.9963 12.3377L20.0063 13.3277ZM24.673 17.0044L20.0063 21.671L20.9963 22.661L25.6629 17.9943L24.673 17.0044ZM25.168 16.7993L10.0013 16.7994L10.0013 18.1994L25.168 18.1993L25.168 16.7993Z" fill="#1B3F67"/>
</svg>
</button>
										</li>:
										<li className="mx-2 paginate_button page-item next " id="dataTable_next">
											<button className="page-link" onClick={() =>this.getGallerys(this.state.pageNo+1)}><svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="18" cy="18" r="17.5" stroke="#4EE287"/>
<path d="M20.9963 12.3377L20.5013 11.8427L19.5114 12.8327L20.0063 13.3277L20.9963 12.3377ZM25.168 17.4993L25.6629 17.9943L26.1579 17.4993L25.6629 17.0044L25.168 17.4993ZM20.0063 21.671L19.5114 22.166L20.5013 23.156L20.9963 22.661L20.0063 21.671ZM20.0063 13.3277L24.673 17.9943L25.6629 17.0044L20.9963 12.3377L20.0063 13.3277ZM24.673 17.0044L20.0063 21.671L20.9963 22.661L25.6629 17.9943L24.673 17.0044ZM25.168 16.7993L10.0013 16.7994L10.0013 18.1994L25.168 18.1993L25.168 16.7993Z" fill="#1B3F67"/>
</svg></button>
										</li>
									}
								</ul>
				
					</div>
				</main>
			</div>
			</div>
		
			</div>
		);
	}
}

export default Gallery;
