import React from "react";

const Loader = () => {
  return (
    <div className="w-full text-3xl items-center h-80vh flex justify-center">
      Loading...
    </div>
  );
};

export default Loader;
