import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import React from "react";

export const useStyles = makeStyles({
  error: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
      borderRadius: "8px",
    },
  },
  select: {
    "& ul": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
    "& li": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  icon: {
    fill: "white",
  },
  root: {
    "& .MuiOutlinedInput-input": {
      //   color: "#141C4C",
      color: "#232E69",
      fontFamily: "Author thin",
      size: "20px",
    },
    "& .MuiInputLabel-root": {
      color: "#232E69",
      fontFamily: "Author thin",
      size: "20px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E7E8ED",
      borderRadius: "8px",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#141C4C",
    },
    "&:hover .MuiInputLabel-root": {
      color: "#6A6A78",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#8A9BAF",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      //   color: "#141C4C",
      borderColor: "#8A9BAF",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#232E69",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#8A9BAF",
    },
  },
});

export const SelectInput = ({
  handleChange,
  value,
  label,
  error,
  helperText,
  options,
  width,
  name,
  required,
  readonly,
  disabled,
  fallback_message,
  bgcolor,
  id,
}) => {
  const classes = useStyles();
  return (
    <div className="w-full">
      <FormControl
        className={!error ? classes.root : classes.error}
        fullWidth
        error={error}
        disabled={disabled}
      >
        <InputLabel id={id ? id : "label-id"}>{label}</InputLabel>
        <Select
          labelId="select-input-label"
          style={{
            borderRadius: "8px",
            width,
            backgroundColor: bgcolor || "transparent",
          }}
          // IconComponent={KeyboardArrowDownIcon}
          MenuProps={{
            sx: {
              "&& .MuiMenuItem-root": {
                backgroundColor: "#F5FBFD",
                border: "1px solid #E7E8ED !important",
                color: "#141C4C",
                "&:hover": {
                  backgroundColor: "#F5FBFD !important",
                },
              },
              "&& .MuiMenu-list": {
                padding: "0",
              },

              "&& .Mui-selected": {
                color: "#0C8EC7 !important",
                backgroundColor: "#F5FBFD",
              },
            },
          }}
          sx={{
            color: "#141C4C",
            ".MuiSvgIcon-root ": {
              fill: "#141C4C !important",
            },
          }}
          required={required}
          value={value}
          onChange={handleChange}
          label={label}
          name={name}
          error={error}
          fullWidth
          readOnly={readonly}
        >
          {options && options.length > 0 ? (
            options?.map((item) => (
              <MenuItem value={item?.value || item?.name}>
                {item?.name}
              </MenuItem>
            ))
          ) : (
            <p className="text-SpaceCadet p-4 text-xl">
              {fallback_message ? fallback_message : "Options Not Found!"}
            </p>
          )}
        </Select>

        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
};
