import React, { useMemo, useState, useEffect } from "react";
import Banner from "../Product/Banner";
import HowWeAreRaising from "../Product/HowWeAreRaising";

import api from "../../api/index";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import JobModal from "./JobModal";
import Button from "../../components/Button";
import updateMeta from "../../components/SeoMeta";
const careerData = [
  {
    name: "Inhouse canteen",
    content:
      "Our in-house canteen offers a diverse menu of delicious, freshly prepared meals to keep our team energized and satisfied throughout the day.",
    image: "/images/career/inhouse.svg",
  },
  {
    name: "Friendly workspace",
    content:
      "In our friendly workspace, you'll find a warm and supportive atmosphere where collaboration and positivity are key to our shared success.",
    image: "/images/career/workspace.svg",
  },
  {
    name: "Competitive salary",
    content:
      "Our employees receive a competitive salary that reflects their skills and experience, ensuring their hard work is duly rewarded.",
    image: "/images/career/salary.svg",
  },
];

const Career = () => {
  const [open, setOpen] = useState(false);
  const [jobList, setJobList] = useState([]);
  const fetchJobs = (page_no) => {
    api
      .get(`/jobs-home?${page_no}`)
      .then((res) => {
        console.log(res.data);
        setJobList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useMemo(() => {
    fetchJobs(1);
  }, []);

  useEffect(() => {
    document.title = 'Careers | Virupaksha Organics Ltd';
    updateMeta("description", "Join the team at Virupaksha Organics Ltd and be part of a leading company in the pharmaceutical and fine chemical industry. Explore our career opportunities today");
    updateMeta("keywords", "Virupaksha Organics Ltd, careers, job opportunities, pharmaceutical jobs, chemical industry careers, join our team, employment opportunities");
  }, []);


  return (
    <div>
      <div>
        <Banner
          title="Careers"
          styles="bg-career bg-cover"
          searchBox={false}
          content="Embark on a rewarding journey with us, as we offer you the opportunity to build a fulfilling career alongside a passionate and dedicated team."
        />

        <div className="py-12">
          <p className="text-center text-lightBlue font-AuthorSemiBold text-3xl">
            Perks of joining us
          </p>

          <p className="w-11/12 md:w-8/12 lg:w-full mx-auto text-center mt-2 text-lightBlue   font-AuthorThin text-lg">
            Explore the possibilities of advancing your career in a field
            dedicated to improving global health and well-being.
          </p>
        </div>

        <div className="w-full  py-8  product_bg grid grid-cols-1 gap-6 lg:grid-cols-3 ">
          {careerData?.map((item, index) => (
            <div key={index} className=" flex items-center   flex-col w-full">
              <img src={item?.image} alt="icon" className="w-20" />

              <p className="text-green font-AuthorSemiBold text-xl lg:text-2xl">
                {item?.name}
              </p>
              <p className="w-10/12 md:w-8/12 lg:w-3/5 text-base mt-1   lg:text-xl font-AuthorLight text-white text-justify opacity-80">
                {item?.content}
              </p>
            </div>
          ))}
        </div>

        <div className="py-12">
          <p className="text-center text-lightBlue font-AuthorSemiBold text-3xl">
            Current openings
          </p>

          <p className="w-11/12 md:w-8/12 mx-auto text-center mt-2 text-lightBlue   font-AuthorThin text-lg">
            Explore our current job openings and embark on an exciting career
            journey with us. Join our team and make a difference!
          </p>

          <p className="text-center mt-1 text-lightBlue font-AuthorSemiBold text-lg underline">
            {jobList.length || 0} openings
          </p>
        </div>

        <div className="w-full md:px-6 lg:px-12  ">
          <div className="w-full border-0 md:border-2 p-2 md:p-4 rounded-lg border-border">
            {jobList &&
              jobList?.map((item, index) => (
                <div
                  key={index}
                  className="w-full border-b-2 p-4 border-border grid grid-cols-1 gap-6 md:gap-0 md:grid-cols-12"
                >
                  <div className="md:col-span-7 lg:col-span-4  flex gap-3 md:gap-2 ">
                    <div>
                      <p className="text-xl lg:text-2xl font-AuthorSemiBold text-lightBlue">
                        {item?.name || "--"}
                      </p>
                      <p className="text-lightBlue font-AuthorThin text-lg ">
                        {moment(item?.updated_at).format("ll")}{" "}
                        <span>.({item?.work_type || "--"})</span>
                      </p>
                    </div>
                    <div>
                      <p className="text-base lg:text-lg font-AuthorSemiBold text-green mt-1 ">
                        {item?.work_location || "--"}
                      </p>
                    </div>
                  </div>
                  <div className="md:col-span-5 lg:col-span-8 flex justify-center md:justify-end">
                    <div className="w-80 md:w-40">
                      <Link to={`/careers/job/${item?.job_id}`}>
                        <Button variant="contained">
                          <p className="text-base font-AuthorSemiBold">Apply</p>
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="py-12">
          <p className="w-11/12 md:w-8/12 mx-auto text-center mt-2 text-lightBlue   font-AuthorSemiBold text-2xl">
            Don’t see a role that fits?
          </p>
          <div className="m-auto py-3 w-80 md:w-40">
            <Button
              variant="contained"
              onClick={() => {
                setOpen(true);
              }}
            >
              <p className="text-base font-AuthorSemiBold">Apply Anyway</p>
            </Button>
          </div>
        </div>

        <JobModal
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
        />

        <div className="pb-10">
          <HowWeAreRaising />
        </div>
      </div>
    </div>
  );
};

export default Career;
