import React from "react";
import SearchBar from "../../components/Inputs/SearchBar";

const Banner = ({
  title,
  searchKey,
  handleChange,
  styles,
  searchBox,
  content,
}) => {
  return (
    <div>
      <div className={`relative h-70vh lg:h-80vh md:h-50vh ${styles}`}>
        <div className="h-30vh md:h-25vh lg:h-30vh"></div>
        <div className="absolute  w-full">
          <div className="w-full h-full flex flex-col justify-center items-center px-3">
            <img
              src="/images/sustainability/ecovadis.png"
              className="h-24 w-24"
              alt="ecovadis"
            />

            <br />

            <h1 className="text-green text-center font-AuthorBold text-2xl md:text-4xl">
              {title}
            </h1>

            <p className="w-full mt-2 md:w-11/12 text-center   lg:px-0 lg:w-6/12 text-white text-lg md:text-xl font-AuthorThin">
              {content}
            </p>
          </div>
        </div>

        <div
          className={`h-16 -bottom-8 absolute w-full flex items-center justify-center   `}
        >
          {searchBox ? (
            <div className="w-full lg:w-5/12 h-full lg:px-0 px-4 md:px-20">
              <SearchBar
                name=""
                value={searchKey}
                placeholder="Search for the product"
                handleChange={handleChange}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Banner;
