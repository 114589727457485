import React, { Component } from "react";
import api from "../../../api/index";
import { Link } from "react-router-dom";
import { authenticationService } from "../../../config/auth.service";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import SideMenu from "../../components/SideMenu";
import Moment from "moment";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../components/Loader";
import moment from "moment";

class ContactEdit extends Component {
  state = {
    currentUser: authenticationService.currentUserValue,
    id: this.props.match.params.id,
    status: "",
    note: "",
    contacts: "",
    notes: [],
    isLoading: false,
    is_contacted: false,
    success: "",
    formErrors: { note: "" },
    formValid: false,
    loading: false,
  };

  componentDidMount() {
    this.getContactById();
  }

  getContactById = () => {
    this.setState({ loading: true });
    api
      .get(`/admin/contacts/` + this.state.id)
      .then((res) => {
        console.log(res.data);
        this.setState({
          contacts: res.data.data,
          notes: res.data.data.notes,
          status: res.data.data.is_contacted,
          is_contacted: res.data.data.is_contacted,
          loading: false,
        });
        console.log(this.state);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  change = (event) => {
    this.setState({ status: event.target.value });
  };

  handleChange = (e) => {
    this.setState({ success: "" });
    let nameKey;
    let valueData;
    if (e["data"]) {
      nameKey = e.data;
      valueData = "";
    }
    if (e.type) {
      nameKey = e.target.name;
      valueData = e.target.value;
    }
    const name = nameKey;
    const value = valueData;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let noteValid = this.state.noteValid;

    switch (fieldName) {
      case "note":
        noteValid = value.length > 0;
        fieldValidationErrors.note = noteValid ? "" : " is invalid";
        break;
      default:
        break;
    }
    this.setState(
      { formErrors: fieldValidationErrors, noteValid: noteValid },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({ formValid: this.state.noteValid });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  addNote = (event) => {
    event.preventDefault();
    console.log(this.state);
    console.log(this.state.formValid);
    if (this.state.formValid) {
      let data = {
        note: {
          note: this.state.note,
          is_status_updated: this.state.status ? true : false,
        },
        is_contacted: Boolean(this.state.status),
      };
      this.setState({ isLoading: true });
      var self = this;
      api
        .put(`/admin/contacts/` + this.state.id, data)
        .then((res) => {
          console.log(res.data);
          this.getContactById();
          self.setState({
            success: res.data.message,
            note: "",
            isLoading: false,
          });
          toast.success(res.data.message, { className: "success-info" });
        })
        .catch((err) => {
          self.setState({ isLoading: false });
          toast.error("Some thing went wrong!, please try again", {
            className: "error-info",
          });
        });
    } else {
      toast.error("Please fill the required details", {
        className: "error-info",
      });
      if (this.state.note === "") {
        this.handleChange({ data: "note" });
      }
      this.scrollToFirstInvalidControl();
      return false;
    }
  };

  scrollToFirstInvalidControl() {
    const firstInvalidControl = document.querySelector(".has-error");

    if (firstInvalidControl) {
      $(window).scrollTop($("textarea").offset().top - 120);
      firstInvalidControl.focus();
    }
  }

  render() {
    return (
      <div className="flex bg-gray-100 admin bg-adminbg">
        <ToastContainer />
        <SideMenu></SideMenu>
        <div className="flex flex-col w-full h-screen overflow-y-hidden">
          <AdminHeader></AdminHeader>
          <div className="flex flex-col w-full overflow-x-hidden border-t border-ThemeLightBlue">
            {this.state.loading ? (
              <Loader />
            ) : (
              <main className="flex-grow w-full p-6 ">
                <div>
                  <div className="flex items-center breadcrumbs">
                    <Link
                      to="/admin/contact-list"
                      className="text-base text-blue"
                    >
                      Contact form submissions{" "}
                    </Link>{" "}
                    <svg
                      className="mx-3"
                      width="8"
                      height="12"
                      viewBox="0 0 13 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 18.25L10.75 10.125L2 2"
                        stroke="#5F7995"
                        strokeWidth="1.875"
                        strokeLinecap="square"
                      />
                    </svg>
                    <span className="text-base text-ThemeBlue font-WavehausBold">
                      View details
                    </span>
                  </div>

                  <hr className="mt-2 border-borderColor" />
                  <div className="w-full pr-0 my-6 lg:pr-2">
                    <div className="px-6 bg-white border rounded-lg shadow-xl py-7 border-borderBlue">
                      <h4 className="pb-2 text-base border-b border-gray text-ThemeBlue font-WavehausBold">
                        User Detail
                      </h4>

                      <div className="flex items-center justify-between mt-8 mb-4">
                        <figure className="flex items-center">
                          <img
                            className="z-10 w-16 h-16 overflow-hidden rounded-full realtive"
                            src={this.state.currentUser.user.image}
                            alt=""
                          />
                          <figcaption className="ml-4">
                            <p className="text-ThemeBlue font-WavehausSemiBold">
                              {" "}
                              {this.state.contacts.name}
                            </p>
                            <p className="text-blue">
                              {this.state.contacts.email}
                            </p>
                          </figcaption>
                        </figure>
                        <div>
                          <p className="text-blue">Phone Number</p>
                          <p className="text-ThemeBlue font-WavehausSemiBold">
                            {this.state.contacts.phone}
                          </p>
                        </div>
                        <div>
                          <p className="text-blue">Date Submitted</p>
                          <p className="text-ThemeBlue font-WavehausSemiBold">
                            {moment(this.state.contacts.created_at).format(
                              "DD MMM YYYY"
                            )}
                          </p>
                        </div>

                        <div>
                          <p className="text-blue">Status</p>
                          {this.state.is_contacted ? (
                            <div className="text-green">Contacted</div>
                          ) : (
                            <select
                              className="status green selected drop-select"
                              onChange={this.change}
                              value={this.state.status}
                            >
                              <option value="true">Contacted</option>
                              <option value="false">Not Contacted</option>
                            </select>
                          )}
                        </div>
                      </div>
                      <div className="flex items-center justify-between mt-8">
                        {this.state.contacts.product_name ? (
                          <div>
                            <p className="text-blue">Product name</p>
                            <p className="text-ThemeBlue font-WavehausSemiBold">
                              {this.state.contacts.product_name}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}{" "}
                        {this.state.contacts.company ? (
                          <div>
                            <p className="text-blue">Company</p>
                            <p className="text-ThemeBlue font-WavehausSemiBold">
                              {this.state.contacts.company}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.contacts.qty ? (
                          <div>
                            <p className="text-blue">Quantity</p>
                            <p className="text-ThemeBlue font-WavehausSemiBold">
                              {this.state.contacts.qty}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          <p className="text-blue">Description</p>
                          <p className="text-ThemeBlue font-WavehausSemiBold">
                            {this.state.contacts.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full pr-0 my-6 lg:pr-2">
                    <div className="px-6 bg-white border rounded-lg shadow-xl py-7 border-borderBlue">
                      <h4 className="pb-2 text-base border-b border-gray text-ThemeBlue font-WavehausBold">
                        Make notes
                      </h4>

                      <form ref="form" onSubmit={this.addNote} noValidate>
                        <div
                          className={` ${this.errorClass(
                            this.state.formErrors.note
                          )}`}
                        >
                          <textarea
                            type="text"
                            rows="4"
                            id="note"
                            onChange={this.handleChange}
                            value={this.state.note}
                            name="note"
                            placeholder="Add your notes on this user.."
                            className="w-full p-3 mt-4 border rounded-lg border-gray text-blue"
                            autoComplete="off"
                          />
                        </div>

                        <div className="w-full text-right">
                          <div className="flex justify-between w-full mt-4 text-right">
                            <p className="px-4 text-green">
                              {this.state.success}
                            </p>
                            {!this.state.isLoading ? (
                              <button className="px-6 py-3 mb-1 mr-1 text-sm text-white transition-all duration-150 ease-linear rounded-full shadow outline-none cursor-pointer font-WavehausSemiBold bg-green get-started focus:outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-lg">
                                <svg
                                  className="inline"
                                  width="16"
                                  height="15"
                                  viewBox="0 0 16 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8 4V11M4.5 7.5H11.5M8 14.5C4.13401 14.5 1 11.366 1 7.5C1 3.63401 4.13401 0.5 8 0.5C11.866 0.5 15 3.63401 15 7.5C15 11.366 11.866 14.5 8 14.5Z"
                                    stroke="white"
                                  />
                                </svg>
                                <span className="ml-2">Submit details</span>
                              </button>
                            ) : (
                              <button className="px-8 py-3 text-sm text-white transition-all duration-150 ease-linear shadow outline-none rounded-3xl font-WavehausBold bg-green get-started focus:outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-lg">
                                Loading...
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                      <hr className="mt-6 border-gray" />
                      <ul className="mt-6">
                        {this.state.notes.map((note, index) => {
                          return (
                            <li className="mt-6">
                              <figure className="flex items-center">
                                <img
                                  className="z-10 w-12 h-12 overflow-hidden rounded-full realtive"
                                  src={this.state.currentUser.user.image}
                                  alt=""
                                />
                                <figcaption className="ml-4">
                                  <p className="text-ThemeBlue font-WavehausSemiBold">
                                    {" "}
                                    {note.addedBy}
                                  </p>
                                  <p className="text-blue">
                                    {Moment(note.created_at).fromNow()}
                                  </p>
                                </figcaption>
                              </figure>
                              <p className="mt-2 ml-16 text-ThemeBlue">
                                {note.note}
                              </p>
                              {note.is_status_updated ? (
                                <div className="py-4 mt-2 bg-lightGreen">
                                  <p className="relative pl-16 text-ThemeBlue list-circle">
                                    <strong className="font-WavehausBold text-ThemeBlue">
                                      {note.addedBy}
                                    </strong>{" "}
                                    changed the status to{" "}
                                    <span className="text-green">
                                      Contacted
                                    </span>
                                  </p>
                                </div>
                              ) : null}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </main>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ContactEdit;
