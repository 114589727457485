import React from "react";
import { statsInfo } from "./helpers";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Button from "../../components/Button";

const WhoAreWe = () => {
  return (
    <div>
      <div className="w-full bg-lightWhite">
        <div className="grid grid-cols-1  lg:grid-cols-2 ">
          <div className="  order-last lg:order-first  py-4 md:py-6 lg:py-12 lg:pt-20 px-6 md:px-10 lg:px-20 flex flex-col gap-2  justify-center">
            <p className="text-sm md:text-xl font-AuthorSemiBold uppercase  text-blueNavy">
              who are we
            </p>
            <p className=" font-AuthorSemiBold text-2xl md:text-4xl leading-tight text-blueNavy">
              Empowering Lives with Sustainability & Quality
            </p>
            <p className="text-base md:text-xl font-AuthorThin text-blueNavy">
              We at Virupaksha Organics Ltd. are committed to protecting what
              matters – People, Planet , Prosperity, Peace & Partnership.
            </p>

            <div className=" flex gap-6 flex-wrap flex-1">
              {React.Children.toArray(
                statsInfo.map((item) => (
                  <div className="flex flex-grow w-32 lg:w-56 items-center gap-2">
                    <div className="h-20 w-20 -ml-3">
                      <img
                        src={item.icon}
                        alt={item.icon}
                        className="h-full w-full object-cover "
                      />
                    </div>

                    <div>
                      <p className="sub-heading2 text-lightGrayText">
                        {item.title}
                      </p>
                      <p className="title1 text-lightBlue">
                        {item.description}
                      </p>
                    </div>
                  </div>
                ))
              )}
            </div>

            <div className="w-full  3xl:w-8/12  md:w-9/12 flex justify-center lg:justify-center md:justify-start md:mt-2">
              <div className="w-32">
                <Link to="/about-us">
                  <Button
                    variant="outlined"
                    class="text-lightBlue font-AuthorSemiBold"
                  >
                    Know More
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="who-we-are w-full  flex justify-end h-50vh md:h-50vh lg:h-auto"></div>
        </div>
      </div>
    </div>
  );
};

export default WhoAreWe;
