import React from "react";
import TextInput from "../components/Inputs/TextInput";
import TextArea from "../components/Inputs/TextArea";
import { useState } from "react";
import { contactFields, regularExpression } from "../Pages/Home/helpers";
import api from "../api/index";
import Validator from "validatorjs";
import Captcha from "./Captcha";
import Button from "./Button";

const ContactForm = () => {
  const [params, setParams] = useState(contactFields);
  const [errors, setErrors] = useState(contactFields);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [verified, setVerified] = useState(false);

  const handleChange = (e) => {
    setErrors(contactFields);
    setIsSubmitted(false);
    let { value, name } = e.target;
    if (name === "phone") {
      if (!regularExpression(name, value)) {
        return;
      }
    }
    setParams({ ...params, [name]: value });
  };

  const validate = (parameters, rule) => {
    const validator = new Validator(parameters, rule, {
      "min.phone": "Phone number should be 10 digits",
      "regex.name": "Name should contain only letters",
    });

    if (validator.fails()) {
      const fieldErrors = {};
      for (const key in validator.errors.errors) {
        fieldErrors[key] = validator.errors.errors[key][0];
      }

      setErrors(fieldErrors);

      return false;
    }
    setErrors({});
    return true;
  };

  const handleForm = () => {
    let rules = {
      name: ["required", "min:3", "max:35", "regex:/^[a-zA-z]*$/"],
      phone: ["required", "min:10", "max:13", "regex:/^\\+?[0-9]*$/"],

      email: "required|email",
      message: "required|max:500|min:10",
    };

    let response = validate(params, rules);

    if (!response) {
      return;
    }

    setIsLoading(true);

    api
      .post(`/contact-request`, params)
      .then((res) => {
        setIsLoading(false);
        setIsSubmitted(true);
        setParams(contactFields);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleCaptcha = (value) => {
    setVerified(value);
  };
  return (
    <div>
      <div className="flex flex-col gap-4 w-full">
        <TextInput
          label="Your Name*"
          value={params?.name}
          handleChange={handleChange}
          name="name"
          error={!!errors?.name}
          helperText={errors?.name}
        />
        <TextInput
          label="Email ID*"
          value={params?.email}
          handleChange={handleChange}
          name="email"
          error={!!errors?.email}
          helperText={errors?.email}
        />
        <TextInput
          label="Contact Number*"
          value={params?.phone}
          handleChange={handleChange}
          name="phone"
          error={!!errors?.phone}
          helperText={errors?.phone}
        />

        <TextArea
          label="Write a message"
          name="message"
          error={!!errors?.message}
          helperText={errors?.message}
          value={params?.message}
          handleChange={handleChange}
        />
        <div className="w-full flex justify-center items-center">
          <Captcha handleCaptcha={handleCaptcha} />
        </div>

        {isSubmitted ? (
          <p className="text-lightBlue text-center text-lg font-AuthorSemiBold">
            "Thank you for your message, we will reach out to you soon"
          </p>
        ) : null}
        <Button
          variant="contained"
          onClick={handleForm}
          loading={isLoading || verified === false}
        >
          {isLoading ? "Processing.." : "Submit"}{" "}
        </Button>
      </div>
    </div>
  );
};

export default ContactForm;
