
import React, { Component } from 'react';
import api from '../../api/index'
import AdminHeader from '../components/AdminHeader/AdminHeader'
import SideMenu from '../components/SideMenu'
import Moment from 'moment';
import { Line, Bar } from "react-chartjs-2";
import { ChartColors } from "./chart-data";
import { authenticationService } from '../../config/auth.service';
import { Helmet } from "react-helmet";
class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading:false,
			selectedFilter:'all',
			selectedGoogleFilter:'0',
			currentUser:authenticationService.currentUserValue,
			contact: [
				0
			],
			job: [
				0
			],
			statistics:'',
			googleUser: [
				0
			],
			googleRange: [
				0
			],
			activeUsers:0,
			sessions:{
				value:0
			},
			pageviews:{
				value:0
			}
		}
	}

	componentDidMount() {
		this.googleFilter('-7');
		this.filter(this.state.selectedFilter);
	
		this.getGoogleAnalytics();
		
	}

	onselect = (e) => {
		var newVal = e.target.value
		this.setState({selectedFilter:newVal})
		this.filter(newVal)
	}
	
	onGoogleselect = (e) => {
		var newVal = e.target.value
		this.setState({selectedGoogleFilter:newVal})
		this.googleFilter(newVal)
	}

	filter = (event)=>{
		var range;
		switch (event) {
		  case '0':
			range = [Moment(), Moment()]
			break;
		  case '-1':
			range = [Moment().subtract(1, 'days'), Moment().subtract(1, 'days')];
			break;
		  case '-7':
			range = [Moment().subtract(6, 'days'), Moment()];
			break;
		  case '-30':
			range = [Moment().subtract(29, 'days'), Moment()];
			break;
		  case 'TM':
			range = [Moment().startOf('month'), Moment().endOf('month')];
			break;
		  case 'LM':
			range = [Moment().subtract(1, 'month').startOf('month'), Moment().subtract(1, 'month').endOf('month')];
			break;
		  case 'all':
			  range = [Moment().subtract(1, 'month').startOf('month'), Moment().subtract(1, 'month').endOf('month')];
			  break;
		} 
	
		if(event === 'all'){
		  this.getDashboards(event);
		}else{
		  this.getDashboards(event,Moment(range[0]). format('YYYY-MM-DD'),Moment(range[1]). format('YYYY-MM-DD'));
		}
	   
	
	  }	
	googleFilter = (event)=>{
		var range;
		switch (event) {
		  
		  case '-7':
			range = [Moment().subtract(6, 'days'), Moment()];
			break;
		  case '-30':
			range = [Moment().subtract(29, 'days'), Moment()];
			break;
		  case 'TM':
			range = [Moment().startOf('month'), Moment().endOf('month')];
			break;
		  case 'LM':
			range = [Moment().subtract(1, 'month').startOf('month'), Moment().subtract(1, 'month').endOf('month')];
			break;
		} 
	
		if(event){
			this.getGoogleGraphData(Moment(range[0]).format('YYYY-MM-DD'),Moment(range[1]).format('YYYY-MM-DD'));
		}
	   
	
	  }

	getDashboards=(event,from,to)=>{
		if(event === 'all'){
			console.log(event)
			api.get(`/admin/statistics/graph`).then(res=>{
				this.setState({statistics:res.data.statistics,contact:res.data.items[0].data,job:res.data.items[1].data})
				console.log(this.state)
			}).catch(err => {
				console.log(err)
			})
		}else{
			api.get(`/admin/statistics/graph?`, {
				params: {
					startDate: from,
					endDate:to
				}}).then(res=>{
				this.setState({statistics:res.data.statistics,contact:res.data.items[0].data,job:res.data.items[1].data})
				console.log(this.state)
			}).catch(err => {
				console.log(err)
			})
		}
		
	}

	getGoogleAnalytics =() =>{
		api.get(`/admin/analytics`).then(res=>{
			this.setState({activeUsers:res.data.activeUsers['activeUsers'],pageviews:res.data.pageviews,sessions:res.data.sessions})
			
		}).catch(err => {
			console.log(err)
		})
	}
	getGoogleGraphData=(from,to)=>{
		this.setState({isLoading:true});
			api.get(`/admin/analytics/graph`, {
				params: {
					startDate: from,
					endDate:to
				}}).then(res=>{
					this.setState({googleUser:res.data.items,googleRange:res.data.range})
				console.log(res.data)
				this.setState({isLoading:false});
			}).catch(err => {
				console.log(err)
			})
	};





	render() {
		const data = {
			labels: [
			  "Jan",
			  "Feb",
			  "Mar",
			  "April",
			  "May",
			  "Jun",
			  "Jul",
			  "Aug",
			  "Sep",
			  "Oct",
			  "Nov",
			  "Dec",
			],
			datasets: [
			  
			  {
				label: "Contact form",
				fill: false,
				lineTension: 0.2,
				backgroundColor: ChartColors.contact,
				borderColor: ChartColors.contact,
				borderDash: [],
				borderDashOffset: 0.0,
				pointBorderColor: ChartColors.contact,
				pointBackgroundColor: "#fff",
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: "#fff",
				pointHoverBorderColor: ChartColors.contact,
				pointHoverBorderWidth: 2,
				pointRadius: 4,
				pointHitRadius: 10,
				data: this.state.contact
			  },
			  {
				label: "Job Applicants",
				fill: false,
				lineTension: 0.2,
				backgroundColor: ChartColors.job,
				borderColor: ChartColors.job,
				borderDash: [],
				borderDashOffset: 0.0,
				pointBorderColor: ChartColors.job,
				pointBackgroundColor: "#fff",
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: "#fff",
				pointHoverBorderColor: ChartColors.job,
				pointHoverBorderWidth: 2,
				pointRadius: 4,
				pointHitRadius: 10,
				data: this.state.job
			  },
			]
		  };
		  
		const lineOptions = {
			scales: {
				xAxes: [{
					type: "time",
					time: {
					  unit: "day",
					  tooltipFormat: "lll"
					},
					gridLines: {
					  display: false
					},
					ticks: {
					  maxTicksLimit: 31
					}
				  }],
				  yAxes: [{
					gridLines: {
						display: true,
						drawBorder: false
					  },
					  ticks: {
						display: true,   
						 maxTicksLimit: 3
					  }
				  }]
			  },
			legend: {
			  display: false
			},
			tooltips: {
			  enabled: true
			}
		  };

		  const googleData = {
			labels: this.state.googleRange,
			datasets: [
			  
			  {
				label: "Number of user",
				fill: false,
				lineTension: 0.2,
				backgroundColor: ChartColors.contact,
				borderColor: ChartColors.contact,
				borderDash: [],
				borderDashOffset: 0.0,
				pointBorderColor: ChartColors.contact,
				pointBackgroundColor: "#fff",
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: "#fff",
				pointHoverBorderColor: ChartColors.contact,
				pointHoverBorderWidth: 2,
				pointRadius: 4,
				pointHitRadius: 10,
				data: this.state.googleUser
			  },
			 
			]
		  };
		  
		const googleLineOptions = {
			scales: {
				xAxes: [{
					type: "time",
					time: {
					  unit: "day",
					  tooltipFormat: "lll"
					},
					gridLines: {
					  display: false
					},
					ticks: {
					  maxTicksLimit: 31
					}
				  }],
				  yAxes: [{
					gridLines: {
						display: true,
						drawBorder: false
					  },
					  ticks: {
						display: true,   
						 maxTicksLimit: 3
					  }
				  }]
			  },
			legend: {
			  display: false
			},
			tooltips: {
			  enabled: true
			}
		  };
		  
		return (
			<div className="flex bg-gray-100 bg-adminbg">
				 <Helmet>
					<title>Dashboard</title>
				</Helmet>
			<SideMenu></SideMenu>
			<div className="flex flex-col w-full h-screen overflow-y-hidden">
			<AdminHeader></AdminHeader>
			<div className="flex flex-col w-full overflow-x-hidden border-t border-ThemeLightBlue">
            <main className="flex-grow w-full p-6 ">
				<div>
					<div className="relative px-8 py-6 mb-8 rounded-md bg-ThemeBlue">
						<h1 className="text-xl text-white">Welcome, <strong className="text-white capitalize"> {this.state.currentUser.user.name} !</strong></h1>
						<p className="text-sm text-blue">Here are your recent Contact form submissions and careers form submissions</p>
						<div className="absolute top-0 left-0 h-full m-auto bg-right bg-no-repeat bg-contain rounded-md -right-4 w-12/12 bg-dasboardbg" >
					
						</div>
					</div>

					<div>
					<div className="flex justify-between">
					<h2 className="text-xl text-ThemeBlue font-WavehausBold">My Dashboard</h2>
					<select className="selected drop-select" onChange={(e) => this.onselect(e)}  value={this.state.selectedFilter}>
						<option value="all"> All Time </option>
						<option value="0"> Today </option>
						<option value="-1"> Yesterday </option>
						<option value="-7"> Last 7 Days </option>
						<option value="-30"> Last 30 Days </option>
						<option value="TM">This Month </option>
						<option value="LM">Last Month </option>
					</select>	
					</div>
					<hr className="mt-2 border-borderBlue"/>


					<div className="flex flex-wrap">
                    <div className="w-full pr-0 my-6 lg:w-1/3 lg:pr-2">
						<div className="px-6 bg-white border rounded-lg shadow-xl py-7 border-borderBlue">
							<div className="flex">
							<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="52.0303" height="52.0303" rx="9.5" fill="#4EE287" stroke="#54C780"/>
<path d="M24.0677 18.4001V17.6667C24.0677 16.0467 25.381 14.7334 27.001 14.7334C28.6211 14.7334 29.9344 16.0467 29.9344 17.6667V18.4001M16.7344 23.5334C22.1695 29.745 31.8326 29.745 37.2677 23.5334M18.201 19.1334H35.801C36.6111 19.1334 37.2677 19.79 37.2677 20.6001V33.8001C37.2677 34.6101 36.6111 35.2667 35.801 35.2667H18.201C17.391 35.2667 16.7344 34.6101 16.7344 33.8001V20.6001C16.7344 19.79 17.391 19.1334 18.201 19.1334Z" stroke="white" strokeWidth="1.5"/>
</svg>
<div className="pl-4">
<p className="text-2xl text-ThemeBlue font-WavehausBold">{this.state.statistics.applications} Applicants</p>
<p className="text-blue">Resume Submitted</p>
</div>

							</div>
						</div>
                    </div>

                    <div className="w-full pl-0 mt-6 lg:w-1/3 lg:pl-2">
					<div className="px-6 bg-white border rounded-lg shadow-xl py-7 border-borderBlue">
							<div className="flex">
							<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="52.0303" height="52.0303" rx="9.5" fill="#4EE287" stroke="#54C780"/>
<path d="M21.4211 25.7431H20.6711V27.2431H21.4211V25.7431ZM30.2632 27.2431H31.0132V25.7431H30.2632V27.2431ZM21.4219 30.2402L20.6719 30.2394L20.6702 31.7394L21.4202 31.7402L21.4219 30.2402ZM30.2623 31.75L31.0123 31.7508L31.014 30.2508L30.264 30.25L30.2623 31.75ZM21.4216 21.2461L20.6716 21.2456L20.6706 22.7456L21.4206 22.7461L21.4216 21.2461ZM27.3153 22.75L28.0653 22.7505L28.0663 21.2505L27.3163 21.25L27.3153 22.75ZM30.2632 16L30.7982 15.4744L30.5777 15.25H30.2632V16ZM34.6842 20.5H35.4342V20.1932L35.2192 19.9744L34.6842 20.5ZM21.4211 27.2431H30.2632V25.7431H21.4211V27.2431ZM21.4202 31.7402L30.2623 31.75L30.264 30.25L21.4219 30.2402L21.4202 31.7402ZM21.4206 22.7461L27.3153 22.75L27.3163 21.25L21.4216 21.2461L21.4206 22.7461ZM33.2105 36.25H18.4737V37.75H33.2105V36.25ZM17.75 35.5V17.5H16.25V35.5H17.75ZM18.4737 16.75H30.2632V15.25H18.4737V16.75ZM33.9342 20.5V35.5H35.4342V20.5H33.9342ZM29.7282 16.5256L34.1492 21.0256L35.2192 19.9744L30.7982 15.4744L29.7282 16.5256ZM18.4737 36.25C18.0865 36.25 17.75 35.9268 17.75 35.5H16.25C16.25 36.7301 17.2331 37.75 18.4737 37.75V36.25ZM33.2105 37.75C34.4511 37.75 35.4342 36.7301 35.4342 35.5H33.9342C33.9342 35.9268 33.5977 36.25 33.2105 36.25V37.75ZM17.75 17.5C17.75 17.0732 18.0865 16.75 18.4737 16.75V15.25C17.2331 15.25 16.25 16.2699 16.25 17.5H17.75Z" fill="white"/>
</svg>

<div className="pl-4">
<p className="text-2xl text-ThemeBlue font-WavehausBold">{this.state.statistics.contacts} Contact</p>
<p className="text-blue"> Submitted</p>
</div>

							</div>
						</div>
                    </div>
                	</div>
				
					<Line data={data} options={lineOptions}/>
					</div>

					<div className='relative'>
					{
						!this.state.isLoading ? ' ': <button className="absolute left-0 right-0 w-full px-8 py-3 text-base text-white transition-all duration-150 ease-linear rounded-md shadow outline-none top-52 font-WavehausBold bg-green get-started focus:outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-lg">Loading...</button>
					}
					<div className="flex justify-between mt-12">
					<h2 className="text-xl text-ThemeBlue font-WavehausBold">Analytics Dashboard</h2>
					<select className="selected drop-select" onChange={(e) => this.onGoogleselect(e)}  value={this.state.selectedGoogleFilter}>
			
						<option value="-7"> Last 7 Days </option>
						<option value="-30"> Last 30 Days </option>
						<option value="TM">This Month </option>
						<option value="LM">Last Month </option>
					</select>	
					</div>
					<hr className="mt-2 border-borderBlue"/>
					<div className="flex flex-wrap">
                    <div className="w-full pr-0 my-6 lg:w-1/3 lg:pr-2">
						<div className="px-6 bg-white border rounded-lg shadow-xl py-7 border-borderBlue">
							<div className="flex">
							<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="52.0303" height="52.0303" rx="9.5" fill="#4EE287" stroke="#54C780"/>
<g clipPath="url(#clip0_1148_101)">
<path d="M12.9993 25.7057C13.0664 25.1997 13.1182 24.6938 13.2017 24.1979C13.5948 21.838 14.6221 19.6292 16.1736 17.8078C16.2356 17.7344 16.3001 17.6598 16.3887 17.5485H13.5421V15.4387H20.1501V22.0502H18.0676V18.9462L18.0132 18.9146C17.8171 19.1524 17.6109 19.38 17.4261 19.6267C16.3451 21.0418 15.6173 22.6944 15.3032 24.4471C14.7149 27.8079 15.4677 30.8626 17.5691 33.5352C19.4745 35.96 21.9972 37.3716 25.0703 37.789C25.1211 37.8031 25.1733 37.8111 25.2259 37.813C25.818 37.7409 26.2001 38.0774 26.5771 38.4657C27.0414 38.9426 27.5374 39.3891 28.0688 39.8963C27.7461 39.9266 27.483 39.9583 27.2186 39.9747C21.0812 40.3542 15.2931 36.2863 13.5661 30.3819C13.2887 29.4564 13.1135 28.5034 13.0436 27.5397C13.0335 27.4546 13.0183 27.3701 12.998 27.2868L12.9993 25.7057Z" fill="#1B3F67"/>
<path d="M27.2926 13C27.805 13.0683 28.3199 13.1202 28.8285 13.2074C31.5972 13.6691 34.146 15.0031 36.1032 17.0147C38.0007 18.9089 39.2773 21.3351 39.7634 23.9715C39.9594 24.9896 40.0341 26.0272 39.986 27.0629C39.986 27.1324 39.9734 27.2007 39.9645 27.2981C39.9202 27.2817 39.8777 27.2605 39.838 27.2349C39.2333 26.6859 38.6222 26.1433 38.0313 25.5792C37.9049 25.4511 37.8221 25.2865 37.7948 25.1086C37.3772 21.9566 35.897 19.4066 33.4034 17.4599C31.8321 16.2246 29.9489 15.4492 27.9631 15.2199C27.3128 15.1414 26.6537 15.1199 25.9629 15.0693V13H27.2926Z" fill="#1B3F67"/>
<path d="M38.4246 28.797L39.9934 30.2074L31.217 39.9772L25.5098 34.5459L26.9634 33.0192L31.098 36.9517L38.4246 28.797Z" fill="#1B3F67"/>
<path d="M25.9638 17.5472H28.069V28.0596H20.082V25.9751H25.9638V17.5472Z" fill="#1B3F67"/>
</g>
<defs>
<clipPath id="clip0_1148_101">
<rect width="27" height="27" fill="white" transform="translate(13 13)"/>
</clipPath>
</defs>
</svg>

<div className="pl-4">
<p className="text-2xl text-ThemeBlue font-WavehausBold">{this.state.activeUsers} Active Users</p>
<p className="text-blue">Active Users</p>
</div>

							</div>
						</div>
                    </div>

                    <div className="w-full pl-0 mt-6 lg:w-1/3 lg:pl-2">
					<div className="px-6 bg-white border rounded-lg shadow-xl py-7 border-borderBlue">
							<div className="flex">
							<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="52.0303" height="52.0303" rx="9.5" fill="#4EE287" stroke="#54C780"/>
<path d="M41.9227 13C42.2841 13.2164 42.4203 13.5257 42.4076 13.9533C42.3809 14.7959 42.4076 15.6398 42.3987 16.4824C42.3987 17.157 42.1594 17.3886 41.4746 17.3912H39.4572V33.2517C39.4572 34.1426 39.2905 34.3056 38.4034 34.3056H26.8602C26.8602 34.7129 26.8412 35.0883 26.873 35.46C26.8806 35.558 27.0346 35.6675 27.1441 35.7298C28.0211 36.2313 28.4398 37.1834 28.1827 38.1189C28.0564 38.5657 27.7854 38.958 27.4123 39.2344C27.0392 39.5108 26.585 39.6556 26.1207 39.6463C25.6568 39.6278 25.2121 39.4562 24.8561 39.1582C24.5 38.8602 24.2528 38.4527 24.153 37.9992C23.9672 37.2088 24.3146 36.2759 25.0618 35.8622C25.4729 35.6344 25.5709 35.3722 25.5263 34.9598C25.5152 34.7414 25.5152 34.5226 25.5263 34.3043H13.954C13.133 34.3043 12.9497 34.121 12.9497 33.2962V17.3912H10.9323C10.2475 17.3912 10.0121 17.157 10.0083 16.4824C10.0083 15.6233 10.0248 14.7641 10.0006 13.905C9.98917 13.49 10.1355 13.2011 10.4843 13H41.9227ZM38.1042 17.4154H14.3422V32.9118H38.0992L38.1042 17.4154ZM41.047 14.3695H11.3956V16.0242H41.0419L41.047 14.3695ZM26.9213 37.5665C26.9203 37.3751 26.8438 37.1918 26.7085 37.0564C26.5731 36.921 26.3898 36.8445 26.1984 36.8435C26.0072 36.8462 25.8247 36.9241 25.6906 37.0604C25.5565 37.1967 25.4814 37.3804 25.4818 37.5716C25.4903 37.7549 25.5664 37.9285 25.6955 38.059C25.8245 38.1895 25.9974 38.2675 26.1806 38.278C26.372 38.2778 26.5559 38.2037 26.694 38.0711C26.832 37.9385 26.9135 37.7577 26.9213 37.5665Z" fill="#1B3F67"/>
<path d="M34.2531 29.1227H32.6875V21.2466H34.2531V29.1227Z" fill="#1B3F67"/>
<path d="M25.375 29.1087V23.1545H26.9609V29.1087H25.375Z" fill="#1B3F67"/>
<path d="M18.1426 25.051H19.726V29.0883H18.1426V25.051Z" fill="#1B3F67"/>
</svg>


<div className="pl-4">
<p className="text-2xl text-ThemeBlue font-WavehausBold">{this.state.sessions['value']} Sessions</p>
<p className="text-blue">  {this.state.sessions['start']} </p>
</div>

							</div>
						</div>
                    </div>
					<div className="w-full pl-0 mt-6 lg:w-1/3 lg:pl-2">
					<div className="px-6 bg-white border rounded-lg shadow-xl py-7 border-borderBlue">
							<div className="flex">
							<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="52.0303" height="52.0303" rx="9.5" fill="#4EE287" stroke="#54C780"/>
<path d="M26.4761 34.1284C22.0822 34.0816 19.0756 32.1626 17.0441 28.6486C16.6627 27.9317 16.326 27.1919 16.0361 26.4335C15.9836 26.2787 15.9885 26.1103 16.0499 25.9589C17.9031 21.4351 22.6572 18.5117 27.517 19.0676C31.8952 19.5612 34.9884 21.9235 36.8208 25.9277C36.8907 26.0997 36.8999 26.2903 36.8468 26.4681C35.4336 30.642 32.6244 33.2226 28.2219 33.9517C27.562 34.0625 26.8831 34.0885 26.4761 34.1284ZM26.5505 32.0501C30.2291 31.8405 32.9898 30.1709 34.6906 26.8387C34.7411 26.7542 34.7677 26.6575 34.7677 26.559C34.7677 26.4605 34.7411 26.3639 34.6906 26.2793C32.9708 22.9662 30.2499 21.1303 26.5194 21.1286C22.7888 21.1269 20.068 22.9592 18.3482 26.262C18.2951 26.351 18.2671 26.4528 18.2671 26.5564C18.2671 26.6601 18.2951 26.7618 18.3482 26.8508C20.068 30.1796 22.8235 31.8457 26.5557 32.0483L26.5505 32.0501Z" fill="#1B3F67"/>
<path d="M26.5658 22.4864C28.8173 22.5245 30.6427 24.3915 30.5925 26.6119C30.5734 27.6861 30.1323 28.7096 29.3645 29.4612C28.5967 30.2128 27.564 30.632 26.4896 30.6282C25.4099 30.6149 24.3792 30.1755 23.6221 29.4057C22.8649 28.6359 22.4427 27.5981 22.4473 26.5183C22.4628 25.4388 22.9045 24.4092 23.6759 23.6539C24.4474 22.8987 25.4862 22.479 26.5658 22.4864ZM26.5138 24.5872C25.9902 24.5904 25.4892 24.8012 25.1207 25.1733C24.7522 25.5453 24.5463 26.0484 24.5481 26.572C24.554 27.09 24.7628 27.585 25.1298 27.9506C25.4967 28.3162 25.9924 28.5233 26.5104 28.5274C27.0341 28.5278 27.5366 28.3209 27.9082 27.9519C28.2798 27.5829 28.4902 27.0818 28.4934 26.5582C28.4907 26.0348 28.281 25.5338 27.9101 25.1645C27.5392 24.7952 27.0372 24.5877 26.5138 24.5872Z" fill="#1B3F67"/>
</svg>


<div className="pl-4">
<p className="text-2xl text-ThemeBlue font-WavehausBold">{this.state.pageviews['value']} Page Views</p>
<p className="text-blue"> {this.state.pageviews['start']} </p>
</div>

							</div>
						</div>
                    </div>
                	</div>
						{
							this.state.googleUser?.length > 1 ? <Bar data={googleData} options={googleLineOptions}/> : ''
						}
					</div>
				
				</div>

				</main>
			</div>
			</div>
		
			</div>
		);
	}
}

export default Dashboard;
