import React, { useEffect, useMemo, useState } from "react";
import api from "../../api/index";
import Banner from "./Banner";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import NoDataFound from "../../components/NoDataFound";
import HowWeAreRaising from "./HowWeAreRaising";
import EnquiryCommon from "./EnquiryCommon";
import Button from "../../components/Button";
import updateMeta from "../../components/SeoMeta";
const IntermediateProducts = () => {
  const [productData, setProductData] = useState([]);
  const [filterdData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [productId, setProductId] = useState();

  const handleEnquiryForm = (id) => {
    setProductId(id);
  };

  const fetchData = () => {
    setIsLoading(true);
    api
      .get(`/products`, {
        params: {
          category_slug: "intermediates",
        },
      })
      .then((res) => {
        let response = res?.data?.data;

        let newData = response.map((item, index) => ({
          ...item,
          sno: index + 1,
        }));
        setProductData(newData);
        setFilteredData(newData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    setSearchKey(e.target.value);
  };

  const handleFilter = () => {
    var data = productData.filter((item) => {
      return (
        JSON.stringify(item).toLowerCase().indexOf(searchKey.toLowerCase()) >= 0
      );
    });
    setFilteredData(data);
  };

  useMemo(() => {
    handleFilter();
  }, [searchKey]);

  useEffect(() => {
    document.title = 'Intermediates | Virupaksha Organics Ltd';
    updateMeta("description", "Discover our extensive portfolio of intermediates at Virupaksha Organics Ltd, essential for the production of high-quality pharmaceuticals and fine chemicals");
    updateMeta("keywords", "Virupaksha Organics Ltd, intermediates, chemical intermediates, pharmaceutical intermediates, chemical production, pharmaceutical manufacturing");
    fetchData();
  }, []);
  return (
    <div className="">
      <Banner
        title="Intermediates"
        handleChange={handleChange}
        searchKey={searchKey}
        styles="bg-intermediate bg-cover"
        searchBox={true}
        content="*None of the below products will be supplied to the countries in
        which these could conflict with the existing patents. The final
        responsibility lies exclusively with the buyer."
      />

      <div className="h-5vh lg:h-10vh"></div>

      {isLoading ? (
        <div className="mt-6">
          <Loader />
        </div>
      ) : (
        <>
          {filterdData && filterdData.length ? (
            <div className="px-2 md:px-4 lg:px-12">
              {filterdData.map((item, index) => (
                <div key={index}>
                  <p className=" font-AuthorBold text-2xl md:text-3xl  mt-4 md:mt-0 text-lightBlue text-center pt-3 md:pt-6 md:pb-2">
                    {item?.title}
                  </p>

                  <div className="border rounded-lg border-border">
                    {item?.list?.map((innerItem, innerIndex) => (
                      <div className="" key={innerIndex}>
                        <div className="rounded-lg  py-2 md:py-4  w-full bg-white px-2 md:px-8 lg:px-12">
                          <p className="text-xl md:text-2xl pb-2 md:pb-4 font-AuthorSemiBold  text-lightBlue text-center">
                            {innerIndex + 1}. {innerItem?.title}
                          </p>

                          <div className="text-white grid grid-cols-1 md:grid-cols-2 h-16  items-center bg-tempBlue rounded-xl pl-4 lg:pl-12 font-AuthorSemiBold text-lg md:text-2xl">
                            <div className="w-full flex justify-center md:justify-start items-center">
                              <p className="">Product Information</p>
                            </div>
                            <div className="hidden md:block">
                              <p className="hidden lg:block">CAS No.</p>
                            </div>
                          </div>

                          {innerItem?.subList?.map((data, index, array) => (
                            <div
                              key={index}
                              className={`grid grid-cols-2 gap-2 items-center pl-1 lg:pl-12 py-4 ${
                                index !== array.length - 1
                                  ? "border-b border-border"
                                  : ""
                              }`}
                            >
                              <div>
                                <p className="text-lightBlue text-sm lg:text-lg mt-2  font-AuthorThin font-semibold break-all leading-tight">
                                  {data?.name}
                                </p>
                              </div>

                              <div className="flex gap-2">
                                <div className="w-full">
                                  <p className="text-lightBlue text-sm lg:text-lg  font-AuthorThin font-semibold hidden lg:block break-all">
                                    {data?.cas_no}
                                  </p>
                                </div>

                                <div className="w-full flex items-center gap-4 justify-end">
                                  <Link
                                    to={{
                                      pathname: `/products/view/${data?.slug}`,
                                      state: {
                                        link: "/products/intermediates",
                                      },
                                    }}
                                  >
                                    <p className="block md:hidden underline  font-AuthorThin font-semibold text-sm lg:text-lg text-lightBlue">
                                      View
                                    </p>
                                  </Link>

                                  <div className="hidden md:block flex-none w-28 lg:w-40">
                                    {" "}
                                    <Link
                                      to={{
                                        pathname: `/products/view/${data?.slug}`,
                                        state: {
                                          link: "/products/intermediates",
                                        },
                                      }}
                                    >
                                      <Button variant="outlined" className="">
                                        <p className="text-xs md:text-sm font-AuthorThin">
                                          {" "}
                                          View
                                        </p>
                                      </Button>
                                    </Link>
                                  </div>
                                  <div
                                    className="w-28 lg:w-40"
                                    onClick={() => {
                                      handleEnquiryForm(data?.id);
                                    }}
                                  >
                                    <EnquiryCommon product_id={productId} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="mt-6">
              <NoDataFound />
            </div>
          )}
        </>
      )}

      <HowWeAreRaising />

      <div className=" h-10vh"></div>
    </div>
  );
};

export default IntermediateProducts;
