import React, { useEffect, useState } from "react";
import api from "../../api";
import Loader from "../../components/Loader";
import HowWeAreRaising from "../Product/HowWeAreRaising";
import updateMeta from "../../components/SeoMeta";

const SustainabilityListing = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchData = () => {
    setLoading(true);
    api
      .get("/sustainability-reports")
      .then((res) => {
        setData(res?.data?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    document.title = 'Sustainability & ESG | Virupaksha Organics Ltd';
    updateMeta("description", "Learn about Virupaksha Organics Ltd's commitment to sustainability and ESG practices, ensuring a responsible and ethical approach to our pharmaceutical and chemical production");
    updateMeta("keywords", " Virupaksha Organics Ltd, sustainability, ESG practices, environmental responsibility, ethical production, pharmaceutical sustainability, chemical industry ESG");
    fetchData();
  }, []);
  return (
    <div>
      <div className="py-6 mt-32 lg:mt-28 md:mt-32  px-4 md:px-10 lg:px-24">
        <p className="md:pb-4 text-left  lg:text-center text-lightBlue font-medium font-AuthorSemiBold  md:text-3xl text-2xl">
          Reports and Policies
        </p>

        <hr className="text-lightBlue border border-indigo-900" />

        {loading ? (
          <Loader />
        ) : (
          <div className="w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-10 py-6">
            {data?.map((item, index) => (
              <a
                key={index}
                className=" font-AuthorSemiBold text-lightBlue text-lg md:text-xl"
                href={`${process.env.REACT_APP_BASE_URL || 'https://api-virupaksha.scube.me/'}${item?.path}`}
                target="_blank"
              >
                {item?.name}
              </a>
            ))}
          </div>
        )}
      </div>

      <div className="pb-10">
        <HowWeAreRaising />
      </div>
    </div>
  );
};

export default SustainabilityListing;
