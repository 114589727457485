import * as React from "react";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { DialogActions } from "@mui/material";
import Button from "../../components/Button";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paper": {
      width: "100%",
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(0),
    },
  },

  "& .MuiDialog-paper": {
    padding: theme.spacing(0),
  },
}));

export default function SuccessModal({ open, handleSuccessClose }) {
  return (
    <div>
      <BootstrapDialog
        sx={{ p: 0 }}
        onClose={handleSuccessClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, py: 1, px: 2 }} id="customized-dialog-title">
          <p
            className={`font-AuthorBold text-transparent text-2xl md:text-3xl`}
          >
            Enquiry Form
          </p>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleSuccessClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="w-full md:w-104 flex flex-col gap-6 px-2 lg:px-6 py-3">
            <p className="text-lightBlue text-center text-base lg:text-xl  font-AuthorSemiBold">
              Thank you for submitting the application!
            </p>
          </div>
        </DialogContent>

        <DialogActions>
          <div className="w-full px-2">
            <Button variant="contained" onClick={handleSuccessClose}>
              Close
            </Button>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
