import React from "react";

const SearchBar = ({ name, value, placeholder, handleChange, onSubmit }) => {
  return (
    <div className="relative w-full flex justify-between items-center px-4 shadow-xl  h-full rounded-lg bg-white">
      <div className="w-2/12 md:w-1/12 lg:w-1/12">
        <img src="/searchIcon.svg" alt="searchIcon" />
      </div>
      <div className="w-11/12">
        <input
          onChange={handleChange}
          type="text"
          placeholder={placeholder}
          className="w-full text-ThemeBlue"
          value={value}
        />
      </div>
    </div>
  );
};

export default SearchBar;
