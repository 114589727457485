import axios from "axios";
import { authHeader } from "../config/auth-header";

const api = axios.create({
  baseURL:  'https://api.virupaksha.com/api',
  // baseURL: "http://localhost:5011/api",
});

api.interceptors.request.use(function (config) {
  const token = authHeader();
  config.headers["x-auth-token"] = token ? authHeader() : "";
  return config;
});

api.interceptors.response.use(
  function (successRes) {
    return successRes;
  },
  function (error) {
    return error.response;
  }
);

export default api;
