import * as React from "react";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextInput from "./Inputs/TextInput";
import { useState } from "react";
import Button from "./Button";
import TextArea from "./Inputs/TextArea";
import { DialogActions } from "@mui/material";
import Validator from "validatorjs";
import { regularExpression } from "../Pages/Home/helpers";
import api from "../api";

import Captcha from "./Captcha";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paper": {
      width: "100%",
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(0),
    },
  },

  "& .MuiDialog-paper": {
    padding: theme.spacing(0),
  },
}));

export default function EnquiryModal({ open, handleClose, product_id }) {
  let fields = {
    name: "",
    email: "",
    phone: "",
    qty: "",
    description: "",
    product_id: "",
    company: "",
  };
  const [params, setParams] = useState(fields);
  const [errors, setErrors] = useState(fields);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verified, setVerified] = useState(false);

  const validate = (parameters, rule) => {
    const validator = new Validator(parameters, rule, {
      "min.phone": "Phone number should be 10 digits",
      "required.qty": "Quantity field is required.",
      "regex.name": "Name should contain only letters",
      "regex.company": "Name should contain only letters",
    });

    if (validator.fails()) {
      const fieldErrors = {};
      for (const key in validator.errors.errors) {
        fieldErrors[key] = validator.errors.errors[key][0];
      }

      setErrors(fieldErrors);

      return false;
    }
    setErrors({});
    return true;
  };

  const handleChange = (e) => {
    setErrors(fields);
    setIsSubmitted(false);
    let { value, name } = e.target;

    if (name === "phone" || name === "qty") {
      if (!regularExpression(name, value)) {
        return false;
      }
    }

    setParams({ ...params, [name]: value });
  };

  const handleSubmit = () => {
    let rules = {
      name: ["required", "min:3", "max:35", "regex:/^[a-zA-z]*$/"],
      phone: "required|min:10|max:13",
      email: "required|email",
      company: ["required", "min:3", "max:35", "regex:/^[a-zA-z]*$/"],
      qty: "required",
      description:"required|max:500"
    };

    let response = validate(params, rules);

    if (!response) {
      return;
    }
    if (params?.phone?.includes("+") && params?.phone?.length !== 13) {
      setErrors({ ...errors, phone: "Enter a valid phone number" });
      return;
    }

    setIsLoading(true);

    let postData = { ...params, product_id: product_id };

    api
      .post("/contact-request", postData)
      .then((res) => {
        setIsLoading(false);
        setIsSubmitted(true);
        setParams(fields);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleCaptcha = (value) => {
    setVerified(value);
  };

  const closeModal = () => {
    handleClose();
    setErrors("");
    setParams(fields);
    setVerified(false);
    setIsSubmitted(false);
  };

  return (
    <div>
      <BootstrapDialog
        sx={{ p: 0 }}
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, py: 1, px: 2 }} id="customized-dialog-title">
          <p
            className={`font-AuthorBold  ${
              isSubmitted ? "text-transparent" : "text-lightBlue"
            } text-2xl md:text-3xl`}
          >
            Enquiry Form
          </p>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="w-full md:w-104 flex flex-col gap-6 px-2 lg:px-6 py-3">
            {isSubmitted ? (
              <p className="text-lightBlue text-center text-base lg:text-xl  font-AuthorSemiBold">
                Thank you for placing an enquiry with us, we will connect with
                you at the earliest!
              </p>
            ) : (
              <div className="flex flex-col gap-6">
                <TextInput
                  label="Your Name*"
                  value={params?.name}
                  handleChange={handleChange}
                  name="name"
                  error={errors?.name}
                  helperText={errors?.name}
                />
                <TextInput
                  label="Email ID*"
                  value={params?.email}
                  handleChange={handleChange}
                  name="email"
                  error={errors?.email}
                  helperText={errors?.email}
                />
                <TextInput
                  label="Contact Number*"
                  value={params?.phone}
                  handleChange={handleChange}
                  name="phone"
                  error={errors?.phone}
                  helperText={errors?.phone}
                />
                <TextInput
                  label="Company Name*"
                  value={params?.company}
                  handleChange={handleChange}
                  name="company"
                  error={errors?.company}
                  helperText={errors?.company}
                />
                <TextInput
                  label="Quantity*"
                  value={params?.qty}
                  handleChange={handleChange}
                  name="qty"
                  error={errors?.qty}
                  helperText={errors?.qty}
                />

                <TextArea
                  label="Write a message"
                  name="description"
                  error={errors?.description}
                  helperText={errors?.description}
                  value={params?.description}
                  handleChange={handleChange}
                />

                <div className="w-full px-6 flex justify-center items-center">
                  <Captcha handleCaptcha={handleCaptcha} />
                </div>
              </div>
            )}
          </div>
        </DialogContent>

        <DialogActions>
          {isSubmitted ? (
            <div className="w-full px-2">
              <Button variant="contained" onClick={handleClose}>
                Close
              </Button>
            </div>
          ) : (
            <div className="w-full px-2">
              <Button
                variant="contained"
                onClick={handleSubmit}
                loading={isLoading || verified === false}
              >
                {isLoading ? "Processing..." : "Submit"}
              </Button>
            </div>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
