import React, { Component } from "react";
import api from "../../../api/index";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import SideMenu from "../../components/SideMenu";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";

class AddressList extends Component {
  state = {
    address: "",
    loading: false,
  };

  componentDidMount() {
    this.getAddress(1);
  }

  getAddress = () => {
    this.setState({ loading: true });
    api
      .get(`/admin/business`)
      .then((res) => {
        console.log(res.data);
        this.setState({ address: res.data.data, loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <div className="flex bg-gray-100 admin bg-adminbg">
        <SideMenu></SideMenu>
        <div className="flex flex-col w-full h-screen overflow-y-hidden">
          <AdminHeader></AdminHeader>
          <div className="flex flex-col w-full overflow-x-hidden border-t border-ThemeLightBlue">
            {this.state.loading ? (
              <Loader />
            ) : (
              <main className="flex-grow w-full p-6 ">
                <div>
                  <div className="flex justify-between">
                    <h2 className="text-xl text-ThemeBlue font-WavehausBold">
                      Contact Information{" "}
                    </h2>
                  </div>

                  <hr className="mt-2 border-borderColor" />
                  <div className="w-full pr-0 my-6 lg:pr-2">
                    <div className="px-6 bg-white border rounded-lg shadow-xl py-7 border-borderBlue">
                      <table className="table ">
                        <thead className="" style={{ color: "#0d4a61" }}>
                          <tr>
                            <th scope="col">Contact Number</th>
                            <th scope="col">Email ID</th>
                            <th scope="col">Address</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" className="odd">
                            <td>{this.state.address.phone}</td>
                            <td>{this.state.address.email}</td>

                            <td>{this.state.address.address}</td>

                            <td className="flex">
                              <Link
                                to={
                                  "/admin/address-list/" + this.state.address.id
                                }
                                className="inline-block mr-2 btn rounded-xl bg-blue btn-info btn-sm"
                              >
                                <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="29"
                                    height="29"
                                    rx="4.5"
                                    fill="#376ABF"
                                    stroke="#2C5CAE"
                                  />
                                  <path
                                    d="M8 16L7.64645 15.6464L7.5 15.7929V16H8ZM17 7L17.3536 6.64645C17.1583 6.45118 16.8417 6.45118 16.6464 6.64645L17 7ZM22 12L22.3536 12.3536C22.5488 12.1583 22.5488 11.8417 22.3536 11.6464L22 12ZM13 21V21.5H13.2071L13.3536 21.3536L13 21ZM8 21H7.5C7.5 21.2761 7.72386 21.5 8 21.5V21ZM8.35355 16.3536L17.3536 7.35355L16.6464 6.64645L7.64645 15.6464L8.35355 16.3536ZM16.6464 7.35355L21.6464 12.3536L22.3536 11.6464L17.3536 6.64645L16.6464 7.35355ZM21.6464 11.6464L12.6464 20.6464L13.3536 21.3536L22.3536 12.3536L21.6464 11.6464ZM13 20.5H8V21.5H13V20.5ZM8.5 21V16H7.5V21H8.5ZM13.6464 10.3536L18.6464 15.3536L19.3536 14.6464L14.3536 9.64645L13.6464 10.3536ZM15.5 21.5H22.5V20.5H15.5V21.5Z"
                                    fill="white"
                                  />
                                </svg>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </main>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AddressList;
