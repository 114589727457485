import React, {useEffect} from "react";
import HowWeAreRaising from "../Product/HowWeAreRaising";
import updateMeta from "../../components/SeoMeta";
let data = [
  "Organic chemistry processes including coupling reactions, condensations, oxidations, reductions, etc.",
  "Chiral separations using resolution process and diastereomeric crystallizations.",
  "Asymmetric synthesis.",
  "Grignard reactions in large scale.",
  "Hydrogenation and asymmetric hydrogenations.",
  "Cryogenic reactions.",
  "Palladium catalyzed reactions.",
  "Carbohydrate chemistry.",
  "Hydrocyanation",
  "Fluorination",
  "Phosgenation",
  "Thiophosegenation",
  "N Butyllithium",
];
let mapData = [
  {
    unit: "Unit 1",
    image: "/home/unit-1.webp",
    address:
      "Survey No.10, Gaddapotharam Village, Jinnaram Mandal, Sangareddy District - 502319, Telangana, India.",
    city: "Hyderabad, Telangana",
    year: "2003",
    area: "Ac.6.15",
    capacity: "400KL",
    accrediations: "FDA,PMDA,KFDA",
    certification:
      "Certified with: ISO 9001, ISO 14001, ISO 45001, ISO 50001,ISO 27001 ",
  },
  {
    unit: "Unit 2",
    image: "/home/Unit-2 _1.webp",
    address:
      "Plot No. 30 To 33, Phase-1, IDA, Pashamylaram, Patancheru Mandal, Sangareddy District - 502307, Telangana, India.",
    city: "Hyderabad, Telangana",
    year: "2010",
    area: "Ac.5.00",
    capacity: "300KL",
    accrediations: "GMP",
    certification:
      "Certified with:  ISO 9001, ISO 14001, ISO 50001, ISO  27001,  ISO 45001",
  },
];
const Facilities = () => {
  useEffect(() => {
    document.title = 'Facilities | Virupaksha Organics Ltd';
    updateMeta("description", "Discover the state-of-the-art facilities of Virupaksha Organics Ltd, equipped to produce high-quality pharmaceuticals and fine chemicals");
    updateMeta("keywords", "Virupaksha Organics Ltd, company facilities, pharmaceutical production, chemical manufacturing, advanced facilities, production capabilities");
  }, []);
  return (
    <div>
      <div>
        <div
          className={`relative h-70vh lg:h-60vh md:h-50vh bg-facilities bg-cover`}
        >
          <div className="h-30vh md:h-25vh lg:h-30vh"></div>
          <div className="absolute  w-full">
            <h1 className="w-11/12 lg:w-5/12 m-auto text-white text-center font-AuthorBold text-2xl md:text-4xl">
              Pioneering <span className="text-green">Innovation</span> and
              <span className="text-green"> Excellence</span> in Drug
              Manufacturing
            </h1>
          </div>
        </div>

        <div className="px-4 md:px-10 lg:px-24 py-6">
          <p className="text-green uppercase font-AuthorSemiBold text-xl md:text-2xl">
            Facilities
          </p>

          <h1 className=" m-auto text-lightBlue font-AuthorBold text-2xl md:text-4xl">
            Pioneering Innovation and Excellence in Drug Manufacturing
          </h1>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 py-6">
            {data?.map((item, index) => (
              <div
                key={index}
                className="flex w-full lg:w-4/5 gap-4 ml-1 lg:ml-0"
              >
                <div className="mt-2 w-2 h-2 lg:w-3 lg:h-3 flex-none flex-shrink-0 bg-ThemeBlue rounded-full"></div>
                <div className="text-lightBlue font-AuthorThin text-lg md:text-xl">
                  {item}
                </div>
              </div>
            ))}
          </div>

          <div className="py-6 product_bg mt-6 rounded-xl">
            <p className="text-center font-AuthorSemiBold text-green text-2xl">
              Manufacturing Units
            </p>

            <h1 className="w-9/12 lg:w-full m-auto text-center text-white font-AuthorBold text-2xl md:text-3xl">
              Unleashing the Power of Expertise
            </h1>

            <div className="pt-6 px-4 lg:px-24 grid grid-cols-1 gap-12">
              {mapData?.map((item, index) => (
                <div
                  key={index}
                  className="marquee_card grid grid-cols-1 md:grid-cols-2 md:gap-8 lg:gap-24     p-6"
                >
                  <div className="">
                    {" "}
                    <img
                      src={item?.image}
                      alt="unit"
                      className="rounded-xl h-full w-full"
                    />
                  </div>

                  <div className="flex flex-col gap-2 mt-3">
                    <p className=" font-AuthorSemiBold text-white text-xl lg:text-2xl">
                      {item?.unit}: {item?.city}
                    </p>
                    <p className="  font-AuthorThin  opacity-90 text-base text-white lg:text-xl">
                      {item?.address}
                    </p>
                    <p className="font-font-AuthorThin  text-white text-base lg:text-xl">
                      Year of Commencement: {item?.year}
                    </p>
                    <p className="font-font-AuthorThin  text-white text-base lg:text-xl">
                      Area: {item?.area}
                    </p>

                    <p className="font-font-AuthorThin  text-white text-base lg:text-xl">
                      Current Capacity: {item?.capacity}
                    </p>

                    <p className="font-font-AuthorThin  text-white text-base lg:text-xl">
                      Accreditations: {item?.accrediations}
                    </p>

                    <p className="font-font-AuthorThin  text-white text-base lg:text-xl">
                      <span className="font-AuthorThin">
                        {item?.certification}
                      </span>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="pb-10">
            <HowWeAreRaising />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facilities;
