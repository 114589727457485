import { authenticationService } from "../../../config/auth.service";
import React, { Component } from "react";
import api from "../../../api/index";
import { Link } from "react-router-dom";
import browserHistory from "../../../config/browserHistory";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import SideMenu from "../../components/SideMenu";
import Moment from "moment";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
class ProductEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      success: "",
      id: this.props.match.params.id,
      status: true,
      name: "",
      category_slug: "active-pharmaceutical-ingredients-apis",
      drug_name: "",
      drug_type: "",
      appearance: "",
      cas_no: "",
      molecular_formula: "",
      molecular_weight: "",
      melt_point: "",
      product_status_short: "",
      product_status: "",
      product_users: "",
      image_url: "",
      formErrors: {
        name: "",
        drug_name: "",
        drug_type: "",
        cas_no: "",
        molecular_formula: "",
        molecular_weight: "",
        melt_point: "",
        image_url: "",
      },
      formValid: false,
    };
    console.log(this.props.match.params.id);
  }

  componentDidMount() {
    if (this.state.id) {
      this.getProductById();
    }
  }

  getProductById = () => {
    api
      .get("/admin/products/" + this.state.id)
      .then((res) => {
        console.log(res);
        this.setState({
          formValid: true,
          drug_nameValid: true,
          drug_typeValid: true,
          nameValid: true,
          cas_noValid: true,
          molecular_formulaValid: true,
          molecular_weightValid: true,
          melt_pointValid: true,
          image_urlValid: true,

          id: res.data.data.id,
          name: res.data.data.name,
          status: res.data.data.status,
          category_slug: res.data.data.category_slug,
          drug_name: res.data.data.drug_name,
          drug_type: res.data.data.drug_type,
          appearance: res.data.data.appearance,
          cas_no: res.data.data.cas_no,
          molecular_formula: res.data.data.molecular_formula,
          molecular_weight: res.data.data.molecular_weight,
          melt_point: res.data.data.melt_point,
          product_status_short: res.data.data.product_status_short,
          product_status: res.data.data.product_status,
          product_users: res.data.data.product_users,
          image_url: (process.env.REACT_APP_BASE_URL || 'https://api-virupaksha.scube.me') +res.data.data.image_url,
        });
        console.log(this.state);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.formValid) {
      let data = {
        name: this.state.name,
        id: this.state.id,
        status: this.state.status,
        category_slug: this.state.category_slug,
        drug_name: this.state.drug_name,
        drug_type: this.state.drug_type,
        appearance: this.state.appearance,
        slug: this.state.slug,
        cas_no: this.state.cas_no,
        molecular_formula: this.state.molecular_formula,
        molecular_weight: this.state.molecular_weight,
        melt_point: this.state.melt_point,
        product_status_short: this.state.product_status_short,
        product_status: this.state.product_status,
        product_users: this.state.product_users,
        image_url: this.state.image_url,
      };
      this.setState({ isLoading: true });
      var self = this;
      if (this.state.id) {
        api
          .put("/admin/products/" + this.state.id, data)
          .then((res) => {
            if (res.data.status) {
              self.setState({
                success: res.data.message,
                isLoading: false,
              });
              toast.success(res.data.message, { className: "success-info" });
            } else {
              self.setState({ isLoading: false });
              toast.error(res.data.message, { className: "error-info" });
            }
          })
          .catch((err) => {
            self.setState({ isLoading: false });
            toast.error("Some thing went wrong!, please try again", {
              className: "error-info",
            });
          });
      } else {
        api
          .post("/admin/products", data)
          .then((res) => {
            if (res.data.status) {
              self.setState({
                success: res.data.message,
                isLoading: false,
              });

              toast.success(res.data.message, { className: "success-info" });
            } else {
              self.setState({ isLoading: false });
              toast.error(res.data.message, { className: "error-info" });
            }
          })
          .catch((err) => {
            self.setState({ isLoading: false });
            toast.error("Some thing went wrong!, please try again", {
              className: "error-info",
            });
          });
      }
    } else {
      toast.error("Please fill the required details", {
        className: "error-info",
      });
      if (this.state.name === "") {
        this.handleChange({ data: "name" });
      }

      if (this.state.category_slug === "") {
        this.handleChange({ data: "category_slug" });
      }
      if (this.state.drug_name === "") {
        this.handleChange({ data: "drug_name" });
      }
      if (this.state.drug_type === "") {
        this.handleChange({ data: "drug_type" });
      }
      if (this.state.cas_no === "") {
        this.handleChange({ data: "cas_no" });
      }
      if (this.state.molecular_formula === "") {
        this.handleChange({ data: "molecular_formula" });
      }
      if (this.state.molecular_weight === "") {
        this.handleChange({ data: "molecular_weight" });
      }
      if (this.state.melt_point === "") {
        this.handleChange({ data: "melt_point" });
      }
      if (this.state.image_url === "") {
        this.handleChange({ data: "image_url" });
      }

      if (this.state.product_users === "") {
        this.handleChange({ data: "product_users" });
      }

      this.scrollToFirstInvalidControl();
      return false;
    }
  };

  scrollToFirstInvalidControl() {
    const firstInvalidControl = document.querySelector(".has-error");

    if (firstInvalidControl) {
      $(window).scrollTop($("input").offset().top - 120);
      firstInvalidControl.focus();
    }
  }

  handleChange = (e) => {
    this.setState({ success: "" });
    let nameKey;
    let valueData;
    if (e["data"]) {
      nameKey = e.data;
      valueData = "";
    }
    if (e.type) {
      nameKey = e.target.name;
      valueData = e.target.value;
    }
    const name = nameKey;
    const value = valueData;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let drug_nameValid = this.state.drug_nameValid;
    let drug_typeValid = this.state.drug_typeValid;
    let nameValid = this.state.nameValid;
    let cas_noValid = this.state.cas_noValid;
    let molecular_formulaValid = this.state.molecular_formulaValid;
    let molecular_weightValid = this.state.molecular_weightValid;
    let melt_pointValid = this.state.melt_pointValid;
    let image_urlValid = this.state.image_urlValid;

    switch (fieldName) {
      case "name":
        nameValid = value.length > 0;
        fieldValidationErrors.name = nameValid ? "" : " is invalid";
        break;
      case "drug_name":
        drug_nameValid = value.length > 0;
        fieldValidationErrors.drug_name = drug_nameValid ? "" : " is invalid";
        break;
      case "drug_type":
        drug_typeValid = value.length > 0;
        fieldValidationErrors.drug_type = drug_typeValid ? "" : " is invalid";
        break;
      case "cas_no":
        cas_noValid = value.length > 0;
        fieldValidationErrors.cas_no = cas_noValid ? "" : " is invalid";
        break;
      case "molecular_formula":
        molecular_formulaValid = value.length > 0;
        fieldValidationErrors.molecular_formula = molecular_formulaValid
          ? ""
          : " is invalid";
        break;
      case "molecular_weight":
        molecular_weightValid = value.length > 0;
        fieldValidationErrors.molecular_weight = molecular_weightValid
          ? ""
          : " is invalid";
        break;
      case "melt_point":
        melt_pointValid = value.length > 0;
        fieldValidationErrors.melt_point = melt_pointValid ? "" : " is invalid";
        break;
      case "image_url":
        image_urlValid = value.length > 0;
        fieldValidationErrors.image_url = image_urlValid ? "" : " is invalid";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        nameValid: nameValid,
        drug_nameValid: drug_nameValid,
        drug_typeValid: drug_typeValid,
        cas_noValid: cas_noValid,
        molecular_formulaValid: molecular_formulaValid,
        molecular_weightValid: molecular_weightValid,
        melt_pointValid: melt_pointValid,
        image_urlValid: image_urlValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.nameValid &&
        this.state.drug_nameValid &&
        this.state.drug_typeValid &&
        this.state.cas_noValid &&
        this.state.molecular_formulaValid &&
        this.state.molecular_weightValid &&
        this.state.melt_pointValid &&
        this.state.image_urlValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }
  onFileChange = (event) => {
    console.log(event);
    if (event.target.files && event.target.files[0]) {
      if (
        event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpg"
      ) {
        var reader = new FileReader();
        var self = this;
        reader.onload = (e) => {
          self.setState({ image_url: e.target.result });
          self.validateField("image_url", e.target.result);
        };

        reader.readAsDataURL(event.target.files[0]);
      } else {
        alert("invalid image");
      }
    }
  };

  render() {
    return (
      <div className="flex bg-gray-100 admin bg-adminbg">
        <ToastContainer />
        <SideMenu></SideMenu>
        <div className="flex flex-col w-full h-screen overflow-y-hidden">
          <AdminHeader></AdminHeader>
          <div className="flex flex-col w-full overflow-x-hidden border-t border-ThemeLightBlue">
            <main className="flex-grow w-full p-6 ">
              <div>
                <div className="flex items-center breadcrumbs">
                  <Link
                    to="/admin/product-list"
                    className="text-base text-blue"
                  >
                    Products List
                  </Link>{" "}
                  <svg
                    className="mx-3"
                    width="8"
                    height="12"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 18.25L10.75 10.125L2 2"
                      stroke="#5F7995"
                      strokeWidth="1.875"
                      strokeLinecap="square"
                    />
                  </svg>
                  <span className="text-base text-ThemeBlue font-WavehausBold">
                    {" "}
                    {this.state.id ? "Edit product" : "Create new product"}{" "}
                  </span>
                </div>

                <hr className="mt-2 border-borderColor" />

                <div className="w-full pr-0 my-6 lg:pr-2">
                  <div className="px-6 bg-white border rounded-lg shadow-xl py-7 border-borderBlue">
                    <form ref="form" onSubmit={this.handleSubmit} noValidate>
                      <div>
                        <div
                          className={`form-group w-1/2 inline-block my-1 ml-2`}
                        >
                          <select
                            id="category_slug"
                            className="form-group__input "
                            onChange={this.handleChange}
                            value={this.state.category_slug}
                            name="category_slug"
                          >
                            <option value="active-pharmaceutical-ingredients-apis">
                              Active Pharmaceutical
                            </option>
                            <option value="intermediates">Intermediates</option>
                          </select>
                          <label
                            htmlFor="category_slug"
                            className="form-group__label"
                          >
                            Product Type
                          </label>
                        </div>

                        <div
                          className={`form-group w-1/2 inline-block my-1 mr-2 ${this.errorClass(
                            this.state.formErrors.name
                          )}`}
                        >
                          <input
                            type="text"
                            id="name"
                            value={this.state.name}
                            onChange={this.handleChange}
                            noValidate
                            name="name"
                            className="form-group__input"
                            autoComplete="off"
                            placeholder=" "
                          />
                          <label htmlFor="name" className="form-group__label">
                            Product Title
                          </label>
                        </div>

                        <div
                          className={`form-group w-1/2 inline-block my-1 mr-2 ${this.errorClass(
                            this.state.formErrors.drug_name
                          )}`}
                        >
                          <input
                            type="text"
                            id="drug_name"
                            value={this.state.drug_name}
                            onChange={this.handleChange}
                            noValidate
                            name="drug_name"
                            className="form-group__input"
                            autoComplete="off"
                            placeholder=" "
                          />
                          <label
                            htmlFor="drug_name"
                            className="form-group__label"
                          >
                            Chemical Name
                          </label>
                        </div>

                        <div
                          className={`form-group w-1/2 inline-block my-1 mr-2 ${this.errorClass(
                            this.state.formErrors.drug_type
                          )}`}
                        >
                          <input
                            type="text"
                            id="drug_type"
                            value={this.state.drug_type}
                            onChange={this.handleChange}
                            noValidate
                            name="drug_type"
                            className="form-group__input"
                            autoComplete="off"
                            placeholder=" "
                          />
                          <label
                            htmlFor="drug_type"
                            className="form-group__label"
                          >
                            Drug Type
                          </label>
                        </div>

                        <div
                          className={`form-group w-1/2 inline-block my-1 mr-2 ${this.errorClass(
                            this.state.formErrors.cas_no
                          )}`}
                        >
                          <input
                            type="text"
                            id="cas_no"
                            value={this.state.cas_no}
                            onChange={this.handleChange}
                            noValidate
                            name="cas_no"
                            className="form-group__input"
                            autoComplete="off"
                            placeholder=" "
                          />
                          <label htmlFor="cas_no" className="form-group__label">
                            Cas no
                          </label>
                        </div>

                        <div
                          className={`form-group w-1/2 inline-block my-1 mr-2`}
                        >
                          <input
                            type="text"
                            id="appearance"
                            value={this.state.appearance}
                            onChange={this.handleChange}
                            name="appearance"
                            className="form-group__input"
                            autoComplete="off"
                            placeholder=" "
                          />
                          <label
                            htmlFor="appearance"
                            className="form-group__label"
                          >
                            Appearance
                          </label>
                        </div>

                        <div
                          className={`form-group w-1/2 inline-block my-1 mr-2 ${this.errorClass(
                            this.state.formErrors.molecular_formula
                          )}`}
                        >
                          <input
                            type="text"
                            id="molecular_formula"
                            value={this.state.molecular_formula}
                            onChange={this.handleChange}
                            noValidate
                            name="molecular_formula"
                            className="form-group__input"
                            autoComplete="off"
                            placeholder=" "
                          />
                          <label
                            htmlFor="molecular_formula"
                            className="form-group__label"
                          >
                            Molecular Formula
                          </label>
                        </div>

                        <div
                          className={`form-group w-1/2 inline-block my-1 mr-2 ${this.errorClass(
                            this.state.formErrors.molecular_weight
                          )}`}
                        >
                          <input
                            type="text"
                            id="molecular_weight"
                            value={this.state.molecular_weight}
                            onChange={this.handleChange}
                            noValidate
                            name="molecular_weight"
                            className="form-group__input"
                            autoComplete="off"
                            placeholder=" "
                          />
                          <label
                            htmlFor="molecular_weight"
                            className="form-group__label"
                          >
                            Molecular Weight
                          </label>
                        </div>

                        <div
                          className={`form-group w-1/2 inline-block my-1 mr-2 ${this.errorClass(
                            this.state.formErrors.melt_point
                          )}`}
                        >
                          <input
                            type="text"
                            id="melt_point"
                            value={this.state.melt_point}
                            onChange={this.handleChange}
                            noValidate
                            name="melt_point"
                            className="form-group__input"
                            autoComplete="off"
                            placeholder=" "
                          />
                          <label
                            htmlFor="melt_point"
                            className="form-group__label"
                          >
                            Melt Point{" "}
                          </label>
                        </div>

                        <div className="inline-block w-1/2 my-1 mr-2 form-group ">
                          {this.state.image_url ? (
                            <img
                              src={this.state.image_url}
                              className="inline-block object-contain w-56 h-48 mt-8"
                            />
                          ) : null}
                          <label
                            className={` form-group__file  border-ThemeLightBlue ${this.errorClass(
                              this.state.formErrors.image_url
                            )} ${
                              this.state.image_url ? "" : "bg-borderBlue border"
                            }`}
                          >
                            {this.state.image_url == "" ? (
                              <>
                                <p>Upload Image Here</p>
                                <svg
                                  className="inline-block ml-4"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clipPath="url(#clip0_1067_1292)">
                                    <path
                                      d="M7.99744 15.9964C6.51063 15.9964 5.02336 16.0073 3.53745 15.9932C2.18716 15.9805 1.15317 15.387 0.459596 14.2242C0.159931 13.718 0.00268188 13.1403 0.00449483 12.5521C-0.00278679 11.5303 -5.61854e-05 10.5086 0.00449483 9.48689C0.00449483 9.12644 0.216117 8.84245 0.530592 8.75415C0.836875 8.66814 1.17547 8.77873 1.33066 9.05407C1.40904 9.19692 1.45138 9.35674 1.45399 9.51965C1.46492 10.4959 1.459 11.4721 1.45991 12.4483C1.45991 13.6589 2.34326 14.5396 3.55702 14.5418C6.52034 14.5418 9.4832 14.5418 12.4456 14.5418C13.6589 14.5418 14.5391 13.6607 14.5391 12.4483C14.5391 11.4548 14.5391 10.4617 14.5391 9.46823C14.5358 9.31371 14.5822 9.16221 14.6713 9.03593C14.7604 8.90964 14.8876 8.8152 15.0342 8.76644C15.3237 8.6695 15.6509 8.75324 15.8252 9.00538C15.9112 9.12962 15.9849 9.29392 15.9858 9.44092C15.9927 10.5701 16.0204 11.7015 15.9645 12.8283C15.8875 14.3648 14.6342 15.6997 13.1178 15.9381C12.8769 15.9759 12.6335 15.9954 12.3896 15.9964C10.9265 15.9991 9.46196 15.9964 7.99744 15.9964Z"
                                      fill="#7D94B1"
                                    />
                                    <path
                                      d="M7.27513 2.49057C7.19276 2.56885 7.14725 2.61072 7.10356 2.65441C6.13996 3.61743 5.17666 4.58106 4.21366 5.5453C4.0116 5.74782 3.78268 5.85341 3.49278 5.78969C3.37147 5.76392 3.25883 5.70738 3.16567 5.6255C3.07252 5.54362 3.00199 5.43916 2.96085 5.32216C2.91972 5.20515 2.90937 5.07954 2.93079 4.95738C2.95222 4.83522 3.0047 4.72062 3.08319 4.6246C3.1202 4.58151 3.15939 4.54035 3.20061 4.50127C4.60293 3.09982 6.00449 1.69867 7.40529 0.297828C7.79971 -0.096604 8.19413 -0.0979693 8.58855 0.293732C9.99846 1.70277 11.4081 3.11165 12.8174 4.52038C13.0258 4.72837 13.1273 4.96685 13.0517 5.25949C13.0225 5.3816 12.9617 5.4939 12.8755 5.58519C12.7893 5.67648 12.6806 5.74357 12.5604 5.77977C12.4401 5.81597 12.3125 5.82 12.1902 5.79148C12.0679 5.76296 11.9553 5.70287 11.8635 5.61721C11.6705 5.44153 11.4916 5.25311 11.3069 5.06606C10.4998 4.2593 9.69354 3.45177 8.88801 2.64349C8.84568 2.60116 8.82794 2.53472 8.79699 2.47919L8.72099 2.53335V2.7295C8.72099 5.67045 8.72099 8.6114 8.72099 11.5524C8.72099 11.9701 8.53166 12.2396 8.17486 12.3342C8.0731 12.3609 7.96672 12.365 7.86322 12.346C7.75973 12.3271 7.66166 12.2857 7.57593 12.2247C7.4902 12.1637 7.41891 12.0847 7.36709 11.9931C7.31527 11.9015 7.28419 11.7997 7.27604 11.6948C7.27058 11.6156 7.27194 11.536 7.27194 11.4568V2.49057H7.27513Z"
                                      fill="#7D94B1"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1067_1292">
                                      <rect
                                        width="16"
                                        height="16"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </>
                            ) : (
                              <div className="underline cursor-pointer">
                                Change image
                              </div>
                            )}
                            <input
                              type="file"
                              id="image_url"
                              accept="image/jpg, image/jpeg, image/png"
                              className="hidden"
                              noValidate
                              name="image_url"
                              onChange={this.onFileChange}
                            />
                          </label>
                        </div>

                        <div
                          className={`form-group w-1/2 inline-block my-1 mr-2`}
                        >
                          <input
                            type="text"
                            id="product_status_short"
                            value={this.state.product_status_short}
                            onChange={this.handleChange}
                            noValidate
                            name="product_status_short"
                            className="form-group__input"
                            autoComplete="off"
                            placeholder=" "
                          />
                          <label
                            htmlFor="product_status_short"
                            className="form-group__label"
                          >
                            Product Status Short{" "}
                          </label>
                        </div>

                        <div className="form-group textarea">
                          <textarea
                            type="text"
                            rows="4"
                            id="product_status"
                            onChange={this.handleChange}
                            value={this.state.product_status}
                            name="product_status"
                            className="form-group__input"
                            autoComplete="off"
                            placeholder=" "
                          />
                          <label
                            htmlFor="product_status"
                            className="w-42 form-group__label"
                          >
                            {" "}
                            Product Status
                          </label>
                        </div>

                        <div className="form-group textarea">
                          <textarea
                            type="text"
                            rows="4"
                            id="product_users"
                            onChange={this.handleChange}
                            value={this.state.product_users}
                            name="product_users"
                            className="form-group__input"
                            autoComplete="off"
                            placeholder=" "
                          />
                          <label
                            htmlFor="product_users"
                            className="w-42 form-group__label"
                          >
                            {" "}
                            Product Uses
                          </label>
                        </div>
                      </div>
                      <p className="px-4 text-green">{this.state.success}</p>
                      <div className="w-full mt-4 text-right">
                        {!this.state.isLoading ? (
                          <button className="px-6 py-3 mb-1 mr-1 text-sm text-white transition-all duration-150 ease-linear rounded-full shadow outline-none cursor-pointer font-WavehausSemiBold bg-green get-started focus:outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-lg">
                            <span>Submit</span>
                          </button>
                        ) : (
                          <button className="px-8 py-3 text-sm text-white transition-all duration-150 ease-linear shadow outline-none rounded-3xl font-WavehausBold bg-green get-started focus:outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-lg">
                            Loading...
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductEdit;
