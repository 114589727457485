import "./App.scss";
import Layout from "./Layout/Layout";
import LandscapeFallbackMessage from "./components/LandScapeFallBack";
import Scroll from "./scroll";
import React, { useEffect, useState } from "react";

const App = () => {
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {

   
    const handleOrientationChange = () => {
      setIsLandscape(
        (typeof window !== "undefined" && window.orientation === 90) ||
          window.orientation === -90
      );
    };

    typeof window !== "undefined" &&
      window.addEventListener("orientationchange", handleOrientationChange);

    handleOrientationChange();

    return () => {
      typeof window !== "undefined" &&
        window.removeEventListener(
          "orientationchange",
          handleOrientationChange
        );
    };
  }, []);
  return (
    <div className="">
      <div>{isLandscape && <LandscapeFallbackMessage />}</div>
      {!isLandscape && (
        <div>
          <Scroll />
          <Layout />
        </div>
      )}
    </div>
  );
};

export default App;
