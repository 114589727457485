import React from "react";
import EnquiryModal from "../../components/EnquiryModal";
import { useState } from "react";
import Button from "../../components/Button";

const EnquiryCommon = ({ product_id }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <div className="w-full">
      <div className="w-full" onClick={handleOpen}>
        <Button variant="contained">
          <p className="text-xs md:text-sm font-AuthorThin">Enquire Now</p>
        </Button>
      </div>

      <EnquiryModal
        open={open}
        handleClose={handleClose}
        product_id={product_id}
      />
    </div>
  );
};

export default EnquiryCommon;
