import React from "react";

const TextArea = ({
  label,
  id,
  name,
  rows = 4,
  className,
  value,
  error,
  handleChange,
  helperText,
  readOnly = false,
}) => (
  <div className="relative">
    <textarea
      readOnly={readOnly}
      className={`font-AuthorThin border-border w-full border ${className ? className : "bg-white"
        } text-lightBlue hover:border-lightBlue rounded-lg p-4 outline-none placeholder:font-AuthorThin focus:border-[2px] focus:border-[#74BFB6] ${
        error ? "border-error placeholder-error" : "" 
      }`}
      name={name}
      id={id}
      rows={rows}
      value={value}
      onChange={handleChange}
      placeholder={label}
    />
    {helperText && (
      <p className="text-error absolute -bottom-3 ml-4 text-xs">
        {helperText}
      </p>
    )}
  </div>
);

export default TextArea;
