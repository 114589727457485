import React, { Component } from "react";
import api from "../../../api/index";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import SideMenu from "../../components/SideMenu";
import Moment from "moment";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../components/Loader";
class UserList extends Component {
  currentUser = {};

  state = {
    search: "",
    pageNo: "",
    total: "0",
    totalPage: "0",
    users: [],
    loading: true,
  };

  componentDidMount() {
    this.getUsers(1, "");
  }

  onSearch = (event) => {
    this.setState({ search: event });
    this.getUsers(this.state.pageNo, event);
  };

  getUsers = (page_no, search) => {
    api
      .get(`/admin/users`, {
        params: {
          search: search,
          page_no: page_no,
          size: 10,
        },
      })
      .then((res) => {
        console.log(res.data);
        this.setState({
          users: res.data.data.data,
          total: res.data.data.pagination.total,
          totalPage: res.data.data.pagination.lastPage,
          pageNo: page_no,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  pagination = () => {
    var page = [];
    for (let i = 1; i <= Math.ceil(this.state.total / 10); i++) {
      page.push(
        <li className="paginate_button page-item">
          {this.state.pageNo == i ? (
            <li className="paginate_button page-item active">
              <button
                aria-controls="dataTable"
                className="page-link "
                onClick={() => this.getUsers(i)}
              >
                {i}
              </button>{" "}
            </li>
          ) : (
            <button
              aria-controls="dataTable"
              className="page-link"
              onClick={() => this.getUsers(i)}
            >
              {i}
            </button>
          )}
        </li>
      );
    }
    return page;
  };

  removeItem = (id) => {
    api
      .delete(`/admin/users/` + id)
      .then((res) => {
        this.setState({
          users: this.state.users.filter((item) => item.id != id),
          total: this.state.total - 1,
        });
        toast.success(res.data.message, { className: "success-info" });
      })
      .catch((err) => {
        toast.error("Some thing went wrong!, please try again", {
          className: "error-info",
        });
        console.log(err);
      });
  };

  render() {
    return (
      <div className="flex bg-gray-100 admin bg-adminbg">
        <SideMenu></SideMenu>
        <div className="flex flex-col w-full h-screen overflow-y-hidden">
          <AdminHeader></AdminHeader>
          <div className="flex flex-col w-full overflow-x-hidden border-t border-ThemeLightBlue">
            {this.state.loading ? (
              <Loader />
            ) : (
              <main className="flex-grow w-full p-6 ">
                <div>
                  <div className="flex justify-between">
                    <h2 className="text-xl text-ThemeBlue font-WavehausBold">
                      Users ({this.state.total})
                    </h2>

                    <Link
                      to={"/admin/new-user"}
                      className="px-6 py-3 mb-1 mr-1 text-sm text-white transition-all duration-150 ease-linear rounded-full shadow outline-none cursor-pointer font-WavehausSemiBold bg-green get-started focus:outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-lg"
                    >
                      <svg
                        className="inline"
                        width="16"
                        height="15"
                        viewBox="0 0 16 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 4V11M4.5 7.5H11.5M8 14.5C4.13401 14.5 1 11.366 1 7.5C1 3.63401 4.13401 0.5 8 0.5C11.866 0.5 15 3.63401 15 7.5C15 11.366 11.866 14.5 8 14.5Z"
                          stroke="white"
                        ></path>
                      </svg>
                      <span className="ml-2">Add New User</span>
                    </Link>
                  </div>

                  <hr className="mt-2 border-borderColor" />
                  <div className="top-filters">
                    <div className="flex items-center justify-end mt-6">
                      <div className="relative w-56">
                        <svg
                          className="absolute top-3 left-2"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17 17L12.4286 12.4286M7.85714 14.7143C4.07005 14.7143 1 11.6442 1 7.85714C1 4.07005 4.07005 1 7.85714 1C11.6442 1 14.7143 4.07005 14.7143 7.85714C14.7143 11.6442 11.6442 14.7143 7.85714 14.7143Z"
                            stroke="#A7C0DD"
                          />
                        </svg>

                        <input
                          type="text"
                          id="search"
                          name="search"
                          onChange={(e) => this.onSearch(e.target.value)}
                          placeholder="Search Name"
                          className="px-3 py-2 pl-8 border text-ThemeBlue border-borderBlue rounded-xl"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full pr-0 my-6">
                    <div className="px-6 bg-white border rounded-lg shadow-xl py-7 border-borderBlue">
                      <table className="table ">
                        <thead className="" style={{ color: "#0d4a61" }}>
                          <tr>
                            <th scope="col">S.NO</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email ID</th>
                            <th scope="col">Created on</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.users.map((user, index) => {
                            return (
                              <tr role="row" className="odd" key={user.id}>
                                <td>{index + 1}</td>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>
                                  {Moment(user.created_at).format("d MMM YYYY")}
                                </td>

                                <td className="flex items-center">
                                  <Link
                                    to={"/admin/user-list/" + user.id}
                                    className="mr-2 btn rounded-xl bg-blue btn-info btn-sm"
                                  >
                                    <svg
                                      width="30"
                                      height="30"
                                      viewBox="0 0 30 30"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x="0.5"
                                        y="0.5"
                                        width="29"
                                        height="29"
                                        rx="4.5"
                                        fill="#376ABF"
                                        stroke="#2C5CAE"
                                      />
                                      <path
                                        d="M8 16L7.64645 15.6464L7.5 15.7929V16H8ZM17 7L17.3536 6.64645C17.1583 6.45118 16.8417 6.45118 16.6464 6.64645L17 7ZM22 12L22.3536 12.3536C22.5488 12.1583 22.5488 11.8417 22.3536 11.6464L22 12ZM13 21V21.5H13.2071L13.3536 21.3536L13 21ZM8 21H7.5C7.5 21.2761 7.72386 21.5 8 21.5V21ZM8.35355 16.3536L17.3536 7.35355L16.6464 6.64645L7.64645 15.6464L8.35355 16.3536ZM16.6464 7.35355L21.6464 12.3536L22.3536 11.6464L17.3536 6.64645L16.6464 7.35355ZM21.6464 11.6464L12.6464 20.6464L13.3536 21.3536L22.3536 12.3536L21.6464 11.6464ZM13 20.5H8V21.5H13V20.5ZM8.5 21V16H7.5V21H8.5ZM13.6464 10.3536L18.6464 15.3536L19.3536 14.6464L14.3536 9.64645L13.6464 10.3536ZM15.5 21.5H22.5V20.5H15.5V21.5Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </Link>
                                  <svg
                                    className="cursor-pointer"
                                    onClick={() => this.removeItem(user.id)}
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="0.5"
                                      y="0.5"
                                      width="29"
                                      height="29"
                                      rx="4.5"
                                      fill="#FF5555"
                                      stroke="#E13B3B"
                                    />
                                    <path
                                      d="M12.5 10.5V9C12.5 8.44772 12.9477 8 13.5 8H17.5C18.0523 8 18.5 8.44772 18.5 9V10.5M8 11H9.5M9.5 11H21.5M9.5 11V21C9.5 21.5523 9.94772 22 10.5 22H20.5C21.0523 22 21.5 21.5523 21.5 21V11M21.5 11H23M15.5 14.5V19.5M12.5 16.5V19.5M18.5 16.5V19.5"
                                      stroke="white"
                                    />
                                  </svg>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {this.state.users.length == 0 ? (
                        <div className="mt-4 text-center">No Data Found</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <ul className="flex items-center justify-end pagination">
                    <span className="mr-2 -mt-1 text-blue font-WavehausSemiBold">
                      {this.state.pageNo} of {this.state.totalPage}{" "}
                    </span>
                    {/* {this.pagination()}  */}
                    {this.state.pageNo <= 1 ? (
                      <li
                        className="mx-2 paginate_button page-item previous disabled"
                        id="dataTable_previous"
                      >
                        <button className="page-link">
                          <svg
                            width="36"
                            height="36"
                            viewBox="0 0 36 36"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="18" cy="18" r="17.5" stroke="#cfd5dc" />
                            <path
                              d="M15.6717 22.6613L16.1667 23.1563L17.1566 22.1663L16.6616 21.6714L15.6717 22.6613ZM11.5 17.4997L11.005 17.0047L10.5101 17.4997L11.005 17.9946L11.5 17.4997ZM16.6616 13.328L17.1566 12.833L16.1667 11.8431L15.6717 12.338L16.6616 13.328ZM16.6616 21.6714L11.995 17.0047L11.005 17.9946L15.6717 22.6613L16.6616 21.6714ZM11.995 17.9946L16.6616 13.328L15.6717 12.338L11.005 17.0047L11.995 17.9946ZM11.5 18.1997H26.6667V16.7997H11.5V18.1997Z"
                              fill="#1B3F67"
                            />
                          </svg>
                        </button>
                      </li>
                    ) : (
                      <li
                        className="mx-2 paginate_button page-item previous"
                        id="dataTable_previous"
                      >
                        <button
                          className="page-link"
                          onClick={() => this.getUsers(this.state.pageNo - 1)}
                        >
                          <svg
                            width="36"
                            height="36"
                            viewBox="0 0 36 36"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="18" cy="18" r="17.5" stroke="#4EE287" />
                            <path
                              d="M15.6717 22.6613L16.1667 23.1563L17.1566 22.1663L16.6616 21.6714L15.6717 22.6613ZM11.5 17.4997L11.005 17.0047L10.5101 17.4997L11.005 17.9946L11.5 17.4997ZM16.6616 13.328L17.1566 12.833L16.1667 11.8431L15.6717 12.338L16.6616 13.328ZM16.6616 21.6714L11.995 17.0047L11.005 17.9946L15.6717 22.6613L16.6616 21.6714ZM11.995 17.9946L16.6616 13.328L15.6717 12.338L11.005 17.0047L11.995 17.9946ZM11.5 18.1997H26.6667V16.7997H11.5V18.1997Z"
                              fill="#1B3F67"
                            />
                          </svg>
                        </button>
                      </li>
                    )}
                    {this.state.pageNo >= this.state.totalPage ? (
                      <li
                        className="mx-2 paginate_button page-item next disabled"
                        id="dataTable_next"
                      >
                        <button className="page-link">
                          <svg
                            width="36"
                            height="36"
                            viewBox="0 0 36 36"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="18" cy="18" r="17.5" stroke="#cfd5dc" />
                            <path
                              d="M20.9963 12.3377L20.5013 11.8427L19.5114 12.8327L20.0063 13.3277L20.9963 12.3377ZM25.168 17.4993L25.6629 17.9943L26.1579 17.4993L25.6629 17.0044L25.168 17.4993ZM20.0063 21.671L19.5114 22.166L20.5013 23.156L20.9963 22.661L20.0063 21.671ZM20.0063 13.3277L24.673 17.9943L25.6629 17.0044L20.9963 12.3377L20.0063 13.3277ZM24.673 17.0044L20.0063 21.671L20.9963 22.661L25.6629 17.9943L24.673 17.0044ZM25.168 16.7993L10.0013 16.7994L10.0013 18.1994L25.168 18.1993L25.168 16.7993Z"
                              fill="#1B3F67"
                            />
                          </svg>
                        </button>
                      </li>
                    ) : (
                      <li
                        className="mx-2 paginate_button page-item next "
                        id="dataTable_next"
                      >
                        <button
                          className="page-link"
                          onClick={() => this.getUsers(this.state.pageNo + 1)}
                        >
                          <svg
                            width="36"
                            height="36"
                            viewBox="0 0 36 36"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="18" cy="18" r="17.5" stroke="#4EE287" />
                            <path
                              d="M20.9963 12.3377L20.5013 11.8427L19.5114 12.8327L20.0063 13.3277L20.9963 12.3377ZM25.168 17.4993L25.6629 17.9943L26.1579 17.4993L25.6629 17.0044L25.168 17.4993ZM20.0063 21.671L19.5114 22.166L20.5013 23.156L20.9963 22.661L20.0063 21.671ZM20.0063 13.3277L24.673 17.9943L25.6629 17.0044L20.9963 12.3377L20.0063 13.3277ZM24.673 17.0044L20.0063 21.671L20.9963 22.661L25.6629 17.9943L24.673 17.0044ZM25.168 16.7993L10.0013 16.7994L10.0013 18.1994L25.168 18.1993L25.168 16.7993Z"
                              fill="#1B3F67"
                            />
                          </svg>
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
              </main>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default UserList;
