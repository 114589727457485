import React from "react";

const GoogleMap = () => {
  const handleMapClick = (mapLocation, index) => {
    if (index === 1) {
      window.open(`${mapLocation}`, "_blank");
    } else {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${mapLocation}`,
        "_blank"
      );
    }
  };

  let mapData = [
    {
      title: "Unit 1",
      image: "/home/contact/unit1.png",
      address:
        "Survey No.10, Gaddapotharam Village, Jinnaram Mandal, Sangareddy District - 502319, Telangana, India.",
      map_location: "Virupaksha Organics Ltd. Unit-1, Gaddapotharam, Telangana",
    },
    {
      title: "Unit 2",
      image: "/home/contact/unit2.png",
      address:
        "Plot No. 30 To 33, Phase-1, IDA, Pashamylaram, Patancheru Mandal, Sangareddy District - 502307, Telangana, India.",
      map_location:
        "https://www.google.com/maps/place/Virupaksha+Organics/@17.5393231,78.1928338,17z/data=!3m1!4b1!4m6!3m5!1s0x3bcbf2291b6bb115:0xb942f445a07dc504!8m2!3d17.5393231!4d78.1928338!16s%2Fg%2F11g6_1hsjx?hl=en&entry=ttu",
    },
  ];

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 px-4 md:px-10 lg:px-12 gap-10">
      {mapData?.map((item, index) => (
        <div
          key={index}
          className="relative border-2 border-border rounded-md  grid grid-cols-1 lg:grid-cols-2"
        >
          <div className="flex py-4 rounded-t-md lg:rounded-t-none lg:rounded-l-md product_bg justify-center items-center    flex-col w-full">
            <img src="/home/contact/location.svg" alt="" className="w-12" />

            <p className="text-green font-AuthorSemiBold text-xl lg:text-2xl">
              {item?.title}
            </p>
            <p className="w-full md:w-10/12 lg:w-full px-3 text-base  text-center  font-AuthorLight text-white">
              {item?.address}
            </p>
          </div>
          <div className="w-full   relative rounded-b-md lg:rounded-b-none lg:rounded-r-md">
            <div className="absolute left-1 md:left-6 top-3">
              <p
                onClick={() => {
                  handleMapClick(item?.map_location, index);
                }}
                className="bg-white p-2 shadow-xl font-AuthorThin font-medium text-skyBlue hover:underline cursor-pointer"
              >
                View Larger map
              </p>
            </div>

            <img
              src={item?.image}
              alt="Clickable Map"
              onClick={() => {
                handleMapClick(item?.map_location, index);
              }}
              className="w-full cursor-pointer h-full md:h-40 lg:h-full  object-cover rounded-r-md"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default GoogleMap;
