import React from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button";

const HowWeAreRaising = () => {
  return (
    <div className="w-full px-4  md:px-10 lg:px-28 mt-10">
      <div className="w-full  py-10 rounded-lg bg-enquire_now bg-cover flex flex-col justify-center items-center gap-6">
        <p className="text-white font-AuthorSemiBold text-xl md:text-3xl text-center md:w-9/12 w-6/12 lg:w-6/12">
          See how we are raising the benchmark in the pharmaceutical industry.
        </p>

        <Link to="/contact-us">
          <div className="w-32">
            <Button variant="">
              {" "}
              <p className="text-white font-AuthorSemiBold text-lg">
                Contact Us
              </p>
            </Button>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default HowWeAreRaising;
