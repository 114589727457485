import React, { Component } from "react";
import api from "../../../api/index";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import SideMenu from "../../components/SideMenu";
import $ from "jquery";

class UserEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      success: "",
      id: this.props.match.params.id,
      name: "",
      phone: "",
      email: "",
      password: "",
      password_confirmation: "",
      formErrors: {
        email: "",
        name: "",
        phone: "",
        password: "",
        password_confirmation: "",
      },
      emailValid: false,
      formValid: false,
    };
  }

  componentDidMount() {
    this.getUserById();
  }

  getUserById = () => {
    api
      .get("/admin/users/" + this.state.id)
      .then((res) => {
        console.log(res);
        this.setState({
          formValid: true,
          email: res.data.data.email,
          phone: res.data.data.phone,
          name: res.data.data.name,
        });
        console.log(this.state);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onHandleTelephoneChange = (e) => {
    let phone = e.target.value;
    if (!Number(phone)) {
      return;
    }
    this.setState({
      [e.target.name]: phone,
    });
    this.handleChange(e);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.state.id) {
      if (
        this.state.password.length < 8 ||
        this.state.password_confirmation.length < 8 ||
        this.state.password !== this.state.password_confirmation
      ) {
        $("#password").addClass("has-error");
        $("#password_con").addClass("has-error");
        toast.error("password min length 8 & password not same", {
          className: "error-info",
        });
      }
    }
    if (this.state.formValid) {
      let data = {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        password: this.state.password,
        password_confirmation: this.state.password_confirmation,
      };
      this.setState({ isLoading: true });
      var self = this;
      if (this.state.id) {
        api
          .put("/admin/users/" + this.state.id, data)
          .then((res) => {
            console.log(res.data);
            self.setState({
              success: res.data.message,
              isLoading: false,
            });
            toast.success(res.data.message, { className: "success-info" });
          })
          .catch((err) => {
            self.setState({ isLoading: false });
            toast.error("Some thing went wrong!, please try again", {
              className: "error-info",
            });
          });
      } else {
        api
          .post("/admin/users", data)
          .then((res) => {
            console.log(res.data);
            self.setState({
              success: res.data.message,
              isLoading: false,
            });
            toast.success(res.data.message, { className: "success-info" });
          })
          .catch((err) => {
            self.setState({ isLoading: false });
            toast.error("Some thing went wrong!, please try again", {
              className: "error-info",
            });
          });
      }
    } else {
      toast.error("Please fill the required details", {
        className: "error-info",
      });
      if (this.state.email === "") {
        this.handleChange({ data: "email" });
      }
      if (this.state.phone === "") {
        this.handleChange({ data: "phone" });
      }
      if (this.state.name === "") {
        this.handleChange({ data: "name" });
      }

      this.scrollToFirstInvalidControl();
      return false;
    }
  };

  scrollToFirstInvalidControl() {
    const firstInvalidControl = document.querySelector(".has-error");

    if (firstInvalidControl) {
      $(window).scrollTop($("input").offset().top - 120);
      firstInvalidControl.focus();
    }
  }

  handleChange = (e) => {
    this.setState({ success: "" });
    let nameKey;
    let valueData;
    if (e["data"]) {
      nameKey = e.data;
      valueData = "";
    }
    if (e.type) {
      nameKey = e.target.name;
      valueData = e.target.value;
    }
    const name = nameKey;
    const value = valueData;
    if (name == "password") {
      $("#password").removeClass("has-error");
    }
    if (name == "password_confirmation") {
      $("#password_con").removeClass("has-error");
    }
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let phoneValid = this.state.phoneValid;
    let nameValid = this.state.nameValid;

    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? "" : " is invalid";
        break;
      case "name":
        nameValid = value.length >= 2;
        fieldValidationErrors.name = nameValid ? "" : " is invalid";
        break;
      case "phone":
        phoneValid = value.length >= 10 && value.length === 10;
        fieldValidationErrors.phone = phoneValid ? "" : " is invalid";
        break;
      default:
        break;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        phoneValid: phoneValid,
        nameValid: nameValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.emailValid && this.state.phoneValid && this.state.nameValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  render() {
    return (
      <div className="flex bg-gray-100 admin bg-adminbg">
        <ToastContainer />
        <SideMenu></SideMenu>
        <div className="flex flex-col w-full h-screen overflow-y-hidden">
          <AdminHeader></AdminHeader>
          <div className="flex flex-col w-full overflow-x-hidden border-t border-ThemeLightBlue">
            <main className="flex-grow w-full p-6 ">
              <div>
                <div className="flex items-center breadcrumbs">
                  <Link to="/admin/user-list" className="text-base text-blue">
                    Users{" "}
                  </Link>{" "}
                  <svg
                    className="mx-3"
                    width="8"
                    height="12"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 18.25L10.75 10.125L2 2"
                      stroke="#5F7995"
                      strokeWidth="1.875"
                      strokeLinecap="square"
                    />
                  </svg>
                  <span className="text-base text-ThemeBlue font-WavehausBold">
                    {this.state.id ? "Edit" : "Add"} new user
                  </span>
                </div>

                <hr className="mt-2 border-borderColor" />

                <div className="w-full pr-0 my-6 lg:pr-2">
                  <form ref="form" onSubmit={this.handleSubmit} noValidate>
                    <div className="px-6 bg-white border rounded-lg shadow-xl py-7 border-borderBlue">
                      <h4 className="pb-2 text-base border-b border-gray text-ThemeBlue font-WavehausBold">
                        Basic Info
                      </h4>
                      <div>
                        <div
                          className={`form-group w-1/2 inline-block my-1 mr-2 ${this.errorClass(
                            this.state.formErrors.name
                          )}`}
                        >
                          <input
                            type="text"
                            id="name"
                            value={this.state.name}
                            onChange={this.handleChange}
                            noValidate
                            name="name"
                            className="form-group__input"
                            autoComplete="off"
                            placeholder=" "
                          />
                          <label htmlFor="name" className="form-group__label">
                            Name
                          </label>
                        </div>

                        <div
                          className={`form-group w-1/2 inline-block my-1 ml-2 ${this.errorClass(
                            this.state.formErrors.phone
                          )}`}
                        >
                          <input
                            type="text"
                            maxLength="10"
                            value={this.state.phone}
                            required
                            id="phone"
                            onChange={this.onHandleTelephoneChange}
                            noValidate
                            name="phone"
                            className="form-group__input"
                            autoComplete="off"
                            placeholder=" "
                          />
                          <label htmlFor="phone" className="form-group__label">
                            Phone
                          </label>
                        </div>

                        <div
                          className={`form-group w-1/2 inline-block  my-1 pl-2 ${this.errorClass(
                            this.state.formErrors.email
                          )}`}
                        >
                          <input
                            type="email"
                            value={this.state.email}
                            required
                            id="email"
                            onChange={this.handleChange}
                            noValidate
                            name="email"
                            className="form-group__input"
                            autoComplete="off"
                            placeholder=" "
                          />
                          <label htmlFor="email" className="form-group__label">
                            Email
                          </label>
                        </div>
                        <h4 className="pb-2 mt-4 text-base border-b border-gray text-ThemeBlue font-WavehausBold">
                          Password Info
                        </h4>

                        <div
                          id="password"
                          className={`form-group w-1/2 inline-block my-1 mr-2 ${this.errorClass(
                            this.state.formErrors.password
                          )}`}
                        >
                          <input
                            type="password"
                            id="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            noValidate
                            name="password"
                            className="form-group__input"
                            autoComplete="off"
                            placeholder=" "
                          />
                          <label
                            htmlFor="password"
                            className="form-group__label"
                          >
                            Password
                          </label>
                        </div>

                        <div
                          id="password_con"
                          className={`form-group w-1/2 inline-block my-1 ml-2 ${this.errorClass(
                            this.state.formErrors.password_confirmation
                          )}`}
                        >
                          <input
                            type="password"
                            value={this.state.password_confirmation}
                            id="password_confirmation"
                            onChange={this.handleChange}
                            noValidate
                            name="password_confirmation"
                            className="form-group__input"
                            autoComplete="off"
                            placeholder=" "
                          />
                          <label
                            htmlFor="password_confirmation"
                            className="form-group__label"
                          >
                            Confirm Password
                          </label>
                        </div>
                      </div>

                      <p className="px-4 text-green">{this.state.success}</p>
                    </div>
                    <div className="w-full mt-4 text-right">
                      {!this.state.isLoading ? (
                        <button className="px-6 py-3 mb-1 mr-1 text-sm text-white transition-all duration-150 ease-linear rounded-full shadow outline-none cursor-pointer font-WavehausSemiBold bg-green get-started focus:outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-lg">
                          <span>Submit Detail</span>
                        </button>
                      ) : (
                        <button className="px-8 py-3 text-sm text-white transition-all duration-150 ease-linear shadow outline-none rounded-3xl font-WavehausBold bg-green get-started focus:outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-lg">
                          Loading...
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

export default UserEdit;
