import React, {useEffect} from "react";
import HowWeAreRaising from "../Product/HowWeAreRaising";
import updateMeta from "../../components/SeoMeta";

const CertificateData = [
  {
    title: "Manufacturing License",
    authority: "Drugs Control Administration Government of Telangana",
  },
  {
    title: "Schedule “M” GMP",
    authority: "Drugs Control Administration Government of Telangana",
  },
  {
    title: "WHO GMP",
    authority: "Drugs Control Administration Government of Telangana",
  },
  {
    title: "ISO 9001:2015",
    authority: "DBS Certifications Pvt. Ltd.",
  },
  {
    title: "ISO 14001:2015",
    authority: "DBS Certifications Pvt. Ltd.",
  },
  {
    title: "ISO 45001:2018",
    authority: "DBS Certifications Pvt. Ltd.",
  },
  {
    title: "ISO/IEC 27001:2022",
    authority: "DBS Certifications Pvt. Ltd.",
  },
  {
    title: "ISO 50001:2018",
    authority: "DBS Certifications Pvt. Ltd.",
  },

  {
    title: "MFDS (FORMER KFDA)",
    authority: "KOREAN REGULATORY",
  },
  {
    title: "Japanese Accreditation for Non-sterile drugs",
    authority: "Ministry of Health, Labor and Welfare",
  },
  {
    title: "EIR Letter",
    authority: "USFDA",
  },

  {
    title: "China Food & Drug Administration",
    authority: "SFDA China",
  },
  {
    title: "EU Written confirmation for API",
    authority:
      "Central Drugs Standard Control Organization, Government of India",
  },
  {
    title: "Ministry of Health of Ukraine",
    authority: "Ministry of Health of Ukraine",
  },

  {
    title: "ANVISA Certificate",
    authority: "Ministry of Health National Health Surveillance Agency",
  },
  {
    title: "Certificate of Suitability",
    authority: "EDQM",
  },

  {
    title: "ANSM GMP Certificate",
    authority: "National Agency for the Safety and Health Products",
  },
  {
    title: "Mexican GMP",
    authority:
      "ESTADOS UNIDOS MEXICANOS COMISION FEDERAL PARA LA PROTECCION CONTRA RIESGOS SANITARIOS COMISION DE AUTORIZATION SANITARIA",
  },
  {
    title: " Ecovadis bronze medal",
    authority: "Ecovadis",
  },
];

const Achivements = () => {
  useEffect(() => {
    document.title = 'Achievements | Virupaksha Organics Ltd';
    updateMeta("description", "Explore the achievements of Virupaksha Organics Ltd, showcasing our milestones and successes in the pharmaceutical and fine chemical industry.");
    updateMeta("keywords", "Virupaksha Organics Ltd, achievements, company milestones, industry awards, pharmaceutical success, chemical industry accomplishments");
  }, []);

  return (
    <div>
      <div
        className={`relative h-70vh lg:h-60vh md:h-50vh bg-targets bg-center bg-cover bg-no-repeat`}
      >
        <div className="h-30vh md:h-25vh lg:h-30vh"></div>
        <div className="absolute  w-full">
          <div className="w-full h-full px-3">
            <h1 className="text-green text-center font-AuthorBold text-2xl md:text-4xl">
              Achievements
            </h1>

            <p className="mx-auto w-full px-2 mt-2 md:w-11/12 text-center   lg:px-0 lg:w-6/12 text-white text-lg md:text-xl font-AuthorThin">
              Dedicated to advancing healthcare through innovation, research,
              and a steadfast commitment to improving the quality of life for
              people around the world.
            </p>
          </div>
        </div>
      </div>

      <div className=" px-4 py-10 md:py-16 lg:px-24">
        <p className="text-green text-lg lg:text-xl text-center  font-AuthorSemiBold uppercase">
          Achievements
        </p>
        <p className="text-2xl w-8/12 md:text-2xl lg:text-3xl font-AuthorBold text-center lg:w-6/12 mx-auto leading-tight text-lightBlue">
          List of Certifications with{" "}
          <span className="text-green">Issue Authority</span>
        </p>
      </div>

      <div className="px-4  -mt-8 lg:-mt-4 md:px-10 lg:px-24">
        <div className="product_bg grid grid-cols-12 gap-2 items-center  rounded-t-xl p-4">
          {["S.NO", "Name of Certificate", "Issuing Authority"].map(
            (item, index) => (
              <div
                key={index}
                className={` text-white text-lg font-AuthorBold  font-600 lg:text-2xl ${
                  index === 0
                    ? "col-span-2 lg:col-span-1"
                    : index === 1
                    ? "col-span-4 lg:col-span-4"
                    : "col-span-4 lg:col-span-7"
                }`}
              >
                {item}
              </div>
            )
          )}
        </div>

        {CertificateData?.map((item, index) => (
          <>
            <div
              key={index}
              className={`w-full  grid grid-cols-12  justify-center p-3 lg:p-6 gap-2 bg-blueNew bg-opacity-20 ${
                index === CertificateData.length - 1 ? "rounded-b-xl" : ""
              }`}
            >
              <div className="col-span-2 lg:col-span-1 text-lightBlue text-sm lg:text-lg md:text-base font-semibold">
                {index + 1}.
              </div>
              <div className="col-span-4 lg:col-span-4 text-lightBlue text-sm lg:text-lg md:text-base font-semibold">
                {item?.title}
              </div>
              <div className="col-span-6 lg:col-span-7 text-lightBlue text-sm md:text-base lg:text-lg font-semibold">
                {item?.authority}
              </div>
            </div>
            <div className="h-2px"></div>
          </>
        ))}
      </div>

      <div className="pb-10">
        <HowWeAreRaising />
      </div>
    </div>
  );
};

export default Achivements;
