import React, { Component } from "react";
import api from "../../../api/index";
import { Link } from "react-router-dom";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import SideMenu from "../../components/SideMenu";
import Moment from "moment";

class JobAdminViewDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      application: [],
      isDiectView: false,
    };
  }

  componentDidMount() {
    let checkPath = this.props.match.url;
    if (checkPath.search("applicants-list/") === -1) {
      this.setState({ isDiectView: true });
    }
    this.getApplicationById();
  }

  getApplicationById = () => {
    api
      .get(`/admin/applications/` + this.state.id)
      .then((res) => {
        this.setState({ application: res.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getUrlName(url = "") {
    let currentUrl = url.split(".com/");
    return currentUrl[1];
  }

  render() {
    return (
      <div className="flex bg-gray-100 admin bg-adminbg">
        <SideMenu></SideMenu>
        <div className="flex flex-col w-full h-screen overflow-y-hidden">
          <AdminHeader></AdminHeader>
          <div className="flex flex-col w-full overflow-x-hidden border-t border-ThemeLightBlue">
            <main className="flex-grow w-full p-6 ">
              <div>
                {this.state.isDiectView ? (
                  <div className="flex items-center breadcrumbs">
                    <Link to="/admin/job-list" className="text-base text-blue">
                      Job List{" "}
                    </Link>{" "}
                    <svg
                      className="mx-3"
                      width="8"
                      height="12"
                      viewBox="0 0 13 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 18.25L10.75 10.125L2 2"
                        stroke="#5F7995"
                        strokeWidth="1.875"
                        strokeLinecap="square"
                      />
                    </svg>
                    <Link
                      to={"/admin/job-list/" + 1}
                      className="text-base text-blue"
                    >
                      View Job info
                    </Link>
                    <svg
                      className="mx-3"
                      width="8"
                      height="12"
                      viewBox="0 0 13 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 18.25L10.75 10.125L2 2"
                        stroke="#5F7995"
                        strokeWidth="1.875"
                        strokeLinecap="square"
                      />
                    </svg>
                    <span className="text-base text-ThemeBlue font-WavehausBold">
                      View Candidate Info
                    </span>
                  </div>
                ) : (
                  <div className="flex items-center breadcrumbs">
                    <Link
                      to="/admin/applicants-list"
                      className="text-base text-blue"
                    >
                      Applications Submitted{" "}
                    </Link>{" "}
                    <svg
                      className="mx-3"
                      width="8"
                      height="12"
                      viewBox="0 0 13 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 18.25L10.75 10.125L2 2"
                        stroke="#5F7995"
                        strokeWidth="1.875"
                        strokeLinecap="square"
                      />
                    </svg>
                    <span className="text-base text-ThemeBlue font-WavehausBold">
                      View Job Application
                    </span>
                  </div>
                )}

                <hr className="mt-2 border-borderColor" />

                <div className="w-full pr-0 my-6 lg:pr-2">
                  <div className="px-6 bg-white border rounded-lg shadow-xl py-7 border-borderBlue">
                    <h4 className="pb-2 text-base border-b border-gray text-ThemeBlue font-WavehausBold">
                      Profile Details
                    </h4>
                    <div className="flex items-center items-baseline justify-between mt-8 mb-4">
                      <div>
                        <div>
                          <p className="text-blue">Job Role:</p>
                          <p className="text-ThemeBlue font-WavehausSemiBold">
                            {this.state.application.job_name}
                          </p>
                        </div>

                        <div className="mt-4">
                          <p className="text-blue">Applied date:</p>
                          <p className="text-ThemeBlue font-WavehausSemiBold">
                            {Moment(this.state.application.created_at).format(
                              "d MMM YYYY"
                            )}
                          </p>
                        </div>
                        <div className="mt-4">
                          <p className="text-blue">Applied Role:</p>
                          <p className="text-ThemeBlue font-WavehausSemiBold">
                            {this.state.application.job_role}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <p className="text-blue">Name:</p>
                          <p className="text-ThemeBlue font-WavehausSemiBold">
                            {this.state.application.name}
                          </p>
                        </div>

                        <div className="mt-4">
                          <p className="text-blue">Willing to relocate?</p>
                          <p className="text-ThemeBlue font-WavehausSemiBold">
                            {this.state.application.is_relocate === 1
                              ? "YES"
                              : "NO"}
                          </p>
                        </div>
                        <div className="mt-4 ">
                          <p className="text-blue">Resume:</p>
                          <div className="p-4 mt-2 border text-ThemeBlue border-bglight rounded-xl bg-bglight font-WavehausSemiBold">
                            <svg
                              className="inline-block"
                              width="14"
                              height="15"
                              viewBox="0 0 14 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 4.5V0H1V4.5C1 5.32843 1.67157 6 2.5 6C3.32843 6 4 5.32843 4 4.5V1.5C4 1.22386 3.77614 1 3.5 1C3.22386 1 3 1.22386 3 1.5V5H2V1.5C2 0.671573 2.67157 0 3.5 0C4.32843 0 5 0.671573 5 1.5V4.5C5 5.88071 3.88071 7 2.5 7C1.11929 7 0 5.88071 0 4.5Z"
                                fill="#4EE287"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12.5 0H6V4.5C6 6.433 4.433 8 2.5 8H1V13.5C1 14.3284 1.67157 15 2.5 15H12.5C13.3284 15 14 14.3284 14 13.5V1.5C14 0.671573 13.3284 0 12.5 0ZM11 4H7V5H11V4ZM11 7H7V8H11V7ZM4 10H11V11H4V10Z"
                                fill="#4EE287"
                              />
                            </svg>
                            <span className="ml-2">
                              {this.getUrlName(
                                this.state.application.resume_url
                              )}
                            </span>
                            <hr className="my-2 border-gray" />
                            <div className="flex justify-between ">
                              <span>1 mb</span>

                              <a
							    target="_blank"
                                href={`${
                                  process.env.REACT_APP_BASE_URL ||
                                  "https://api-virupaksha.scube.me/"
                                }${this.state.application.resume_url}`}
                                className="border-b green border-green"
                              >
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <p className="text-blue">Email ID:</p>
                          <p className="text-ThemeBlue font-WavehausSemiBold">
                            {this.state.application.email}
                          </p>
                        </div>

                        <div className="mt-4">
                          <p className="text-blue">
                            How did he hear about Virupaksha?
                          </p>
                          <p className="text-ThemeBlue font-WavehausSemiBold">
                            {this.state.application.job_source_type}
                          </p>
                        </div>
                        <div className="mt-4">&nbsp;</div>
                      </div>
                      <div>
                        <div>
                          <p className="text-blue">Phone Number:</p>
                          <p className="text-ThemeBlue font-WavehausSemiBold">
                            {this.state.application.phone}
                          </p>
                        </div>

                        <div className="mt-4">
                          <p className="text-blue">How soon can you join?</p>
                          <p className="text-ThemeBlue font-WavehausSemiBold">
                            {this.state.application.join_in_days_type}
                          </p>
                        </div>
                        <div className="mt-4">&nbsp;</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

export default JobAdminViewDetails;
