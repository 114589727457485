export const list = [
  {
    name: "HOME",
    url: "/",
    dropdown: false,
    isActive: false,
    subList: [],
    slug: "",
  },

  {
    name: "ABOUT US",
    url: "/about-us",
    dropdown: false,
    isActive: false,
    slug: "about-us",
    subList: [
      {
        name: "About",
        subLink: "/about-us",
        isActive: false,
      },
      {
        name: "Board Members",
        subLink: "/about-us/board-members",
        isActive: false,
      },
      {
        name: "Achievements",
        subLink: "/about-us/achievements",
        isActive: false,
      },
      {
        name: "Future Targets & Goals",
        subLink: "/about-us/future-targets",
        isActive: false,
      },
      {
        name: "Facilities",
        subLink: "/about-us/facilities",
        isActive: false,
      },
    ],
  },

  {
    name: "PRODUCTS",
    url: "/products/active-pharmaceutical-ingredients",
    dropdown: false,
    isActive: false,
    slug: "products",
    subList: [
      {
        name: "Active Pharmaceutical Ingredients",
        subLink: "/products/active-pharmaceutical-ingredients",
        isActive: false,
      },
      {
        name: "Intermediates",
        subLink: "/products/intermediates",
        isActive: false,
      },
    ],
  },

  {
    name: "SUSTAINABILITY & ESG",
    url: "/sustainability",
    slug: "sustainability",
    dropdown: true,
    isActive: false,
    subList: [
      {
        name: "IMS Policy",
        url: `https://api.virupaksha.com/public/IMS_Policy.pdf`,
      },
      {
        name: "Human Rights Policy",
        url: "https://api.virupaksha.com/public/human_rights_policy.pdf",
      },
      {
        name: "Anti-Money Laundering Policy",
        url: "https://api.virupaksha.com/public/anti_money_laundering_policy.pdf",
      },
      {
        name: "Responsible Marketing Policy",
        url: "https://api.virupaksha.com/public/responsible_ marketing_policy.pdf",
      },
      {
        name: "Sustainable Procurement Policy",
        url: "https://api.virupaksha.com/public/sustainable_procurement_policy.pdf",
      },
      {
        name: "CSR Policy",
        url: "https://api.virupaksha.com/public/CSR_Policy.pdf",
      },
      {
        name: "CSR Annual Report",
        url: "https://api.virupaksha.com/public/CSR_Annual_report.pdf",
      },
      {
        name: "Whistle Blower Policy",
        url: "https://api.virupaksha.com/public/whistle_blower_policy.pdf",
      },
      {
        name: "Customer Safety Policy",
        url: "https://api.virupaksha.com/public/Customer_Safety_Policy.pdf",
      },
      {
        name: "Bio Diversity Policy",
        url: "https://api.virupaksha.com/public/Biodiversity_Policy.pdf",
      },
      {
        name: "Conflict Minerals Policy",
        url: "https://api.virupaksha.com/public/Conflict_Minerals_Policy.pdf",
      },
      {
        name: "Water & Energy Conservation Policy",
        url: "https://api.virupaksha.com/public/Water_Energy_Conservation_Policy.pdf",
      },
      {
        name: "Supplier Diversity Policy",
        url: "https://api.virupaksha.com/public/Supplier_Diversity_Policy.pdf",
      },
      {
        name: "Fair Competition Policy",
        url: "https://api.virupaksha.com/public/Fair_competition_Policy.pdf",
      },
    ],
  },

  {
    name: "CONTACT",
    url: "/contact-us",
    slug: "contact-us",
    dropdown: false,
    isActive: false,
    subList: [
      {
        name: "",
        subLink: "",
        isActive: false,
      },
    ],
  },
];

export const mobileList = [
  {
    name: "ABOUT US",
    url: "/about-us",
    slug: "about-us",
    dropdown: false,
    isActive: false,
    subList: [
      {
        name: "About",
        subLink: "/about-us",
        isActive: false,
      },
      {
        name: "Board Members",
        subLink: "/about-us/board-members",
        isActive: false,
      },
      {
        name: "Achievements",
        subLink: "/about-us/achievements",
        isActive: false,
      },
      {
        name: "Future Targets & Goals",
        subLink: "/about-us/future-targets",
        isActive: false,
      },
      {
        name: "Facilities",
        subLink: "/about-us/facilities",
        isActive: false,
      },
    ],
  },

  {
    name: "PRODUCTS",
    url: "/products/active-pharmaceutical-ingredients",
    slug: "products",
    dropdown: false,
    isActive: false,
    subList: [
      {
        name: "Active Pharmaceutical Ingredients",
        subLink: "/products/active-pharmaceutical-ingredients",
        isActive: false,
      },
      {
        name: "Intermediates",
        subLink: "/products/intermediates",
        isActive: false,
      },
    ],
  },

  // {
  //   name: "SUSTAINABILITY & ESG",
  //   url: "/sustainability",
  //   dropdown: true,
  //   isActive: false,
  //   slug: "sustainability",
  //   subList: [
  //     {
  //       name: "IMS Policy",
  //       url: `https://api.virupaksha.com/public/IMS_Policy.pdf`,
  //     },
  //     {
  //       name: "Human Rights Policy",
  //       url: "https://api.virupaksha.com/public/human_rights_policy.pdf",
  //     },
  //     {
  //       name: "Anti-Money Laundering Policy",
  //       url: "https://api.virupaksha.com/public/anti_money_laundering_policy.pdf",
  //     },
  //     {
  //       name: "Responsible Marketing Policy",
  //       url: "https://api.virupaksha.com/public/responsible_ marketing_policy.pdf",
  //     },
  //     {
  //       name: "Sustainable Procurement Policy",
  //       url: "https://api.virupaksha.com/public/sustainable_procurement_policy.pdf",
  //     },
  //     {
  //       name: "CSR Policy",
  //       url: "https://api.virupaksha.com/public/CSR_Policy.pdf",
  //     },
  //     {
  //       name: "CSR Annual Report",
  //       url: "https://api.virupaksha.com/public/CSR_Annual_report.pdf",
  //     },
  //     {
  //       name: "Whistle Blower Policy",
  //       url: "https://api.virupaksha.com/public/whistle_blower_policy.pdf",
  //     },
  //     {
  //       name: "Customer Safety Policy",
  //       url: "https://api.virupaksha.com/public/Customer_Safety_Policy.pdf",
  //     },
  //     {
  //       name: "Bio Diversity Policy",
  //       url: "https://api.virupaksha.com/public/Biodiversity_Policy.pdf",
  //     },
  //     {
  //       name: "Conflict Minerals Policy",
  //       url: "https://api.virupaksha.com/public/Conflict_Minerals_Policy.pdf",
  //     },
  //     {
  //       name: "Water & Energy Conservation Policy",
  //       url: "https://api.virupaksha.com/public/Water_Energy_Conservation_Policy.pdf",
  //     },
  //     {
  //       name: "Supplier Diversity Policy",
  //       url: "https://api.virupaksha.com/public/Supplier_Diversity_Policy.pdf",
  //     },
  //     {
  //       name: "Fair Competition Policy",
  //       url: "https://api.virupaksha.com/public/Fair_competition_Policy.pdf",
  //     },
  //   ],
  // },
];

export const sustainability = [
  {
    name: "IMS Policy",
    url: `https://api.virupaksha.com/public/IMS_Policy.pdf`,
  },
  {
    name: "Human Rights Policy",
    url: "https://api.virupaksha.com/public/human_rights_policy.pdf",
  },
  {
    name: "Anti-Money Laundering Policy",
    url: "https://api.virupaksha.com/public/anti_money_laundering_policy.pdf",
  },
  {
    name: "Responsible Marketing Policy",
    url: "https://api.virupaksha.com/public/responsible_ marketing_policy.pdf",
  },
  {
    name: "Sustainable Procurement Policy",
    url: "https://api.virupaksha.com/public/sustainable_procurement_policy.pdf",
  },
  {
    name: "CSR Policy",
    url: "https://api.virupaksha.com/public/CSR_Policy.pdf",
  },
  {
    name: "CSR Annual Report",
    url: "https://api.virupaksha.com/public/CSR_Annual_report.pdf",
  },
  {
    name: "Whistle Blower Policy",
    url: "https://api.virupaksha.com/public/whistle_blower_policy.pdf",
  },
  {
    name: "Customer Safety Policy",
    url: "https://api.virupaksha.com/public/Customer_Safety_Policy.pdf",
  },
  {
    name: "Bio Diversity Policy",
    url: "https://api.virupaksha.com/public/Biodiversity_Policy.pdf",
  },
  {
    name: "Conflict Minerals Policy",
    url: "https://api.virupaksha.com/public/Conflict_Minerals_Policy.pdf",
  },
  {
    name: "Water & Energy Conservation Policy",
    url: "https://api.virupaksha.com/public/Water_Energy_Conservation_Policy.pdf",
  },
  {
    name: "Supplier Diversity Policy",
    url: "https://api.virupaksha.com/public/Supplier_Diversity_Policy.pdf",
  },
  {
    name: "Fair Competition Policy",
    url: "https://api.virupaksha.com/public/Fair_competition_Policy.pdf",
  },
];
