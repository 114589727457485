import React, { useEffect, useState } from "react";
import gsap from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { cards, whyToChooseUsContent, whyToChooseUsIcons } from "./helpers";

const WhyToChooseUs = () => {
  const cardStyles = {
    borderRadius: "12px",
  };

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (window.innerWidth > 1024) {
      gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
      let ctx = gsap.context(() => {
        gsap.set(".animateCard", {
          zIndex: (i, target, targets) => targets.length - i,
        });
        let cards = gsap.utils.toArray(".animateCard:not(.orange)");
        const hideMeElement = document.querySelector(".hide-me");

        const hideElement = (element) => {
          gsap.to(element, { opacity: 0, duration: 0 });
        };
        const visibleElement = (element) => {
          gsap.to(element, { opacity: 1, duration: 0 });
        };

        gsap.timeline({
          scrollTrigger: {
            trigger: ".whychooseAnimate",
            start: "top bottom",
            end: "top 20%",
            // snap: 1,
            snap: {
              snapTo: 1,
              duration: 1,
              delay: 0.1,
              ease: "power1.inOut",
            },

            duration: 1,
            scrub: true,
          },
        });

        var tl = gsap.timeline({
          scrollTrigger: {
            trigger: ".cardContainer",
            start: "top 20%",

            end: () => "+=" + (cards.length + 2) * 400,

            id: "Pin",
            pin: true,
            snap: {
              snapTo: 1 / 3,
              duration: 1,
              delay: 0.1,
              ease: "power1.inOut",
            },
            scrub: 1,
            duration: 1,
            onUpdate: (self) => {
              //forward

              if (self.direction >= 0) {
                if (self.progress > 0.01 && self.progress <= 0.25) {
                  hideElement(hideMeElement);
                }
                if (self.progress > 0.3 && self.progress <= 0.33) {
                  setActiveIndex(1);
                  visibleElement(hideMeElement);
                }

                if (self.progress > 0.34 && self.progress <= 0.5) {
                  hideElement(hideMeElement);
                }

                if (self.progress > 0.6 && self.progress <= 0.66) {
                  setActiveIndex(2);

                  visibleElement(hideMeElement);
                }

                if (self.progress > 0.67 && self.progress <= 0.99) {
                  hideElement(hideMeElement);
                }

                if (self.progress > 0.9 && self.progress <= 1) {
                  setActiveIndex(3);
                  visibleElement(hideMeElement);
                }
              }

              //backward
              if (self.direction <= 0) {
                if (self.progress >= 0.98) {
                  hideElement(hideMeElement);
                } else if (self.progress >= 0.66 && self.progress <= 0.7) {
                  setActiveIndex(2);
                  visibleElement(hideMeElement);
                } else if (self.progress >= 0.58) {
                  hideElement(hideMeElement);
                } else if (self.progress >= 0.33 && self.progress <= 0.4) {
                  setActiveIndex(1);
                  visibleElement(hideMeElement);
                } else if (self.progress >= 0.2) {
                  hideElement(hideMeElement);
                } else if (self.progress >= 0 && self.progress <= 0.1) {
                  setActiveIndex(0);
                  visibleElement(hideMeElement);
                }
              }
            },
          },
        });
      });
      return () => ctx.revert();
    }
  }, []);
  return (
    <>
      <div className="whychooseAnimate">
        <div className="hidden  3xl:py-4 cardContainer  lg:grid grid-cols-2 gap-2 rounded-xl bg-paleSubtleBlue">
          <div className="h-full w-full flex items-center justify-center p-4">
            <div className="my-auto border border-dashed ring1 rounded-full circle-spacing w-500px  h-500px 3xl:h-600px 3xl:w-600px bg-transparent flex items-center justify-center animation-frame">
              <div className="circle-spacing border border-dashed  ring2 rounded-full w-400px h-400px 3xl:h-500px 3xl:w-500px bg-transparent flex items-center justify-center ">
                <div className="circle-spacing border border-dashed ring3 rounded-full w-300px h-300px 3xl:h-400px 3xl:w-400px bg-transparent flex items-center justify-center relative">
                  {React.Children.toArray(
                    whyToChooseUsIcons.map((item, index) => (
                      <img
                        src={
                          activeIndex === index ? item.activeIcon : item.icon
                        }
                        alt={item.icon}
                        className={`w-[45px] h-[45px] ${item.position} ${
                          activeIndex === index
                            ? "scaleIcon transition-all duration-500"
                            : "scale-100 transition-all duration-500"
                        }`}
                      />
                    ))
                  )}

                  <div className="circle-spacing border  border-dashed ring4 rounded-full w-200px h-200px 3xl:h-300px 3xl:w-300px bg-transparent flex items-center justify-center"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex pb-10 gap-6">
            <div className="h-full flex flex-col gap-2 justify-end">
              {React.Children.toArray(
                [0, 1, 2, 3].map((item, index) => (
                  <div
                    className={`w-1 h-6vh rounded-lg transition-all duration-500 ${
                      activeIndex === index ? "bg-green" : " bg-lightGreenish"
                    }`}
                  />
                ))
              )}
            </div>
            <div className="w-full h-full grid grid-cols-1 pb-2">
              <div className="w-full flex flex-col  justify-end ">
                <p className="text-green uppercase sub-heading1">
                  Why Choose us
                </p>
                <p className="title1 leading-tight text-lightBlue">
                  Delivering Excellence in <br /> Pharmaceutical Manufacturing
                </p>
                <p className="text-lg text-lightBlue">
                  Trust us for pharma excellence: quality, expertise,
                  customer-centricity.
                </p>
              </div>
              <div className=" flex flex-col justify-end w-full h-full">
                <div
                  className="animateCard product_bg  green h-25vh w-80% p-6"
                  style={cardStyles}
                >
                  <div className="transition-all duration-1000 hide-me">
                    <p className="text-white heading1">
                      {whyToChooseUsContent[activeIndex].title}
                    </p>
                    <p className="text-white mt-1 font-AuthorThin text-xl leading-tight">
                      {whyToChooseUsContent[activeIndex].description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:hidden">
        <div className="w-full">
          <div className="bg-paleSubtleBlue shadow-xl w-full flex lg:flex-row flex-col lg:justify-between justify-center items-center">
            <div className="w-50% p-5 lg:block hidden">
              <div className="w-full rounded-lg h-full"></div>
            </div>

            <div className="lg:p-10 p-5 flex flex-col gap-3 w-full">
              <p className="text-green uppercase  font-AuthorSemiBold">
                Why Choose us
              </p>
              <h2 className=" text-lightBlue text-2xl font-AuthorSemiBold leading-tight">
                Delivering Excellence in Pharmaceutical Manufacturing
              </h2>

              <p className="text-lightBlue font-AuthorThin text-xl">
                Trust us for pharma excellence: quality, expertise, customer-{" "}
                centricity.
              </p>

              <div className="choose-card w-full lg:p-10 p-5 rounded-lg lg:flex hidden flex-col items-start justify-center">
                <p className="text-white body-content1">Regulatory Approvals</p>

                <p className="body-content1 text-white">
                  Following the ICH guidelines have become bread and butter for
                  our employees.
                </p>
              </div>

              <div className="flex flex-wrap flex-1 gap-5 mt-5">
                {React.Children.toArray(
                  cards.map((item, index) => (
                    <div
                      key={index}
                      className="product_bg  rounded-lg p-5 flex flex-grow w-32 items-center gap-4 flex-col justify-center"
                    >
                      <img src={item.icon} alt={item.icon} />
                      <p className="text-center font-AuthorSemiBold text-white text-xs font-medium">
                        {[0, 2].includes(index) ? (
                          <>
                            {item?.title?.split(" ")[0]} <br />
                            {item?.title?.split(" ")[1]}{" "}
                          </>
                        ) : (
                          <>{item?.title}</>
                        )}
                      </p>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default WhyToChooseUs;
