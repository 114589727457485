import TextField from "@mui/material/TextField";
import React from "react";

 const TextInput = ({
  success,
  rows,
  height,
  width,
  type,
  value,
  label,
  error,
  helperText,
  readOnly,
  disabled,
  handleChange,
  name,
  bgcolor,
}) => (
  <div className={`${width}`}>
    <TextField
      onWheel={(e) => e.target?.blur()}
      autoComplete="off"
      type={type}
      rows={rows}
      name={name}
      fullWidth
      onChange={(e) => {
        handleChange(e);
      }}
      disabled={disabled}
      inputProps={{ readOnly, autoComplete: "off" }}
      error={error}
      helperText={helperText}
      sx={{
        "& .MuiInputBase-input": {
          color: "#232E69",
          fontFamily: "Author thin",
        },
        "& .MuiOutlinedInput-notchedOutline": {},
        backgroundColor: bgcolor || "transparent",

        textarea: { color: "black", height },

        "& .MuiFormLabel-root.Mui-hovered": {
          color: "red",
        },
        "& .MuiFormLabel-root.Mui-focused": {
          borderColor: "#8A9BAF",
          color: "#232E69",
        },
        "& .MuiFormLabel-root.Mui-hover": {
          color: "red",
        },
        "& .MuiOutlinedInput-root:hover": {
          "& > fieldset": {
            color: "red",
            borderColor: "#8A9BAF",
          },
        },
        "& .MuiOutlinedInput-root": {
          backgroundColor: bgcolor ?? "white",
          borderRadius: "8px",

          "& > fieldset": {
            borderColor: value ? "#8A9BAF" : success ? "#3AC430" : "#3d577e33",
          },
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          "& > fieldset": {
            borderColor: "#232E69",
          },
        },

        "& .MuiFormLabel-root": {
          fontFamily: "Author thin",
        },
      }}
      variant="outlined"
      value={value}
      label={label}
    />
  </div>
);

export default TextInput 
