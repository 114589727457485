import React, { Fragment } from "react";

const Button = (props) => {
  return (
    <Fragment>
      {props.variant === "outlined" ? (
        <button
          disabled={props.loading}
          onClick={props.onClick}
          className={`${
            props.class
          } hover:bg-lightBlue hover:text-white outlined-btn flex items-center justify-center  text-sm font-medium capitalize w-full h-12 ${
            props.loading
              ? "cursor-not-allowed bg-gray"
              : "cursor-pointer "
          }`}
        >
          {props.children}
        </button>
      ) : props.variant === "contained" ? (
       

        <button
          disabled={props.loading}
          onClick={props.onClick}
          className={`${
            props.class
          }  text-sm flex rounded-lg items-center justify-center text-white font-Authorbold capitalize w-full h-12 ${
            props.loading
              ? "cursor-not-allowed bg-gray"
              : "cursor-pointer bg-ThemeBlue "
          }`}
        >
          {props.children}
        </button>
      ) : (
        <button
          disabled={props.loading}
          onClick={props.onClick}
          className={`${props.class} know-more-btn flex items-center justify-center text-sm font-medium capitalize w-full h-12`}
        >
          {props.children}
        </button>
      )}
    </Fragment>
  );
};

export default Button;
