export const productList = [
  {
    url: "4-1-hydroxy-4-4-hydroxydiphenylmethyl-1-piperidinyl-butyl-dimethylbenzeneacetic-acid-hydrochloride",
    title:
      "Fexofenadine HCl, Cas No. 83799-24-0 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Fexofenadine HCl the appearance of Fexofenadine HCl is White crystalline powder and Molecular Weight is 538.",
    keywords:
      "Fexofenadine HCl manufacturers, Fexofenadine HCl suppliers, Fexofenadine HCl exporters, Fexofenadine HCl in india, Cas No. 83799-24-0, 4-[1-Hydroxy-4-[4-(hydroxydiphenylmethyl)-1-piperidinyl] butyl]-α,α-dimethylbenzeneacetic acid hydrochloride",
    imageAlt: "Fexofenadine HCl",
  },
  {
    url: "24-difluorophenyl-1h-124-triazole-1-ylmethyl-1h-124-triazole-1-ethanol",
    title:
      "Fluconazole, Cas No. 86386-73-4 Manufacturers, Suppliers, Exporters in india",
    description:
      " We are the manufacturers of Fluconazole the appearance of Fluconazole and Molecular Weight is 306.27",
    keywords:
      "Fluconazole manufacturers, Fluconazole suppliers, Fluconazole exporters, Fluconazole in india, Cas No. 86386-73-4, α-(2,4-Difluorophenyl)-α-(1H-1,2,4-triazole-1-ylmethyl)-1H-1,2,4-triazole-1-ethanol",
    imageAlt: "Fluconazole",
  },
  {
    url: "1r2r-2-dimethylaminomethyl-1-3-methoxyphenylcyclohexan-1-ol",
    title:
      "Tramadol HCI, Cas No. 27203-92-5 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Tramadol HCI the appearance of Tramadol HCI is White to off white powder and Molecular Weight is151.93",
    keywords:
      "Tramadol HCI manufacturers, Tramadol HCI suppliers, Tramadol HCI exporters, Tramadol HCI in india, Cas No. 27203-92-5, (1R,2R)-2-[(dimethylamino)methyl]-1-(3-methoxyphenyl)cyclohexan-1-ol.",
    imageAlt: "Tramadol HCI",
  },
  {
    url: "1-3-dimethylaminopropyl-1-4-fluorophenyl-13-dihydro-s-5-isobenzofurancarbonitrile-oxalate",
    title:
      "Escitalopram Oxalate, Cas No. 219861-08-2 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Escitalopram Oxalate the appearance of Oxalate is White or almost white, crystalline powder. and Molecular Weight is 414.39",
    keywords:
      "Escitalopram Oxalate manufacturers, Escitalopram Oxalate suppliers, Escitalopram Oxalate exporters, Escitalopram Oxalate in india, Cas No. 219861-08-2, 1-[3-(Dimethylamino)propyl]-1-(4-fluorophenyl)-1,3-dihydro-S-(+)-5-isobenzofurancarbonitrile oxalate.",
    imageAlt: "Escitalopram Oxalate",
  },
  {
    url: "1-3-dimethylamino-propyl-1-p-fluorophenyl-5-phthalancarbonitrilehydrobromide",
    title:
      "Citalopram Hbr, Cas No. 59729-32-7 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Citalopram Hbr the appearance of Citalopram Hbr is White or almost white, crystalline powder. and Molecular Weight is 405.39.",
    keywords:
      "Citalopram Hbr manufacturers, Citalopram Hbr suppliers, Citalopram Hbr exporters, Citalopram Hbr in india, Cas No. 59729-32-7, 1-[3-(Dimethylamino)-propyl]-1-(p-fluorophenyl)-5-phthalancarbonitrile.Hydrobromide",
    imageAlt: "Citalopram Hbr",
  },
  {
    url: "benzonitrile-4-13-dihydro-1-hydroxy-21-benzoxaborol-5-yloxy",
    title:
      "Crisaborole, Cas No. 906673-24-3 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Crisaborole the appearance of Crisaborole is White to off white powder. and Molecular Weight is 281.05",
    keywords:
      "Crisaborole manufacturers, Crisaborole suppliers, Crisaborole exporters, Crisaborole in india, Cas No. 906673-24-3, Benzonitrile, 4-[(1,3-dihydro-1-hydroxy-2,1-benzoxaborol-5-yl)oxy]",
    imageAlt: "Crisaborole",
  },
  {
    url: "rr-24-difluorophenyl-methyl-4-methylene-1h-124-triazol-1-ylmethyl-1-piperidineethanol",
    title:
      "Efinaconazole, Cas No. 164650-44-6 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Efinaconazole the appearance of Efinaconazole is White to off white colour powder. and Molecular Weight is 348.39.",
    keywords:
      "Efinaconazole manufacturers, Efinaconazole suppliers, Efinaconazole exporters, Efinaconazole in india, Cas No. 164650-44-6, (αR,βR)-α-(2,4-Difluorophenyl)-β-methyl-4-methylene-α-(1H-1,2,4-triazol-1-ylmethyl)-1-piperidineethanol",
    imageAlt: "Efinaconazole",
  },
  {
    url: "2-4-2-4-1-2-ethoxyethyl-1h-benzimidazol-2-yl-1-piperidinylethylphenyl-2-methylpropanoic-acid",
    title:
      "Bilastine, Cas No. 202189-78-4 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Bilastine the appearance of Bilastine is  White to off white crystalline powder and Molecular Weight is 463.62.",
    keywords:
      "Bilastine manufacturers, Bilastine suppliers, Bilastine exporters, Bilastine in india, Cas No. 202189-78-4, 2-[-4-(2-[4-[1-(2-Ethoxyethyl)-1H-benzimidazol-2-yl]-1-piperidinyl]ethyl)phenyl]-2-methylpropanoic acid",
    imageAlt: "Bilastine",
  },
  {
    url: "5-fluoro-13-dihydro-1-hydroxy-21-benzoxaborole",
    title:
      "Tavaborole, Cas No. 174671-46-6 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Tavaborole the appearance of Tavaborole is White to off white powder and Molecular Weight is 151.93.",
    keywords:
      "Tavaborole manufacturers, Tavaborole suppliers, Tavaborole exporters, Tavaborole in india, Cas No. 174671-46-6, 5-Fluoro-1,3-dihydro-1-hydroxy-2,1-benzoxaborole",
    imageAlt: "Tavaborole",
  },
  {
    url: "bis4-2-tert-butylamino-1-hydroxyethyl-2-hydroxymethylphenol-sulfuric-acid",
    title:
      "Salbutamol Sulphate, Cas No. 51022-70-9 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Salbutamol Sulphate  the appearance of Salbutamol Sulphate is White or almost white, crystalline powder and Molecular Weight is 576.7.",
    keywords:
      "Salbutamol Sulphate manufacturers, Salbutamol Sulphate suppliers, Salbutamol Sulphate exporters, Salbutamol Sulphate in india, Cas No. 51022-70-9, bis(4-[2-(tert-butylamino)-1-hydroxyethyl]-2-(hydroxymethyl)phenol); sulfuric acid",
    imageAlt: "Salbutamol Sulphate",
  },

  {
    url: "3-1r-2r-3-dimethylamino-1-ethyl-2-methylpropylphenol-monohydrochloride",
    title:
      "Tapentadol, Cas No. 175591-09-0 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Tapentadol. The appearance of Tapentadol is Off-white to pale yellow color solid. Molecular Weight is 257.84.",
    keywords:
      "Tapentadol manufacturers, Tapentadol suppliers, Tapentadol exporters, Tapentadol in india, Cas No. 175591-09-0, 3-[(1R, 2R)-3-(dimethylamino)-1-ethyl-2-methylpropyl]phenol monohydrochloride",
    imageAlt: "Tapentadol",
  },
  {
    url: "2r3r4r5s-6-methylaminohexane-12345-pentaol-2-35-dichlorophenylbenzodoxazole-6-carboxylate",
    title:
      "Tafamidis Meglumine, Cas No. 951395-08-7 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Tafamidis Meglumine. The appearance of Tafamidis Meglumine is White to pink color powder. Molecular Weight is 503.",
    keywords:
      "Tafamidis Meglumine manufacturers, Tafamidis Meglumine suppliers, Tafamidis Meglumine exporters, Tafamidis Meglumine in india, 2R,3R,4R,5S)-6-(Methylamino)hexane-1,2,3,4,5-pentaol 2-(3,5-dichlorophenyl)benzo[d]oxazole-6-carboxylate",
    imageAlt: "Tafamidis Meglumine",
  },
  {
    url: "4-2-1r2r-2-25-difluorophenyl-2-hydroxy-1-methyl-3-1h-124-triazol-1-ylpropyl-4-thiazolylbenzonitrile2r3r-3-4-4-cya-nophenylthiazol-2-yl-2-25-difluorophenyl-1-1h-124-triazol-1-ylbutan-2-ol",
    title:
      "Isavuconazole, Cas No. 241479-67-4 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Isavuconazole. The appearance of Isavuconazole is White to off-white powder. Molecular Weight is 437.47.",
    keywords:
      "Isavuconazole manufacturers, Isavuconazole suppliers, Isavuconazole exporters, Isavuconazole in india, Cas No. 241479-67-4, 4-[2-[(1R,2R)-2-(2,5-Difluorophenyl)-2-hydroxy-1-methyl-3-(1H-1,2,4-triazol-1-yl)propyl]-4-thiazolyl]benzonitrile;2R,3R)-3-[4-(4-Cyanophenyl)thiazol-2-yl]-2-(2,5-difluorophenyl)-1-(1H-1,2,4-triazol-1-yl)butan-2-ol",
    imageAlt: "Isavuconazole",
  },
  {
    url: "8-3r-3-aminopiperidin-1-yl-7-but-2-yn-1-yl-3-methyl-1-4-methylquinazolin-2-ylmethyl-37-dihydro-1h-purine-26-dione",
    title:
      "Linagliptin, Cas No. 668270-12-0 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Linagliptin. The appearance of Linagliptin is White to off-white powder (or) cream color powder. Molecular Weight is 472.",
    keywords:
      "Linagliptin manufacturers, Linagliptin suppliers, Linagliptin exporters, Linagliptin in india, Cas No. 668270-12-0, 8-[(3R)-3-Aminopiperidin-1-yl]-7-(but-2-yn-1-yl)-3-methyl-1-[(4-methylquinazolin-2-yl)methyl]-3,7-dihydro-1H-purine-2,6-dione",
    imageAlt: "Linagliptin",
  },
  {
    url: "1s-15-anhydro-1-c-4-chloro-3-4-ethoxyphenylmethylphenyl-d-glucitol",
    title:
      "Dapagliflozin, Cas No. 461432-26-8 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Dapagliflozin. The appearance of Dapagliflozin is White to off white powder. Molecular Weight is 408.5.",
    keywords:
      "Dapagliflozin manufacturers, Dapagliflozin exporters, Dapagliflozin suppliers, Dapagliflozin in india, Cas No. 461432-26-8, (1S)-1,5-Anhydro-1-C-[4-chloro-3-[(4-ethoxyphenyl)methyl]phenyl]-d-glucitol",
    imageAlt: "Dapagliflozin",
  },
  {
    url: "sacubitril-valsartan-cocrystal",
    title:
      "Sacubitril-Valsartan Cocrystal, Cas No. 936623-90-4 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Sacubitril-Valsartan Cocrystal. The appearance of Sacubitril-Valsartan Cocrystal is White Solid. Molecular Weight is 961.02.",
    keywords:
      "Sacubitril-Valsartan Cocrystal manufacturers, Sacubitril-Valsartan Cocrystal suppliers, Sacubitril-Valsartan Cocrystal exporters, Sacubitril-Valsartan Cocrystal in india, Cas No. 936623-90-4, Sacubitril-valsartan cocrystal, trisodium sacubitril valsartan hemi-pentahydrate",
    imageAlt: "Sacubitril-Valsartan Cocrystal",
  },
  {
    url: "5-methoxy-2-4-methoxy-35-dimethyl-2-pyridinylmethylsulfinyl-1h-benzimidazole",
    title:
      "Omeprazole, Cas No. 73590-58-6 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Omeprazole. The appearance of Omeprazole is white to off-white, odorless, crystalline powder. Molecular Weight is 345.42.",
    keywords:
      "Omeprazole manufacturers, Omeprazole suppliers, Omeprazole exporters, Omeprazole in india, Cas No. 73590-58-6, 5-Methoxy-2-[[(4-methoxy-3,5-dimethyl-2-pyridinyl)methyl]sulfinyl]-1H-benzimidazole",
    imageAlt: "Omeprazole",
  },
  {
    url: "2-5-bromo-2-methyl-benzyl-5-4-fluorophenylthiophene",
    title:
      "Canagliflozin Intermediates, Cas No. 1030825-20-7 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Canagliflozin intermediates which are key chemical compounds produced during the synthesis of canagliflozin, a medication used to treat type 2 diabetes mellitus.",
    keywords:
      "Canagliflozin Intermediates manufacturers, Canagliflozin Intermediates suppliers, Canagliflozin Intermediates exporters, Canagliflozin Intermediates in india, Cas No. 1030825-20-7, 2-(5-Bromo-2-methyl benzyl)-5-(4-fluorophenyl)thiophene",
    imageAlt: "Canagliflozin Intermediates",
  },
  {
    url: "5-bromo-2-chloro-4-ethoxydiphenylmethane",
    title:
      "Dapagliflozin Intermediates, Cas No. 461432-23-5 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Dapagliflozin Intermediates serve as pivotal building blocks in the multi-step synthesis of dapagliflozin's molecular structure, facilitating the transformation of starting materials into the final active pharmaceutical ingredient.",
    keywords:
      "Dapagliflozin Intermediates manufacturers, Dapagliflozin Intermediates suppliers, Dapagliflozin Intermediates exporters, Dapagliflozin Intermediates in india, Cas No. 461432-23-5, 5-Bromo-2-chloro-4-ethoxydiphenylmethane",
    imageAlt: "Dapagliflozin Intermediates",
  },
  {
    url: "2-chloromethyl-4-methylquinazoline",
    title:
      "Linagliptin Intermediates, Cas No. 109113-72-6 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Linagliptin Intermediates these intermediates contribute to the overall efficiency, purity, and safety of the linagliptin manufacturing process, ensuring the production of a high-quality medication for diabetes management.",
    keywords:
      "Linagliptin Intermediates manufacturers, Linagliptin Intermediates suppliers, Linagliptin Intermediates exporters, Linagliptin Intermediates in india, Cas No. 109113-72-6, 2-(Chloromethyl)-4-methylquinazoline",
    imageAlt: "Linagliptin Intermediates",
  },

  {
    url: "3s-3-4-5-bromo-2-chlorophenylmethylphenoxytetrahydro-furan",
    title:
      "Empagliflozin Intermediates, Cas No. 915095-89-5 Manufacturers, Suppliers, Exporters in india",
    description:
      "We are the manufacturers of Empagliflozin Intermediates. These intermediates serve as fundamental building blocks in the multi-step synthesis of empagliflozin's molecular structure, facilitating the transformation of starting materials into the final active pharmaceutical ingredient.",
    keywords:
      "Empagliflozin Intermediates manufacturers, Empagliflozin Intermediates suppliers, Empagliflozin Intermediates exporters, Empagliflozin Intermediates in india, Cas No. 915095-89-5, (3S)-3-[4-[(5-Bromo-2-chlorophenyl)methyl]phenoxy]tetrahydro-furan.",
    imageAlt: "Empagliflozin Intermediates",
  },
  {
    url: "3-1-piperazinyl-12-benzisothiazole",
    title:
      "Lurasidone Intermediates, Cas No. 87691-87-0 Manufacturers, Suppliers, Exporters in india",
    description:
      "Lurasidone intermediates are fundamental chemical compounds synthesized in the manufacturing process of luliconazole, an antifungal medication used to treat various fungal skin infections, including athlete's foot and ringworm.",
    keywords:
      "Lurasidone Intermediates manufacturers, Lurasidone Intermediates suppliers, Lurasidone Intermediates exporters, Lurasidone Intermediates in india, Cas No. 87691-87-0, 3-(1-Piperazinyl)-1,2-benzisothiazole",
    imageAlt: "Lurasidone Intermediates",
  },
  {
    url: "3-4-chlorobutyl-1h-indole-5-carbonitrile",
    title:
      "Vilazodone Intermediates, Cas No. 143612-79-7 Manufacturers, Suppliers, Exporters in india",
    description:
      "Vilazodone intermediates are essential chemical components synthesized during the manufacturing process of vilazodone, a medication used to treat major depressive disorder.",
    keywords:
      "Vilazodone Intermediates manufacturers, Vilazodone Intermediates suppliers, Vilazodone Intermediates exporters, Vilazodone Intermediates in india, Cas No. 143612-79-7, 3-(4-Chlorobutyl)-1H -indole-5-carbonitrile",
    imageAlt: "Vilazodone Intermediates",
  },
  {
    url: "2-2-cyanophenylacetic-acid",
    title:
      "Perampanel Intermediates, Cas No. 18698-99-2 Manufacturers, Suppliers, Exporters in india",
    description:
      "These intermediates serve as essential building blocks in the stepwise synthesis of perampanel's molecular structure, guiding the transformation of starting materials into the final active pharmaceutical ingredient.",
    keywords:
      "Perampanel Intermediates manufacturers, Perampanel Intermediates suppliers, Perampanel Intermediates exporters, Perampanel Intermediates in india, Cas No. 18698-99-2, 2-(2-cyanophenyl)acetic acid",
    imageAlt: "Perampanel Intermediates",
  },
  {
    url: "1-2r3s-2-24-difluorophenyl-3-methyloxiran-2-yl-methyl-1h-124-triazole",
    title:
      "Albaconazole Intermediates, Cas No. 127000-90-2 Manufacturers, Suppliers, Exporters in india",
    description:
      "Albaconazole intermediates are key chemical compounds synthesized in the manufacturing process of albaconazole, an antifungal medication.",
    keywords:
      "Albaconazole Intermediates manufacturers, Albaconazole Intermediates suppliers, Albaconazole Intermediates exporters, Albaconazole Intermediates in india, Cas No. 127000-90-2, 1-(((2R,3S)-2-(2,4-difluorophenyl)-3-methyloxiran-2-yl)-methyl)-1H-1,2,4-triazole",
    imageAlt: "Albaconazole Intermediates",
  },
  {
    url: "4-methylidenepiperidinehydrochloride",
    title:
      "Efinaconazole Intermediates, Cas No. 144230-50-2 Manufacturers, Suppliers, Exporters in india",
    description:
      "Efinaconazole intermediates are pivotal chemical compounds synthesized at various stages of the manufacturing process of efinaconazole, an antifungal medication used to treat toenail fungus.",
    keywords:
      "Efinaconazole Intermediates manufacturers, Efinaconazole Intermediates suppliers, Efinaconazole Intermediates exporters, Efinaconazole Intermediates in india, Cas No. 144230-50-2, 4-methylidenepiperidine;hydrochloride",
    imageAlt: "Efinaconazole Intermediates",
  },
  {
    url: "2r3s-2-25-difluorophenyl-3-methyl-2-1h-124-triazol-1-yl-methyl-oxirane",
    title:
      "Isavuconazole Intermediates, Cas No. 241479-73-2 Manufacturers, Suppliers, Exporters in india",
    description:
      "Isavuconazole intermediates are essential chemical compounds synthesized during the production of isavuconazole, an antifungal medication used to treat invasive aspergillosis and invasive mucormycosis.",
    keywords:
      "Isavuconazole Intermediates manufacturers, Isavuconazole Intermediates suppliers, Isavuconazole Intermediates exporters, Isavuconazole Intermediates in india, Cas No. 241479-73-2, (2R,3S)-2-(2,5-difluorophenyl)-3-methyl-2-[(1H-1,2,4-triazol-1-yl)-methyl]-oxirane",
    imageAlt: "Isavuconazole Intermediates",
  },
  {
    url: "s-2-chloro-1-24-dichlorophenylethyl-methanesulfonate",
    title:
      "Luliconazole Intermediates, Cas No. 229334-55-8 Manufacturers, Suppliers, Exporters in india",
    description:
      "Luliconazole intermediates are fundamental chemical compounds synthesized in the manufacturing process of luliconazole, an antifungal medication used to treat various fungal skin infections, including athlete's foot and ringworm.",
    keywords:
      "Luliconazole Intermediates manufacturers, Luliconazole Intermediates suppliers, Luliconazole Intermediates exporters, Luliconazole Intermediates in india, Cas No. 229334-55-8, (S)-2-chloro-1-(2,4-dichlorophenyl)ethyl methanesulfonate",
    imageAlt: "Luliconazole Intermediates",
  },
  {
    url: "2-bromo-5-fluorobenzyl-alcohol",
    title:
      "Tavaborole Intermediates, Cas No. 202865-66-5 Manufacturers, Suppliers, Exporters in india",
    description:
      "Tavaborole intermediates are pivotal chemical compounds synthesized during the production of tavaborole, a medication used to treat onychomycosis, a fungal infection of the toenails and fingernails.",
    keywords:
      "Tavaborole Intermediates manufacturers, Tavaborole Intermediates suppliers, Tavaborole Intermediates exporters, Tavaborole Intermediates in india, Cas No. 202865-66-5, 2-bromo-5-fluorobenzyl alcohol",
    imageAlt: "Tavaborole Intermediates",
  },
  {
    url: "2-bromo-5-methoxybenzyl-alcohol",
    title:
      "Crisaborole Intermediates, Cas No. 150192-39-5 Manufacturers, Suppliers, Exporters in india",
    description:
      "Crisaborole intermediates are key chemical compounds synthesized in the production of crisaborole, a medication used to treat mild to moderate eczema (atopic dermatitis)",
    keywords:
      "Crisaborole Intermediates manufacturers, Crisaborole Intermediates suppliers, Crisaborole Intermediates exporters, Crisaborole Intermediates in india, Cas No. 150192-39-5, 2-Bromo-5-methoxybenzyl alcohol",
    imageAlt: "Crisaborole Intermediates",
  },
  {
    url: "4567-tetrahydro-13-benzothiazole-26-diamine",
    title:
      "Dex pramipexole Pramipexole Intermediates, Cas No. 104617-49-4 Manufacturers, Suppliers, Exporters in india",
    description:
      "These intermediates serve as crucial building blocks in the stepwise synthesis of dex pramipexole's molecular structure, guiding the transformation of starting materials into the final active pharmaceutical ingredient.",
    keywords:
      "Dex pramipexole Pramipexole Intermediates manufacturers, Dex pramipexole Pramipexole Intermediates suppliers, Dex pramipexole Pramipexole Intermediates exporters, Dex pramipexole Pramipexole Intermediates in india, Cas No. 104617-49-4, 4,5,6,7-tetrahydro-1,3-benzothiazole-2,6-diamine",
    imageAlt: "Dex pramipexole Pramipexole Intermediates",
  },
  {
    url: "r-3-amino-1-butanol",
    title:
      "Dolutegravir Intermediates, Cas No. 61477-40-5 Manufacturers, Suppliers, Exporters in india",
    description:
      "Dolutegravir intermediates are essential chemical compounds synthesized during the production of dolutegravir, an antiretroviral medication used to treat HIV infection.",
    keywords:
      "Dolutegravir Intermediates manufacturers, Dolutegravir Intermediates suppliers, Dolutegravir Intermediates exporters, Dolutegravir Intermediates in india, Cas No. 61477-40-5, (R)-3-amino-1-butanol",
    imageAlt: "Dolutegravir Intermediates",
  },
  {
    url: "3-hydroxy-7-oxo-5-cholanic-acid",
    title:
      "Obeticholic acid Intermediates, Cas No. 4651-67-6 Manufacturers, Suppliers, Exporters in india",
    description:
      "Obeticholic acid intermediates are fundamental chemical compounds synthesized in the production of obeticholic acid, a medication used to treat primary biliary cholangitis (PBC) and non-alcoholic steatohepatitis (NASH).",
    keywords:
      "Obeticholic acid Intermediates manufacturers, Obeticholic acid Intermediates suppliers, Obeticholic acid Intermediates exporters, Obeticholic acid Intermediates in india, Cas No. 4651-67-6, 3α-Hydroxy-7-oxo-5β-cholanic Acid",
    imageAlt: "Obeticholic acid Intermediates",
  },
];
