import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { list } from "./helpers";
import SmallScreenHeader from "./SmallScreenHeader";

const Header = () => {
  let { pathname } = useLocation();

  const [menus, setMenus] = useState(list);

  const handleMenusOnHover = (activeIndex) => {
    const updatedMenus = menus?.map((item, index) => ({
      ...item,
      isActive: index === activeIndex,
      dropdown: activeIndex === 3 ? false : index === activeIndex,
      subList: item.subList.map((subItem, innerIndex) => ({
        ...subItem,
        isActive: false,
      })),
    }));
    setMenus(updatedMenus);
  };

  const handleMainCategorySelect = (activeIndex) => {
    const updatedMenus = menus?.map((item, index) => ({
      ...item,
      isActive: index === activeIndex,
      dropdown: false,
    }));
    setMenus(updatedMenus);
  };

  const handleMenusOnLeave = () => {
    const updatedMenus = menus?.map((item, index) => ({
      ...item,
      isActive: false,
      dropdown: false,
    }));
    setMenus(updatedMenus);
  };

  const handleSubLink = () => {
    const updatedMenus = menus?.map((item, index) => ({
      ...item,
      isActive: false,
      dropdown: false,
    }));
    setMenus(updatedMenus);
  };
  return (
    <div>
      {pathname.includes("admin") || pathname.includes("login") ? null : (
        <>
          <div className="hidden body-content2 lg:block fixed top-5 w-full h-20 px-3 md:px-10 lg:px-12 z-50">
            <div className="w-full h-full px-3 md:px-10 lg:px-12 bg-lightBlue  flex justify-between items-center rounded-xl">
              <div>
                <Link to="/" className="w-full">
                  <img src="/images/home/logo.svg" alt="logo" />
                </Link>
              </div>

              <div
                className="hidden  lg:flex w-full h-full items-center  justify-end  gap-10"
                onMouseLeave={handleMenusOnLeave}
              >
                {menus?.map((item, index) => (
                  <div key={index}>
                    <div>
                      <Link to={item?.url}>
                        <p
                          className={`${
                            (pathname.includes("/")
                              ? pathname.split("/")[1]
                              : pathname) === item?.slug || item?.isActive
                              ? "text-green"
                              : "text-white"
                          }  text-xl font-AuthorThin font-medium`}
                          onMouseEnter={() => handleMenusOnHover(index)}
                          onClick={() => {
                            handleMainCategorySelect(index);
                          }}
                        >
                          {item?.name}
                        </p>
                      </Link>
                    </div>

                    {[0, 4].includes(index) ? (
                      ""
                    ) : (
                      <>
                        {item?.isActive && item?.dropdown ? (
                          <div
                            className={`${
                              index === 3 ? "right-48" : '"'
                            }  shadow-all modal absolute top-20 z-50 h-auto w-auto rounded-b-lg bg-lightBlue  px-6 py-3`}
                          >
                            {index === 3 ? (
                              // <div className="grid grid-cols-2 gap-1 pb-1">
                              //   {item?.subList?.map((child, childIndex) => (
                              //     <div key={childIndex}>
                              //       <a
                              //         href={child?.url}
                              //         target="_blank"
                              //         className=" text-white font-medium font-AuthorThin text-xl hover:text-green"
                              //       >
                              //         {child?.name}
                              //       </a>
                              //     </div>
                              //   ))}
                              // </div>
                              <></>
                            ) : (
                              <>
                                {React.Children.toArray(
                                  item?.subList?.map(
                                    (Inneritem, innerIndex) => (
                                      <div className="py-1" key={innerIndex}>
                                        <Link to={Inneritem?.subLink}>
                                          <p
                                            onClick={handleSubLink}
                                            className={`hover:text-green font-AuthorThin ${
                                              (pathname.includes("/") &&
                                                pathname) === Inneritem?.subLink
                                                ? "text-green"
                                                : "text-white"
                                            }`}
                                          >
                                            {Inneritem?.name}
                                          </p>
                                        </Link>
                                      </div>
                                    )
                                  )
                                )}
                              </>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="block lg:hidden">
            <SmallScreenHeader />
          </div>
        </>
      )}
    </div>
  );
};

export default Header;
