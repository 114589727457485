
import {authenticationService } from '../../../config/auth.service';
import React, { Component } from 'react';
import api from '../../../api/index'
import {Link} from 'react-router-dom'
import  browserHistory from '../../../config/browserHistory'
import AdminHeader from '../../components/AdminHeader/AdminHeader'
import SideMenu from '../../components/SideMenu'
import Moment from 'moment';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
class JobEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false, 
			success:	'',
			id:this.props.match.params.id,
			name:'',
			work_type:'Full Time',
			work_location:'Hyderabad (Remote until lockdown)',
			roles_responsibilities:'',
			expectations:'',
			formErrors: {name: '', work_type: '',work_location:'',roles_responsibilities:'',expectations:''},
			formValid: false
		}
		console.log(this.props.match.params.id)
	}

	componentDidMount() {
		if(this.state.id){
			this.getJobById();
		}
	}

	getJobById=()=>{
		api.get('/admin/jobs/' + this.state.id).then(res=>{
			console.log(res)
			this.setState({
				formValid:true,
				nameValid:false,
				workValid:false,
				locationValid:false,
				roleValid:false,
				expectationValid:false,
				name:res.data.data.name,
				work_type:res.data.data.work_type, 
				work_location: res.data.data.work_location,
				roles_responsibilities: res.data.data.roles_responsibilities,
				expectations: res.data.data.expectations,
			})
		console.log(this.state)
		}).catch(err => {
			console.log(err)
		})
	}

	
	
	
	scrollToFirstInvalidControl() {
		const firstInvalidControl = document.querySelector(
		  ".has-error"
		);
	 
		if (firstInvalidControl) {
		  $(window).scrollTop($("input").offset().top - 120);
		  firstInvalidControl.focus();
		}
	  }

	handleChange = (e) => {
		this.setState({success:''});
		let nameKey;
		let valueData;
		if(e['data']){
		  nameKey = e.data;
		  valueData = '';
		}
		if(e.type){
		  nameKey = e.target.name;
		  valueData =  e.target.value;
		}
		const name = nameKey;
		const value = valueData;
		this.setState({[name]: value},
			() => { this.validateField(name, value) });;
	  }

	validateField(fieldName, value) {
		let fieldValidationErrors = this.state.formErrors;
		let expectationValid = this.state.expectationValid;
		let roleValid = this.state.roleValid;
		let nameValid = this.state.nameValid;
		let workValid = this.state.workValid;
		let locationValid = this.state.locationValid;

		switch(fieldName) {
		  case 'name':
			nameValid = (value.length > 0);
			fieldValidationErrors.name = nameValid ? '' : ' is invalid';
			break;  
		case 'work_type':
			workValid = (value.length > 0);
			fieldValidationErrors.work_type = workValid ? '': ' is invalid';
			break;
		case 'work_location':
			locationValid = (value.length > 0);
			fieldValidationErrors.work_location = locationValid ? '': ' is invalid';
			break; 
		case 'roles_responsibilities':
			roleValid = (value.length > 0);
			fieldValidationErrors.roles_responsibilities = roleValid ? '': ' is invalid';
			break;
		case 'expectations':
			expectationValid = (value.length > 0);
			fieldValidationErrors.expectations = expectationValid ? '': ' is invalid';
			break;
		  default:
			break;
		}
		this.setState({formErrors: fieldValidationErrors,
			nameValid: nameValid,
			workValid: workValid,
			locationValid: locationValid,
			roleValid: roleValid,
			expectationValid: expectationValid,
		});
		
	}	

	validateForm() {
		// this.setState({formValid: 
		// 	this.state.nameValid && 
		// 	this.state.workValid && 
		// 	this.state.locationValid && 
		// 	this.state.roleValid && 
		// 	this.state.expectationValid 
		// });
	}

	errorClass(error) {
		return(error.length === 0 ? '' : 'has-error');
	}
	  
	handleSubmit=(event)=>{
		event.preventDefault();
		console.log(this.state)
		console.log(this.state.formValid)
		if(this.state.formValid){
		let data = {
			name: this.state.name,
			work_type: this.state.work_type,
			work_location: this.state.work_location,
			roles_responsibilities: this.state.roles_responsibilities,
			expectations: this.state.expectations,
		}
		this.setState({isLoading:true});
		var self =this;
		if(this.state.id){
			api.put('/admin/jobs/' + this.state.id, data )
			.then(res => {
				console.log(res.data)
				self.setState({
					success:res.data.message,
					isLoading:false
				})
				toast.success(res.data.message,{className: 'success-info'})
			}).catch(err => {
				self.setState({isLoading:false});
				toast.error('Some thing went wrong!, please try again',{className: 'error-info'})
			})
		}else{
			api.post('/admin/jobs', data )
			.then(res => {
				console.log(res.data)
				self.setState({
					success:res.data.message,
					isLoading:false
				})
				toast.success(res.data.message,{className: 'success-info'})
			}).catch(err => {
				self.setState({isLoading:false});
				toast.error('Some thing went wrong!, please try again',{className: 'error-info'})
			})
		}
		}else{
			toast.error('Please fill the required details',{className: 'error-info'})
			if(this.state.name === ''){
				this.handleChange({data:'name'})
			}
			if(this.state.work_type === ''){
			this.handleChange({data:'work_type'})
			}
			if(this.state.work_location === ''){
			this.handleChange({data:'work_location'})
			}
			if(this.state.roles_responsibilities === ''){
				this.handleChange({data:'roles_responsibilities'})
			}
			if(this.state.expectations === ''){
					this.handleChange({data:'expectations'})
			}
			this.scrollToFirstInvalidControl();
			return false;
		}
		
	}
	render() {
		return (
			<div className="flex bg-gray-100 admin bg-adminbg">
			<ToastContainer />
			<SideMenu></SideMenu>
			<div className="flex flex-col w-full h-screen overflow-y-hidden">
			<AdminHeader></AdminHeader>
			<div className="flex flex-col w-full overflow-x-hidden border-t border-ThemeLightBlue">
            <main className="flex-grow w-full p-6 ">
				<div>
             
                <div className="flex items-center breadcrumbs">
                <Link to="/admin/job-list" className="text-base text-blue">Job List</Link>	<svg className="mx-3" width="8" height="12" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 18.25L10.75 10.125L2 2" stroke="#5F7995" strokeWidth="1.875" strokeLinecap="square"/>
</svg>
 <span className="text-base text-ThemeBlue font-WavehausBold"> {this.state.id ? 'Edit job':'Create new job'}  </span>
                </div>

				
					<hr className="mt-2 border-borderColor"/>
					

                        <div className="w-full pr-0 my-6 lg:pr-2">
							<div className="px-6 bg-white border rounded-lg shadow-xl py-7 border-borderBlue">
							
                                <form ref="form"  onSubmit={this.handleSubmit} noValidate>
							
                            <div>

							<div className={`form-group w-1/2 inline-block my-1 mr-2 ${this.errorClass(this.state.formErrors.name)}`}>
								<input type="text" id="name" value={this.state.name} onChange={this.handleChange} noValidate name="name" className="form-group__input" autoComplete="off" placeholder=" "/>
								<label htmlFor="name" className="form-group__label">Job Title</label>
							</div>

							<div  className={`form-group w-1/2 inline-block my-1 ml-2 ${this.errorClass(this.state.formErrors.work_type)}`}>
							<select
								id="work_type" 
								className="form-group__input "
									onChange={this.handleChange}
									value={this.state.join_in_days_type}
									name="work_type"
								>
									<option value="Full Time">Full Time</option>
									<option value="Part Time">Part Time</option>
								</select>
								<label htmlFor="work_type" className="form-group__label">
								Job Type
								</label>
							</div>


							<div  className={`form-group w-1/2 inline-block  my-1 pl-2 ${this.errorClass(this.state.formErrors.work_location)}`}>
							<select
								id="work_location" 
								className="form-group__input "
									onChange={this.handleChange}
									value={this.state.work_location}
									name="work_location"
								>
									<option value="Hyderabad (Remote until lockdown)">Hyderabad (Remote until lockdown)</option>
									<option value="Hyderabad">Hyderabad</option>
								</select>
								<label htmlFor="work_location" className="form-group__label">
								Job Location
								</label>
							</div>

							<div className="form-group textarea">
                      <textarea
                        type="text"
                        rows="4"
                        id="roles_responsibilities"
                        onChange={this.handleChange}
                        value={this.state.roles_responsibilities}
                        name="roles_responsibilities"
                        className="form-group__input"
                        autoComplete="off"
                        placeholder=" "
                      />
                      <label htmlFor="roles_responsibilities" className="w-42 form-group__label">
                        {" "}
						Roles Responsibilities
                      </label>
                    </div>

					<div className="form-group textarea">
                      <textarea
                        type="text"
                        rows="4"
                        id="expectations"
                        onChange={this.handleChange}
                        value={this.state.expectations}
                        name="expectations"
                        className="form-group__input"
                        autoComplete="off"
                        placeholder=" "
                      />
                      <label htmlFor="expectations" className="w-20 form-group__label">
                        {" "}
						Expectations
                      </label>
                    </div>
                       
							</div>
							<p className="px-4 text-green">{this.state.success}</p>
							<div className="w-full mt-4 text-right">
							{
								!this.state.isLoading ? 	<button className="px-6 py-3 mb-1 mr-1 text-sm text-white transition-all duration-150 ease-linear rounded-full shadow outline-none cursor-pointer font-WavehausSemiBold bg-green get-started focus:outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-lg">
                                        
								<span>Post this job</span>
								</button>: <button className="px-8 py-3 text-sm text-white transition-all duration-150 ease-linear shadow outline-none rounded-3xl font-WavehausBold bg-green get-started focus:outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-lg">Loading...</button>
							}
                                    </div>
					</form>
                             
                            </div>
                        </div>
						
					</div>
				</main>
			</div>
			</div>
		
			</div>
		);
	}
}

export default JobEdit;
