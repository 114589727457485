import React, { useState, useEffect } from "react";
import gsap from "gsap/dist/gsap";
import { Link } from "react-router-dom/cjs/react-router-dom";

const WhatWeDo = () => {
  const slidesData = [
    {
      id: 1,
      text: "WHAT WE DO",
      text2:
        "Pharmaceutical intermediates play a crucial role in the production of life-saving medications.",
      text3:
        "Pharmaceutical intermediates play a crucial role in the production of life-saving medications.",

      headingColor: "text-tempBlue",
      text2Color: "text-tempBlue",
      class: "bg-lightWhite",
      url: "/products/active-pharmaceutical-ingredients",
    },
    {
      id: 2,
      text: " Active Pharmaceutical Ingredients",
      text2:
        " Active substances that provide pharmacological activity in medications.",
      class: "bg-greenishBlue",
      image: "/home/shapes/chemistry1.svg",
      headingColor: "text-lightBlue",
      text2Color: "text-lightBlue",
      url: "/products/intermediates",
    },
    {
      id: 3,
      text: "Intermediates",
      text2:
        "Pharmaceutical intermediates play a crucial role in the production of life-saving medications",
      class: "bg-mintGreen",

      image: "/home/shapes/chemistry2.svg",
      headingColor: "text-lightBlue",
      text2Color: "text-lightBlue",
      url: "/about-us",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const nextSlideIndex = (currentSlide + 1) % slidesData.length;
    const prevSlideIndex =
      currentSlide === 0 ? slidesData.length - 1 : currentSlide - 1;

    const timeline = gsap.timeline();

    timeline.to(`#slide-${currentSlide}`, {
      x: "-100%",
      opacity: 1,
      duration: 2,
    });

    timeline.fromTo(
      `#slide-${nextSlideIndex}`,
      { x: "100%" },
      { x: "0%", opacity: 1, duration: 2 },
      "<"
    );

    timeline.fromTo(
      `#slide-${prevSlideIndex}`,
      { x: "0%" },
      { x: "-100%", opacity: 0, duration: 2 },
      "<"
    );

    const timeout = setTimeout(() => {
      setCurrentSlide(nextSlideIndex);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [currentSlide]);
  return (
    <div>
      {/* Large screens */}
      <div className="hidden  absolute bottom-0 lg:grid grid-cols-1  lg:grid-cols-3 ">
        <div className="bg-mintGreen px-12 py-8   hidden lg:flex md:flex flex-col items-start justify-center">
          <div className="flex items-start gap-5">
            <div className="lg:flex flex-col gap-2 items-start justify-center">
              <p className="text-lightBlue   font-AuthorBold text-2xl ">
                Active Pharmaceutical Ingredients
              </p>
              <p className=" font-AuthorThin text-xl text-lightBlue">
                Active substances that provide pharmacological activity in
                medications.
              </p>
              <div className="mt-2">
                <Link to="/products/active-pharmaceutical-ingredients">
                  <p className="text-lightBlue underline text-lg font-AuthorSemiBold">
                    Know More
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-greenishBlue px-12 py-8   hidden lg:flex md:flex flex-col items-start justify-center">
          <div className="flex items-start gap-5">
            <div className="lg:flex flex-col gap-2 items-start justify-center">
              <p className="text-lightBlue   font-AuthorBold text-2xl">
                Intermediates
              </p>
              <p className="font-AuthorThin text-xl text-lightBlue">
                Pharmaceutical intermediates play a crucial role in the
                production of life-saving medications.
              </p>

              <div className="mt-2">
                <Link to="/products/intermediates">
                  <p className="text-lightBlue underline text-lg font-AuthorSemiBold">
                    Know More
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lightWhite px-12 py-8  flex flex-col gap-1 lg:gap-2 items-start ">
          <p className="text-lightBlue font-AuthorBold text-2xl">What We Do</p>

          <div className="">
            <p className=" font-AuthorThin text-xl text-lightBlue ">
              We make high-quality APIs and intermediates, which are the
              backbone of the pharmaceutical industry.
            </p>
          </div>
          <div className="mt-2">
            <Link to="/about-us">
              <p className="text-lightBlue underline text-lg font-AuthorSemiBold">
                Know More
              </p>
            </Link>
          </div>
        </div>
      </div>

      {/*Medium and Small screens */}

      <div className="w-full block lg:hidden">
        <div className="w-full absolute bottom-0 ">
          <div className="relative font-AuthorThin  block lg:hidden h-48 w-full overflow-hidden">
            {slidesData.map((slide, index) => (
              <div
                id={`slide-${index}`}
                key={slide.id}
                className={` pl-6 customslide pb-16 ${slide.bgColor} ${slide?.class} absolute h-full w-full px-2 py-6 `}
                style={{
                  opacity: index === currentSlide ? 1 : 0,
                  transform:
                    index === currentSlide
                      ? "translateX(0%)"
                      : "translateX(100%)",
                }}
              >
                <div className="flex flex-col">
                  <div className="flex items-start gap-5">
                    <div className="flex flex-col gap-1 items-start justify-center">
                      <p
                        className={`${slide?.headingColor} font-AuthorSemiBold text-xl`}
                      >
                        {slide?.text}
                      </p>

                      <p className={`${slide?.text2Color}  font-AuthorThin`}>
                        {slide?.text2}
                      </p>

                      <Link to={slide?.url}>
                        {" "}
                        <div className="mt-1">
                          <p className="font-AuthorSemiBold text-lightBlue underline">
                            Know more
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
