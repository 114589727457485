import React from "react";

export const TabOne = ({ data }) => {
  let ProductData = [
    {
      name: "Chemical Name",
      content: data?.name || "--",
    },

    {
      name: "Molecular Weight",
      content: data?.molecular_weight || "--",
    },
    {
      name: "Molecular Formula",
      content: data?.molecular_formula || "--",
    },
    {
      name: "Appearance",
      content: data?.appearance || "--",
    },
  ];
  return (
    <div>
      {ProductData?.map((item, index) => (
        <div
          key={index}
          className="w-full grid grid-cols-2 lg:grid-cols-12 mb-2"
        >
          <div className="lg:col-span-3">
            <p className="text-lightBlue font-AuthorSemiBold font-medium text-base lg:text-xl">
              {item?.name}
            </p>
          </div>
          <div className="lg:col-span-9">
            <p className="text-lightBlue font-AuthorThin font-normal text-base lg:text-xl break-all">
              {item?.content}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export const TabTwo = ({ data }) => {
  return (
    <div>
      <p className=" font-AuthorThin text-base lg:text-xl text-lightBlue">
        {data?.product_users || "--"}
      </p>
    </div>
  );
};

export const TabThree = ({ data }) => {
  return (
    <div>
      <p className=" font-AuthorThin text-base lg:text-xl text-lightBlue">
        {data?.product_status || "--"}
      </p>
    </div>
  );
};
